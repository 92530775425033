import React, {useState, useEffect} from "react";
import {ContainerBox,TextBox} from "../../common/StyleMasterComp"
import Utils_MediaQuery from "../../common/Utils_MediaQuery";


function TopTxt(props){


    let [txtSize, setTxtSize] = useState({ BigMarginTop:"0px", BigFont:"30px", SmallFont:"16px" });

    useEffect(() => {
    let CurrMediaType = Utils_MediaQuery.GetMediaDetailType(props.objWindowSize.Width,props.objWindowSize.Height);

        // console.log("TopTxt : ",CurrMediaType);
        if(CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_M_FOLD_S){
            setTxtSize({ BigMarginTop:"20px", BigFont:"17px", SmallFont:"12px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.P_M_IPHONE_S){
            setTxtSize({ BigMarginTop:"10px", BigFont:"18px", SmallFont:"12px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.P_M_ANDROID_S){
            setTxtSize({ BigMarginTop:"10px", BigFont:"19px", SmallFont:"12px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_L){
            setTxtSize({ BigMarginTop:"10px", BigFont:"20px", SmallFont:"12px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_T_GALAXY_S ||
            CurrMediaType === Utils_MediaQuery.P_T_IPAD_S ||
            CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_L){
            setTxtSize({ BigMarginTop:"0px", BigFont:"30px", SmallFont:"16px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_D_DESKTOP_S ||
            CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_L){
            setTxtSize({ BigMarginTop:"0px", BigFont:"30px", SmallFont:"18px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.L_M_FOLD_S ||
            CurrMediaType === Utils_MediaQuery.L_M_IPHONE_S ||
            CurrMediaType === Utils_MediaQuery.L_M_ANDROID_S ||
            CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_L){
            setTxtSize({ BigFont:"20px", SmallFont:"12px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.L_T_GALAXY_S ||
            CurrMediaType === Utils_MediaQuery.L_T_IPAD_S){
            setTxtSize({ BigFont:"20px", SmallFont:"12px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_L){
            setTxtSize({ BigFont:"20px", SmallFont:"16px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.L_D_DESKTOP_S ||
            CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_L){
            setTxtSize({ BigFont:"26px", SmallFont:"16px" });
        }
    },[props.objWindowSize])


    return(
        <ContainerBox>
            <TextBox FontSize={txtSize.BigFont} MarginTop={txtSize.BigMarginTop} FontColor="#000">새만금 메타버스 바로가기</TextBox>
            <TextBox FontSize={txtSize.SmallFont} FontColor="#000">‘이동’ 버튼을 클릭해 Engage 새만금 메타버스로 이동하세요.</TextBox>
        </ContainerBox>
    );
}

export default TopTxt;