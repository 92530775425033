import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {ContainerBox,TextBox} from "../../common/StyleMasterComp"
import Utils_MediaQuery from "../../common/Utils_MediaQuery";

let AuthTextBox = styled(TextBox)`
    font-family: 'SEBANG_Gothic_Regular';
`;

function MidtitleTxt(props){

    let [txtInfo1, setTxtInfo1] = useState({ FontSize:"40px" });

    useEffect(() => {
        let CurrMediaType = Utils_MediaQuery.GetMediaDetailType(props.objWindowSize.Width,props.objWindowSize.Height);

        if(CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_M_FOLD_S ||
            CurrMediaType === Utils_MediaQuery.P_M_IPHONE_S ||
            CurrMediaType === Utils_MediaQuery.P_M_ANDROID_S){
            setTxtInfo1({ FontSize:"15px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_L){
            setTxtInfo1({ FontSize:"18px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_S){
            setTxtInfo1({ FontSize:"22px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.P_T_GALAXY_S ||
            CurrMediaType === Utils_MediaQuery.P_T_IPAD_S ||
            CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_L){
            setTxtInfo1({ FontSize:"28px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_D_DESKTOP_S ||
            CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_L){
            setTxtInfo1({ FontSize:"40px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.L_M_FOLD_S){
            setTxtInfo1({ FontSize:"10px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.L_M_IPHONE_S ||
            CurrMediaType === Utils_MediaQuery.L_M_ANDROID_S){
            setTxtInfo1({ FontSize:"12px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_L){
            setTxtInfo1({ FontSize:"15px" });
        }
        
        else if(CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.L_T_GALAXY_S ||
            CurrMediaType === Utils_MediaQuery.L_T_IPAD_S){
            setTxtInfo1({ FontSize:"19px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_L){
            setTxtInfo1({ FontSize:"25px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.L_D_DESKTOP_S ||
            CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_L){
            setTxtInfo1({ FontSize:"40px" });
        }
    },[props.objWindowSize])

    return(
        <ContainerBox>
            {/* <AuthTextBox FontSize={txtInfo1.FontSize} FontColor="#fff">U, Our Star!</AuthTextBox>
            <AuthTextBox FontSize={txtInfo1.FontSize} FontColor="#fff">서울시립대학교 성과공유회 메타버스,</AuthTextBox>
            <AuthTextBox FontSize={txtInfo1.FontSize} FontColor="#fff">Our Star에 오신 것을 환영합니다.</AuthTextBox> */}
            
        </ContainerBox>
    );
}

export default MidtitleTxt;