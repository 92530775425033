import styled from "styled-components";

export const ContainerBox = styled.div`
    position: relative;
    pointer-events:none;
    margin: 0px;
    padding: 0px;
`;

export const Block = styled(ContainerBox)`
  display: ${props => props.Display || null};
  position: ${props => props.Position || null};
  width: ${props => props.ContainerWidth || '100vw'};
  height:${props => props.ContainerHeight || '1px'};
  background-color: ${props => props.BackColor || null};
  border: ${props => props.Border + " solid #000" || null};
  left: ${props => props.Left || null};
  top: ${props => props.Top || null};
  right: ${props => props.Right || null};
  bottom: ${props => props.Bottom || null};
`;

export const TextBox = styled.div `
  color: ${props => props.FontColor || "#000"}; 
  font-size: ${props => props.FontSize || "16px"}; 
  font-weight: ${props => props.FontWeight || null};
  font-family: ${props => props.FontFamily || 'Spoqa HanSansNeo'};
  word-spacing: ${props => props.WordSpacing || null};
  letter-spacing: ${props => props.LetterSpacing || null};
  text-align: ${props => props.TextAlign || null};
  margin-top: ${props => props.MarginTop || "0px"};
  margin-left: ${props => props.MarginLeft || "0px"};
  margin-right: ${props => props.MarginRight || "0px"};
  margin-bottom: ${props => props.MarginBottom || "0px"};
  pointer-events: ${props => props.PointerEvents || "none"};
  border: ${props => props.Border + " solid #000" || null};
  padding: ${props => props.Padding || null};
  line-height: ${props => props.LineHeight || null};
  width: ${props => props.Width || null};
  height: ${props => props.Height || null};
`;

export const ImgBox = styled.div`
  width: ${props => props.Width || '100vw'};
  height: ${props => props.Height || '1px'};
  position: ${props => props.Position || null};
  display: ${props => props.Display || null};
  left: ${props => props.Left || null};
  top: ${props => props.Top || null};
  right: ${props => props.Right || null};
  bottom: ${props => props.Bottom || null};
  pointer-events:none;
  background-image: url(${props => props.BackImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

export const ImgButton = styled.div`
  width: ${props => props.BtnWidth || '100vw'};
  height: ${props => props.BtnHeight || '1px'};
  position: ${props => props.Position || null};
  display: ${props => props.Display || null};
  left: ${props => props.Left || null};
  top: ${props => props.Top || null};
  right: ${props => props.Right || null};
  bottom: ${props => props.Bottom || null};
  pointer-events:all;
  cursor: pointer;
  background-image: url(${props => props.BackImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

export const ModalContainerBox = styled(ContainerBox)`
    position: fixed;
    left: 0;
    top:0;
    pointer-events:all;
    z-index: 100;
`;

export const ModalBlackBox = styled.div`
    position: relative;
    left: 0;
    top:0;
    height:100vh;
    width:100vw;
    pointer-events:all;    
    background-color: black;
    opacity: 0.8;
`;