import React, { useState, useEffect } from "react";
import { ContainerBox, ImgButton } from "../../common/StyleMasterComp";

// KDG
// import IcoWorldMap from '../../images/ico_worldMap.png';
import IcoWorldMap from '../../images/ico_worldMap_SMG.png';
// KDG
// import IcoWorldBack from '../../images/ico_worldBack.png';
import IcoWorldBack from '../../images/ico_worldMap_SMG.png';
// KDG
// import IcoCompanyList from '../../images/ico_companyList.png';
import IcoCompanyList from '../../images/ico_target_list.png';
import IcoSatisfaction from '../../images/ico_satisfaction.png';
import Utils_MediaQuery from "../../common/Utils_MediaQuery";

function Topmenu(props){

    let [btnSize, setBtnSize] = useState({ Width:"92px", Height:"99px" });
    let [btnPosition1, setBtnPosition1] = useState({ Left:"30px", Top:"20px" });
    let [btnPosition2, setBtnPosition2] = useState({ Right:"30px", Top:"20px" });
    let [btnPosition3, setBtnPosition3] = useState({ Right:"100px", Top:"20px" });   // 만족도 조사 버튼

    useEffect(() => {
        let CurrMediaType = Utils_MediaQuery.GetMediaDetailType(props.objWindowSize.Width,props.objWindowSize.Height);
        console.log("Topmenu : ",CurrMediaType);
        if(CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_M_FOLD_S){
            setBtnSize({ Width:"50px", Height:"54px" });
            setBtnPosition1({ Left:"15px", Top:"15px" });
            setBtnPosition2({ Right:"15px", Top:"15px" });
            setBtnPosition3({ Right:"80px", Top:"20px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.P_M_IPHONE_S ||
            CurrMediaType === Utils_MediaQuery.P_M_ANDROID_S ||
            CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_L){
            setBtnSize({ Width:"60px", Height:"65px" });
            setBtnPosition1({ Left:"15px", Top:"15px" });
            setBtnPosition2({ Right:"15px", Top:"15px" });
            setBtnPosition3({ Right:"90px", Top:"20px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_T_GALAXY_S ||
            CurrMediaType === Utils_MediaQuery.P_T_IPAD_S ||
            CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_L){
            setBtnSize({ Width:"92px", Height:"99px" });
            setBtnPosition1({ Left:"15px", Top:"15px" });
            setBtnPosition2({ Right:"15px", Top:"15px" });
            setBtnPosition3({ Right:"122px", Top:"20px" });
        }
        

        else if(CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_D_DESKTOP_S ||
            CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_L){
            setBtnSize({ Width:"150px", Height:"161px" });
            setBtnPosition1({ Left:"15px", Top:"15px" });
            setBtnPosition2({ Right:"15px", Top:"15px" });
            setBtnPosition3({ Right:"180px", Top:"20px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.L_M_FOLD_S){
            setBtnSize({ Width:"50px", Height:"54px" });
            setBtnPosition1({ Left:"15px", Top:"15px" });
            setBtnPosition2({ Right:"15px", Top:"15px" });
            setBtnPosition3({ Right:"80px", Top:"20px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.L_M_IPHONE_S ||
            CurrMediaType === Utils_MediaQuery.L_M_ANDROID_S ||
            CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_L){
            setBtnSize({ Width:"60px", Height:"65px" });
            setBtnPosition1({ Left:"15px", Top:"15px" });
            setBtnPosition2({ Right:"15px", Top:"15px" });
            setBtnPosition3({ Right:"90px", Top:"20px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.L_T_GALAXY_S ||
            CurrMediaType === Utils_MediaQuery.L_T_IPAD_S ||
            CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_L){
            setBtnSize({ Width:"92px", Height:"99px" });
            setBtnPosition1({ Left:"15px", Top:"15px" });
            setBtnPosition2({ Right:"15px", Top:"15px" });
            setBtnPosition3({ Right:"122px", Top:"20px" });
        }
        
        else if(CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.L_D_DESKTOP_S ||
            CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_L){
            setBtnSize({ Width:"92px", Height:"99px" });
            setBtnPosition1({ Left:"15px", Top:"15px" });
            setBtnPosition2({ Right:"15px", Top:"15px" });
            setBtnPosition3({ Right:"122px", Top:"20px" });
        }
    },[props.objWindowSize])

    return(
        <ContainerBox>
            {
                (props.isControlMode)
                ?<>
                    <ImgButton BackImg={IcoWorldMap} BtnWidth={btnSize.Width} BtnHeight={btnSize.Height} 
                    Position="absolute" Left={btnPosition1.Left} Top={btnPosition1.Top}
                    onClick={() => {
                        props.funcWorldMapMode(false);
                    }} />
                </>
                :<>
                    <ImgButton BackImg={IcoWorldBack} BtnWidth={btnSize.Width} BtnHeight={btnSize.Height}  
                    Position="absolute" Left={btnPosition1.Left} Top={btnPosition1.Top}
                    onClick={() => {
                        props.funcWorldMapMode(true);
                    }}/>
                </>
            }
            {/* <ImgButton BackImg={IcoSatisfaction} BtnWidth={btnSize.Width} BtnHeight={btnSize.Height} 
                Position="absolute" Right={btnPosition3.Right} Top={btnPosition2.Top}
                onClick={() => {
                    if(props.funcSatisfactionUrl)props.funcSatisfactionUrl();
                }}/> */}
            <ImgButton BackImg={IcoCompanyList} BtnWidth={btnSize.Width} BtnHeight={btnSize.Height} 
                Position="absolute" Right={btnPosition2.Right} Top={btnPosition2.Top}
                onClick={() => {
                    props.funcBoothListModal();
                }}/>
        </ContainerBox>
    );
}

export default Topmenu;