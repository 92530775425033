import { useMediaQuery } from 'react-responsive'

export const PortraitScreen = ({children}) => {
  const isPortrait = useMediaQuery({orientation:'portrait'})
  return isPortrait
      ? children
      : null
}

export const LandscapeScreen = ({children}) => {
  const isLandscape = useMediaQuery({orientation:'landscape'})
  return isLandscape
      ? children
      : null
}

export const MobileIphone = ({children}) => {
  // const isMobile = useMediaQuery({orientation:'portrait', maxWidth: 376})
  const isMobile = useMediaQuery({maxWidth: 376})
  return isMobile
      ? children
      : null
}

export const Mobile = ({children}) => {
  // const isMobile = useMediaQuery({orientation:'portrait', minWidth: 377, maxWidth: 413})
  const isMobile = useMediaQuery({minWidth: 377, maxWidth: 413})
  return isMobile
      ? children
      : null
}

export const Tablet = ({children}) => {
  // const isTablet = useMediaQuery({orientation:'portrait', minWidth: 414, maxWidth: 754})
  const isTablet = useMediaQuery({minWidth: 414, maxWidth: 754})
  return isTablet
      ? children
      : null
}

export const HeightMin = ({children}) => {
  // const isTablet = useMediaQuery({orientation:'portrait', minWidth: 414, maxWidth: 754})
  const isTabletH = useMediaQuery({maxHeight: 375})
  return isTabletH
      ? children
      : null
}

export const HeightMax = ({children}) => {
  // const isTablet = useMediaQuery({orientation:'portrait', minWidth: 414, maxWidth: 754})
  const isTabletH = useMediaQuery({minHeight: 376})
  return isTabletH
      ? children
      : null
}

export const TabletBig = ({children}) => {
  // const isDesktop = useMediaQuery({orientation:'portrait', minWidth: 755})
  const isDesktop = useMediaQuery({minWidth: 755, maxWidth: 1024})
  return isDesktop
      ? children
      : null
}

export const Desktop = ({children}) => {
  // const isDesktop = useMediaQuery({orientation:'portrait', minWidth: 755})
  const isDesktop = useMediaQuery({minWidth: 1025})
  return isDesktop
      ? children
      : null
}



// export { Desktop, Tablet, Mobile, MobileIphone };