//import * as BABYLON from '@babylonjs/core';
import * as BABYLON from 'babylonjs';
import * as recast from "recast-detour";
import Constants from '../common/Constants.js';
import CameraManager from './CameraManager.js';
import Marker from './Marker.js';


export default class NavimeshManager {

    static m_objInit;
    static m_navimeshManager;
    static agents;
    static arrAvata;
    static marker;
    static isMoveAble = true;
    static arrMeshes;
    static crowd;
    static navmeshParameters;
    static agentParams;

    constructor(_objInit) {

        if (_objInit !== NavimeshManager.m_objInit) {
            throw new Error("NavimeshManager 생성은 다음과 같이 해주세요 - NavimeshManager.GetInstance()");
            // return;
        }

    }

    /**
     *===============================================================================
     * GetInstance()           :
     *===============================================================================
     */
    static GetInstance() {
        if (NavimeshManager.m_navimeshManager == null) {
            NavimeshManager.m_navimeshManager = new NavimeshManager(NavimeshManager.m_objInit);
            NavimeshManager.m_objInit = {};
            NavimeshManager.GetInstance().setNavimesh();
        }
        return NavimeshManager.m_navimeshManager;
    }

    static dispose() {
        NavimeshManager.navigationPlugin.dispose();
        NavimeshManager.navigationPlugin = null;

        NavimeshManager.marker.dispose();
        NavimeshManager.marker = null;
        NavimeshManager.agents = null;
        NavimeshManager.arrAvata = null;
        NavimeshManager.arrMeshes = null;
    }

    setNavimesh() {
        NavimeshManager.navigationPlugin = new BABYLON.RecastJSPlugin(recast);
        NavimeshManager.marker = new Marker();

        NavimeshManager.agents = [];
        NavimeshManager.arrAvata = [];
        NavimeshManager.arrMeshes = [];

        NavimeshManager.navmeshParameters = {
            cs: 0.3,
            ch: 0.2,
            walkableSlopeAngle: 35,
            walkableHeight: 1,
            walkableClimb: 1,
            walkableRadius: 1,
            maxEdgeLen: 12.,
            maxSimplificationError: 1.3,
            minRegionArea: 8,
            mergeRegionArea: 20,
            maxVertsPerPoly: 6,
            detailSampleDist: 6,
            detailSampleMaxError: 1,
        };

    }

    static AddMeshForNavimesh(_mesh) {
        NavimeshManager.arrMeshes.push(_mesh);
    }

    static MakeNavimesh(_isVisible = true, _isDebugMode = false) {

        for (let i = 0; i < NavimeshManager.arrMeshes.length; i++) {
            let meshNavi = NavimeshManager.arrMeshes[i];
            meshNavi.isVisible = _isVisible;
            // meshNavi.position.y -= 0.1;
        }

        let meshRoad = BABYLON.Mesh.MergeMeshes(NavimeshManager.arrMeshes, false);
        // meshRoad.parent = Constants.SCENE.getMeshByName('__root__');
        meshRoad.parent = Constants.CONTAINER_MAP;
        if (meshRoad === undefined) {
            setTimeout(() => { // 아이폰에서 연산속도 부족할 때가 있어서 임시 예외처리
                meshRoad.isVisible = _isVisible;
            }, 500);
        } else {    
            meshRoad.isVisible = _isVisible;
        }

        NavimeshManager.navigationPlugin.createNavMesh([meshRoad], NavimeshManager.navmeshParameters);

        if (_isDebugMode) {
            var navmeshdebug = NavimeshManager.navigationPlugin.createDebugNavMesh(Constants.SCENE);
            navmeshdebug.position = new BABYLON.Vector3(0, 0, 0);

            var matdebug = new BABYLON.StandardMaterial('matdebug', Constants.SCENE);
            matdebug.diffuseColor = new BABYLON.Color3(0.1, 0.2, 1);
            matdebug.alpha = .5;
            navmeshdebug.material = matdebug;
        }

        NavimeshManager.GetInstance().setCrowd();

        Constants.SCENE.onPointerObservable.add((pointerInfo) => {

            if (pointerInfo.type === BABYLON.PointerEventTypes.POINTERUP) {
                if (pointerInfo.pickInfo.hit) {
                    NavimeshManager.GetInstance().pointerDown(pointerInfo.pickInfo.pickedMesh)
                }
            }
        });

        Constants.SCENE.onBeforeRenderObservable.add(NavimeshManager.GetInstance().onNavigating.bind(this));
    }


    setCrowd() {

        const max_num_agent = Constants.MULTI_MAX_NUM + Constants.BOT_NAMES.length;
        NavimeshManager.crowd = NavimeshManager.navigationPlugin.createCrowd(max_num_agent, 1, Constants.SCENE);
        NavimeshManager.agentParams = {
            radius: .8,
            height: 0.2,
            maxAcceleration: 8.0,
            maxSpeed: 5.0,
            collisionQueryRange: 0.5,
            pathOptimizationRange: 0.0,
            separationWeight: 1.0
        };
    }

    pointerDown() {

        if (CameraManager.cameraMode === CameraManager.MINI_MAP_MODE) return;

        /*         for(var i = 1; i < _arrPickMeshes.length; i++)
                {
                    let pickMesh = _arrPickMeshes[i];
                    pickMesh.isPickable = false;
                }
         */
        let startingPoint = NavimeshManager.GetInstance().getGroundPosition();

        if (startingPoint) { // we need to disconnect camera from canvas
            //let agentss = crowd.getAgents();
            //console.log("PICK POINT :: ", "Y : ", NavimeshManager.navigationPlugin.getClosestPoint(startingPoint).y);

            const naviClosestPoint = NavimeshManager.navigationPlugin.getClosestPoint(startingPoint);
            if (naviClosestPoint.x === 0 && naviClosestPoint.z === 0) return;
            //if(NavimeshManager.navigationPlugin.getClosestPoint(startingPoint).y > 1.1)  return;
            NavimeshManager.marker.onPointing();
            NavimeshManager.crowd.agentGoto(NavimeshManager.agents[0], naviClosestPoint);

        }
    }

    getGroundPosition() {
        if (!NavimeshManager.isMoveAble) return;
        var pickinfo = Constants.SCENE.pick(Constants.SCENE.pointerX, Constants.SCENE.pointerY);

        console.log("찍힌 PickInfo : ", pickinfo, pickinfo.pickedMesh.name);

        // 찍힌 위치가 이동이 가능한 영역으로 등록되어 있다면
        if (pickinfo.hit && Constants.allowedMeshNames.indexOf(pickinfo.pickedMesh.name)>=0) {
            //let point = new BABYLON.Vector3(pickinfo.pickedPoint.x, 0, pickinfo.pickedPoint.z) // y값을 0으로 주면 계단등 높은곳으로 이동을 못함 
            let point = pickinfo.pickedPoint;
            pickinfo.pickedPoint.y += 1;
            // if (pickinfo.pickedPoint.y > 1.20) point = null; //건물등 클릭시 이동 금지
            console.log("PICK POINT :: ", "X : " + pickinfo.pickedPoint.x + " / ", "Y : " + pickinfo.pickedPoint.y + " / ", "Z : " + pickinfo.pickedPoint.z);
            function _two(n) { return Math.floor(n*100.)/100. }
            console.log(_two(pickinfo.pickedPoint.x) + ", " + _two(pickinfo.pickedPoint.y) + ", " + _two(pickinfo.pickedPoint.z));
            return point;
        }

        return null;
    }


    // 매 프레임 렌더링 시에 아바타 특성 조정
    onNavigating() {

        let me = NavimeshManager.agents[0];
        NavimeshManager.agents.forEach(agent => {
                

            if ( agent === me )
            {
                if (agent === null || agent === undefined) return;
                if (CameraManager.cameraMode === CameraManager.MINI_MAP_MODE) {
                    NavimeshManager.crowd.agentGoto(agent, NavimeshManager.navigationPlugin.getClosestPoint(agent.mesh.position));
                    NavimeshManager.arrAvata[0].speed = 0;
                    agent.mesh.position = NavimeshManager.crowd.getAgentPosition(agent.idx);
                    return;
                }

                agent.mesh.position = NavimeshManager.crowd.getAgentPosition(agent.idx);

            }
            let vel = NavimeshManager.crowd.getAgentVelocity(agent.idx);
            if ( agent.target )
                NavimeshManager.crowd.getAgentNextTargetPathToRef(agent.idx, agent.target.position);

            //let dT = _engine.getDeltaTime();
            let nSpeed = vel.length().toFixed(2);
            let pos;

            // 속도가 특정 이상이면 작동.
            if (nSpeed >= Constants.AVATAR_THRESHOLD_SPEED) {
                //_avatar.StartAnimation(nSpeed);
                NavimeshManager.arrAvata[agent.idx].speed = nSpeed;
                // vel.normalize();
                // 아바타 방향 전환 - 이동하는 방향으로 회전
                var desiredRotation = Math.atan2(vel.x, vel.z);
                if ( agent==me )
                    agent.mesh.rotation = new BABYLON.Vector3(0, desiredRotation, 0);
                else
                    agent.trf.rotation = new BABYLON.Vector3(0, desiredRotation, 0);
                pos = agent.mesh.position;
                NavimeshManager.arrAvata[agent.idx].onMoveStart();
            } else {
                if (pos) NavimeshManager.ForceMove(pos);
                NavimeshManager.arrAvata[agent.idx].speed = 0;
                NavimeshManager.arrAvata[agent.idx].onMoveStop();
            }
        });
    }


    static AddAvatar(_avatar, _targetMesh) {
        NavimeshManager.arrAvata.push(_avatar);
        let pos = _avatar.position || new BABYLON.Vector3(Constants.STARTING_POINT.x, Constants.STARTING_POINT.y, Constants.STARTING_POINT.z);
        let transform = new BABYLON.TransformNode();
        if ( !_targetMesh ) // avatar의 indicator가 없는 경우. 즉 bot일 경우 transform 객체를 
        {
            _avatar.meshAvatar.position = new BABYLON.Vector3(0, 0, 0);
            _avatar.meshAvatar.parent = transform;
        }
        let agentIndex = NavimeshManager.crowd.addAgent(pos, NavimeshManager.agentParams, transform);
        _avatar.agentIndex = agentIndex;
        NavimeshManager.agents.push({
            name: _avatar.strName,
            idx: agentIndex,
            trf: transform,
            mesh: _avatar.meshNavi,
            target: _targetMesh
        });

    }

    static ForceMove(_vecPosition) {
        NavimeshManager.crowd.agentTeleport(NavimeshManager.agents[0].mesh, NavimeshManager.navigationPlugin.getClosestPoint(_vecPosition));
        //console.log("forceMove :::: ",  NavimeshManager.agents[0].mesh.position )
    }

    static set moveAble(_isAble) {
        NavimeshManager.isMoveAble = _isAble;
    }
};