import Communicator from '../common/Communicator.js';
import Constants from '../common/Constants.js';
import CommunicatorEvent from '../common/event/CommunicatorEvent.js';
export default class LoadingProgressManager
{
    static LOADED_DATA;

    constructor(_objInit)
    {
        //super();
        if (_objInit !== LoadingProgressManager.m_objInit) 
        {
            throw new Error( "LoadingProgressManager 생성은 다음과 같이 해주세요 - LoadingProgressManager.GetInstance()" ); 
           // return;
        }
    }

    static GetInstance(_scene, _avatar)
    {
        if(LoadingProgressManager.m_loadingProgressManager == null) 
        {
            LoadingProgressManager.m_loadingProgressManager = new LoadingProgressManager(LoadingProgressManager.m_objInit);
            LoadingProgressManager.m_objInit = {};

            LoadingProgressManager.LOADED_DATA = 0;
        }
        return LoadingProgressManager.m_loadingProgressManager;
    }

    
    static AddData(_nData){
        if(_nData === NaN || NaN === undefined)return;
        LoadingProgressManager.LOADED_DATA += _nData;
        let nPercent = LoadingProgressManager.LOADED_DATA / Constants.TOTAL_DATA * 100;
        //console.log("AddData :::: ", _nData, LoadingProgressManager.LOADED_DATA, nPercent);
        Communicator.sendEvent(CommunicatorEvent.WORLD_LOADING, nPercent);
    }
};

