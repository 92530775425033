import React, { useEffect, useState, useRef  } from 'react';
import styled from "styled-components";

import { Block, ContainerBox } from "../../common/StyleMasterComp";

import ChatTab from './ChatTab';
import ImgCloseChat from "../../images/ico_chatOff.png";
import ImgOpenChat from "../../images/ico_chatOn.png";
import Utils_MediaQuery from '../../common/Utils_MediaQuery';


let MenuBar = styled.div`
    width: 621px; height:38px;
    cursor: pointer;
    left:10px;
`;

let ChatContentArea = styled.div`
    pointer-events:all;
    margin:0; padding:0; margin-top:0;
    width: ${props => props.Width || "621px"}; 
    height: ${props => props.Height || "296px"};
    background: rgba(0, 0, 0, 0.46);
    border-radius: 0 0 22px 22px;
    font-size: ${props => props.FontSize || "16px"};
    ul{
        list-style-type: none;
        margin: 0; padding: 0;
        overflow-x:hidden;
        overflow-y:scroll;
        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: rgba(136,136,136,1);
        }
        ::-webkit-scrollbar { 
            border-radius: 10px;
            width:5px;
            background-color: rgba(0,0,0,0.3);
        }
        height:${props=> props.UlHeight ||"220px"};
        padding-right:10px;
        padding-top:5px;
        padding-left:5px;
    }
    li{
        padding-top:1px;
    }
    
    text-align: left;
    padding:10px;
    box-sizing:border-box;
    color:#fff;
    text-shadow: 2px 2px 2px #000000;
`;

let InputTxtSet = styled.div``;

let InputBox = styled.input`
    pointer-events:all;
    font-family: 'Spoqa HanSansNeo'; font-size: 16px; color: #fff;  font-weight: 600;
    box-sizing: border-box; 
    width: ${props => props.Width || "621px"}; 
    height: 40px; 
    position:absolute;
    bottom:0px;
    border-radius: 100px;
    background-color: #ebf3ff; 
    border: none; 
    color: black;
    padding:0 47px;
    ::placeholder {
        font-family: 'Spoqa HanSansNeo'; font-size: 16px; color: #000;  font-weight: 600;
    }
    :focus { 
        outline:none; font-weight:600;
    }
`;

let BtnOpen = styled.div`
    pointer-events:all;
    bottom:0;
    width:40px;
    height:40px;
    background-image: url(${props => props.ImgURL});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    cursor: pointer;
    z-index:20;
`;
let BtnClose = styled.div`
    pointer-events:all;
    bottom:0;
    width:40px;
    height:40px;
    background-image: url(${props => props.ImgURL});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    cursor: pointer;
    z-index:20;
`;

function ChatingBox(props,ref){
    let [iSelectMenuIndex,setSelectMenuIndex] = useState(0);
    let [inputData, setInputData] = useState('');
    let useChatData = [];

    const messagesEndRef = useRef(null);


    let arrUseChatData = () => {
        props.arrChatdata.map((data) => {
            if (iSelectMenuIndex === 0 && data.roomType === "Room_Type_Normal") {
                useChatData.push(data);
            } else if (iSelectMenuIndex === 1 && data.roomType === "Room_Type_Lobby") {
                useChatData.push(data);
            }

            return null;
        })
    }

    arrUseChatData();


    const onKeyPress = (e) => {
        if (inputData === "") return;
        if (e.key === 'Enter') {

            props.funcSendChatData({menuIndex:iSelectMenuIndex, msg: inputData});
            // setChatData([...chatData, { roomType: "Room_Type_Normal", messageType: "chatting", msg: inputData }]);
            setInputData("");
        }
    }
    

    useEffect(() => {
        { messagesEndRef.current?.scrollIntoView({ behavior: "smooth" }) }
    });


    let [chatContentInfo, setChatContentInfo] = useState({ Width:"621px", Height:"334px", BlackBoxHeight:"296px", UlHeight:"220px" });
    let [chatFontSize, setChatFontSize] = useState({ FontSize:"16px" });

    useEffect(() => {
        let CurrMediaType = Utils_MediaQuery.GetMediaDetailType(props.objWindowSize.Width,props.objWindowSize.Height);
        console.log("페이지명 : ",CurrMediaType);

        if(CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_M_FOLD_S){
            setChatContentInfo({ Width:"290px", Height:"300px", BlackBoxHeight:"260px", UlHeight:"200px" });
            setChatFontSize({FontSize:"12px"});
            
        }
        else if(CurrMediaType === Utils_MediaQuery.P_M_IPHONE_S){
            setChatContentInfo({ Width:"340px", Height:"300px", BlackBoxHeight:"260px", UlHeight:"200px" });
            setChatFontSize({FontSize:"12px"});
        }
        else if(CurrMediaType === Utils_MediaQuery.P_M_ANDROID_S ||
            CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_L){
            setChatContentInfo({ Width:"350px", Height:"300px", BlackBoxHeight:"260px", UlHeight:"200px" });
            setChatFontSize({FontSize:"14px"});
        }

        else if(CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_T_GALAXY_S ||
            CurrMediaType === Utils_MediaQuery.P_T_IPAD_S ||
            CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_L){
            setChatContentInfo({ Width:"590px", Height:"334px", BlackBoxHeight:"296px", UlHeight:"220px" });
            setChatFontSize({FontSize:"15px"});
        }

        else if(CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_D_DESKTOP_S ||
            CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_L){
            setChatContentInfo({ Width:"621px", Height:"334px", BlackBoxHeight:"296px", UlHeight:"220px" });
            setChatFontSize({FontSize:"16px"});
        }


        else if(CurrMediaType === Utils_MediaQuery.L_M_FOLD_S){
            setChatContentInfo({ Width:"480px", Height:"150px", BlackBoxHeight:"112px", UlHeight:"55px" });
            setChatFontSize({FontSize:"12px"});
        }

        else if(CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_S){
            setChatContentInfo({ Width:"480px", Height:"220px", BlackBoxHeight:"180px", UlHeight:"120px" });
            setChatFontSize({FontSize:"12px"});
        }

        else if(CurrMediaType === Utils_MediaQuery.L_M_IPHONE_S ||
            CurrMediaType === Utils_MediaQuery.L_M_ANDROID_S ||
            CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_L){
            setChatContentInfo({ Width:"480px", Height:"210px", BlackBoxHeight:"162px", UlHeight:"105px" });
            setChatFontSize({FontSize:"12px"});
        }

        else if(CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.L_T_GALAXY_S ||
            CurrMediaType === Utils_MediaQuery.L_T_IPAD_S ||
            CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_L){
            setChatContentInfo({ Width:"500px", Height:"312px", BlackBoxHeight:"270px", UlHeight:"210px" });
            setChatFontSize({FontSize:"14px"});
        }

        else if(CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.L_D_DESKTOP_S ||
            CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_L){
            setChatContentInfo({ Width:"621px", Height:"334px", BlackBoxHeight:"296px", UlHeight:"220px" });
            setChatFontSize({FontSize:"16px"});
        }
    },[props.objWindowSize])

    return(
        <ContainerBox>
            <Block ContainerWidth={chatContentInfo.Width} ContainerHeight={chatContentInfo.Height}>
                {

                    (!props.isUseChatRoom)
                        ? <BtnOpen ImgURL={ImgOpenChat} onClick={() => {
                            props.funcUseChatRoom(true);
                            }} 
                        />
                        : <>
                            <MenuBar>
                                {
                                    (iSelectMenuIndex === 0)
                                        ? <ChatTab iSelectMenuIndex={iSelectMenuIndex} funcSelectMenuIndex={setSelectMenuIndex}></ChatTab>
                                        : <ChatTab iSelectMenuIndex={iSelectMenuIndex} funcSelectMenuIndex={setSelectMenuIndex}></ChatTab>
                                }
                            </MenuBar>
                            <ChatContentArea Width={chatContentInfo.Width} Height={chatContentInfo.BlackBoxHeight} 
                                UlHeight={chatContentInfo.UlHeight} FontSize={chatFontSize.FontSize}> 
                                <ul>
                                    {
                                        useChatData.map((data, i) => {
                                            return (
                                                <ChatTxtConvert data={data} key={i} />
                                            )
                                        })
                                    }
                                    <div ref={messagesEndRef} />
                                </ul>
                            </ChatContentArea>


                            <InputTxtSet>
                                <InputBox Width={chatContentInfo.Width} placeholder="메시지를 입력해주세요." 
                                    onChange={(e) => { setInputData(e.target.value)}} 
                                    onKeyPress={onKeyPress} value={inputData} />
                            </InputTxtSet>
                            <BtnClose ImgURL={ImgCloseChat} onClick={() => {
                                props.funcUseChatRoom(false);
                            }} />
                        </>
                }
            </Block>
        </ContainerBox>
    )
}

function ChatTxtConvert(props) {
    //console.log(props.data)
    if (props.data.messageType === 'login' && props.data.roomType === 'Room_Type_Normal') {
        return <li><span style={{ color: "#00BEFF" }}>[입장] {props.data.name}{props.data.msg}</span></li>
    } else if (props.data.messageType === 'logout' && props.data.roomType === 'Room_Type_Normal') {
        return <li><span style={{ color: "#FF2225" }}>[퇴장] {props.data.name}{props.data.msg}</span></li>
    } else if (props.data.messageType === 'notice' && props.data.roomType === 'Room_Type_Normal') {
        return <li><span style={{ color: "#00BE00" }}>[알림]{props.data.msg}</span></li>
    } else if (props.data.messageType === 'chatting') {
        return <li>{props.data.name} : {props.data.msg}</li>
    } else {
        return null;
    }
}

export default React.forwardRef(ChatingBox);