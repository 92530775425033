/**
 @Class          : SelectableObject
 @Maker          : Sean
 @Date           : 2024-02-22
 @Debug DATE     : 
 @Debug Contents : -
 @Version        : 1.0
 @Description    : 부스 클래스
 */

import * as BABYLON from 'babylonjs';
import gsap from "gsap";
import Constants from '../common/Constants';
import EventDispatcher from '../common/event/EventDispatcher';
import CameraManager from './CameraManager';
import ShadowManager from './ShadowManager';
import NavimeshManager from './NavimeshManager';
import CommunicatorEvent from '../common/event/CommunicatorEvent';
import Communicator from '../common/Communicator';
import DataManager from '../common/data/DataManager';

/**
 * 선택 가능한 Object
 * 마우스를 올리면 highlight가 된다.
 * 
 * click되면 특정 행위를 할 수 있도록 설정할 수 있다.
 * 
 * 이 Object는 hierarchy 특성상 가장 최상단에 node나 mesh가 있을 수 있다.
 * 하위메쉬에서 마우스 동작이 일어나도 걸려 있는 전체 mesh가 반응하도록 한다.
 */
export default class SelectableObject extends EventDispatcher {
    'use strict';
    static SETTING_COMPLETE = "Setting_Complete";

    /**
     *===============================================================================
     * constructor()      :
     * mesh와 node중 하나는 무조건 인자로 들어와야 한다.
     * mesh는 이미 world에 추가 되어 있다고 생각한다.
     * 
     * @param _objData  :   기본 데이터
     * @param _mesh     : 이 객체의 mesh
     * @param _node     : 이 객체의 node
     * @param _cbClick  : 클릭시 콜백
     * @param _cbOver   : 클릭시 콜백
     * @param _cbOut    : 클릭시 콜백
     *===============================================================================
     */
    constructor(_objData, _mesh, _node, _cbClick=null, _cbOver=null, _cbOut=null, _hlColor=Constants.PORTAL_MOUSE_OVER_COLOR ) {

        if ( !_mesh && !_node )
            throw Error("SelectableObject:constructor() : mesh와 node중 하나는 명시해 줘야 합니다. ");
        super();

        this.objCompanyData = _objData
        this.mesh= _mesh;       // 전체 부모 mesh
        this.node= _node;       // 전체 부모 node. mesh와 node중 하나만 있으면 된다.
        this.childrenMeshes = null;
        this.isIntersects = false;
        this.videoVidTex = null;
        this.strBaseURL = null;
        this.highlightLayer = null;
        this.glowLayer = null;
        this.timeOut = null;
        this.cbClick = _cbClick;
        this.cbOver = _cbOver;
        this.cbOut = _cbOut;
        this.hlColor = _hlColor
        // this.linesBooth = null;
        // this.linesBooth2 = null;
        // this.linesBooth3 = null;

        this.init();
    }

    setClickHandler( cb ) { this.cbClick = cb; }
    setMouseOverHandler( cb ) { this.cbOver = cb; }
    setMouseOutHandler( cb ) { this.cbOut = cb; }

    /**
     *===============================================================================
     * dispose()             :
     *===============================================================================*/
    dispose() {
        clearTimeout(this.timeOut);
        this.highlightLayer.dispose();
        this.highlightLayer = null;
        this.mesh.dispose();
        this.mesh= null;
    }

    /**
     *===============================================================================
     * init()             :
     *===============================================================================*/
    init() {
        this.strBaseURL = "";

        this.highlightLayer = new BABYLON.HighlightLayer("highlight", Constants.SCENE);
        this.glowLayer = new BABYLON.GlowLayer("glow", Constants.SCENE);
        this.glowLayer.intensity = 1;
        this.glowLayer.isEnabled = false;

        this.setInteraction();

        // Constants.SCENE.onBeforeAnimationsObservable.add(this.onCheckIntersects.bind(this));

        setTimeout(() => {
            // this.dispatchEvent({
            //     type: Booth.SETTING_COMPLETE,
            //     data: null
            // })
        }, 200);
    }

    /**
     *===============================================================================
     * setInteraction()             : 지정된 최상위 객체의 모든 하위 객체들을 대상으로 
     *                                interaction을 정의해줌
     *===============================================================================*/
    setInteraction() 
    {
        // node와 mesh중 설정된 최상위 개체를 넣어 준다.
        const topNode = (this.node || this.mesh);
        // console.log("NODE : ", topNode.name);
        const children = topNode.getChildren();
        this.childrenMeshes = children.filter(i=>i instanceof BABYLON.Mesh);
        // console.log(this.childrenMeshes);
        this.childrenMeshes.forEach(i => {
            this.setAction(i, this.childrenMeshes);
        });
    }

    /**
     *===============================================================================
     * setAction()             : 인터렉션 액션 바인딩
     *===============================================================================*/
    setAction(_mesh, allChildren) {
        _mesh.isPickable = true;
        _mesh.actionManager = new BABYLON.ActionManager(Constants.SCENE);

        function setHighlight()
        {
            const c = this.hlColor;
            allChildren.forEach(i=>{
                this.highlightLayer.addMesh(i, new BABYLON.Color3(c.r, c.g, c.b));
                // console.log("mesh names : ", i.name);
            });
        }

        function unsetHighlight()
        {
            allChildren.forEach(i=>{
                this.highlightLayer.removeMesh(i);
            });
        }

        _mesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, (_meshEvent) => {
            setHighlight.bind(this)();
            if ( this.cbOver && typeof this.cbOver=="function" )
                this.cbOver().bind(this);
        }));
        _mesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, (_meshEvent) => {
            unsetHighlight.bind(this)();
            if ( this.cbOut && typeof this.cbOut=="function" )
                this.cbOut().bind(this);
        }));

        _mesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickUpTrigger, (_meshEvent) => {
            unsetHighlight.bind(this)();
            this.onClickInfo(_mesh);
            if ( this.cbClick && typeof this.cbClick=="function" )
                this.cbClick();
        }));
    }

    /**
     *===============================================================================
     * onClickInfo()            :  셀렉트
     * @param _objMeshData       : 인포메이션 mesh
     *===============================================================================
     */
    onClickInfo(_mesh) {
        if (!this.isIntersects) return;

        switch (_mesh.name) {
            case this.meshBooth.name + "_kiosk":
                Communicator.sendEvent(CommunicatorEvent.OPEN_CATALOG, this.objCompanyData.catalog_images);;
                break;
            case this.meshBooth.name + "_homepage":
                Communicator.sendEvent(CommunicatorEvent.OPEN_URL_LINK, this.objCompanyData.homepage);
                break;
            case this.meshBooth.name + "_sns":
                Communicator.sendEvent(CommunicatorEvent.OPEN_URL_LINK, this.objCompanyData.sns);
                break;
            case this.meshBooth.name + "_video":
                Communicator.sendEvent(CommunicatorEvent.OPEN_VIDEO, this.objCompanyData.video_url);
                break;
            case this.meshBooth.name + "_disket":
                Communicator.sendEvent(CommunicatorEvent.OPEN_PDF, this.objCompanyData.ir_doc);
                break;
            default:
                break;
        }
    }

}