import React, { useContext, useEffect, useState } from "react";
import styled from 'styled-components';
import { customEvent } from "../../common/ga";
import { Block, ContainerBox, TextBox } from "../../common/StyleMasterComp"

// import ReactGA from 'react-ga';
import Utils_MediaQuery from "../../common/Utils_MediaQuery";
import Utils from "../../../common/Utils";
import NavimeshManager from "../../../content/NavimeshManager";
import { MainContext } from "../../page/PageMain";

let CardBox = styled(Block)`
    :first-child { border-top: 1px solid #ddd; }
    :last-child { border-bottom: 1px solid #ddd; }
    width: 100%; height:80px;
    border-bottom:1px solid #ddd;
    display: flex;
    overflow: hidden;
`;

let TextContentsBlock = styled(Block)`
    width: calc(100% - 100px);
`;

let MoveBtnBlock = styled(Block)`
    width: 100px; margin-top:24px;
`;

let OpenBtnBlock = styled(Block)`
    width: 150px; margin-top:24px;
`;

let BtnMove = styled.div`
    pointer-events:all;
    position: absolute;
    left:10px;
    cursor: pointer;
    background-color: #00a0e0; 
    width:66px; 
    height: 36px; 
    text-align: center;   
    line-height: 35px; 
    border-radius: 100px; 
    color: #fff;
`;

let BtnOpen = styled(BtnMove)`
    width:91px; 
    background-color: #003d90; 
`;

function BoothListCard(props) {
    let [txtInfo1, setTxtInfo1] = useState({ FontSize:"20px", MarginLeft:"10px", MarginTop:"10px"  });
    let [txtInfo2, setTxtInfo2] = useState({ FontSize:"15px", MarginLeft:"10px", MarginTop:"10px" });
    const mainContext = useContext(MainContext);

    useEffect(() => {
        let CurrMediaType = Utils_MediaQuery.GetMediaDetailType(props.objWindowSize.Width,props.objWindowSize.Height);
        // console.log("BoothListCard : ",CurrMediaType);
        if(CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_M_FOLD_S){
            setTxtInfo1({ FontSize:"10px", MarginLeft:"10px", MarginTop:"20px" });
            setTxtInfo2({ FontSize:"8px", MarginLeft:"10px", MarginTop:"5px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.P_M_IPHONE_S){
            setTxtInfo1({ FontSize:"11px", MarginLeft:"10px", MarginTop:"20px" });
            setTxtInfo2({ FontSize:"11px", MarginLeft:"10px", MarginTop:"5px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.P_M_ANDROID_S ||
            CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_L){
            setTxtInfo1({ FontSize:"12px", MarginLeft:"10px", MarginTop:"15px" });
            setTxtInfo2({ FontSize:"12px", MarginLeft:"10px", MarginTop:"5px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_T_GALAXY_S ||
            CurrMediaType === Utils_MediaQuery.P_T_IPAD_S ||
            CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_L){
            setTxtInfo1({ FontSize:"22px", MarginLeft:"10px", MarginTop:"10px" });
            setTxtInfo2({ FontSize:"16px", MarginLeft:"10px", MarginTop:"5px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_D_DESKTOP_S ||
            CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_L){
            setTxtInfo1({ FontSize:"22px", MarginLeft:"10px", MarginTop:"10px" });
            setTxtInfo2({ FontSize:"16px", MarginLeft:"10px", MarginTop:"5px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.L_M_FOLD_S ||
            CurrMediaType === Utils_MediaQuery.L_M_IPHONE_S ||
            CurrMediaType === Utils_MediaQuery.L_M_ANDROID_S ||
            CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_L){
            setTxtInfo1({ FontSize:"20px", MarginLeft:"10px", MarginTop:"10px" });
            setTxtInfo2({ FontSize:"14px", MarginLeft:"10px", MarginTop:"8px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.L_T_GALAXY_S){
            setTxtInfo1({ FontSize:"18px", MarginLeft:"10px", MarginTop:"14px" });
            setTxtInfo2({ FontSize:"13px", MarginLeft:"10px", MarginTop:"10px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.L_T_IPAD_S ||
            CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_L){
            setTxtInfo1({ FontSize:"18px", MarginLeft:"10px", MarginTop:"13px" });
            setTxtInfo2({ FontSize:"13px", MarginLeft:"10px", MarginTop:"10px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.L_D_DESKTOP_S ||
            CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_L){
            setTxtInfo1({ FontSize:"20px", MarginLeft:"10px", MarginTop:"10px" });
            setTxtInfo2({ FontSize:"14px", MarginLeft:"10px", MarginTop:"10px" });
        }
    },[props.objWindowSize])

    return (
        <ContainerBox>
            <CardBox Width="500px" Height="100px">
                <TextContentsBlock>
                    <TextBox FontSize={txtInfo1.FontSize} MarginLeft={txtInfo1.MarginLeft} LineHeight="115%" MarginTop={txtInfo1.MarginTop} FontColor="#000" FontWeight="400"> 
                    {/* {props.data.id}.  */}
                    {props.data.name} </TextBox>
                    <TextBox FontSize={txtInfo2.FontSize} MarginLeft={txtInfo2.MarginLeft} LineHeight="115%" FontColor="#000" FontWeight="200"  MarginTop={txtInfo2.MarginTop}> {props.data.desc} </TextBox>
                </TextContentsBlock>
                {/* 이동버튼 */}
                <MoveBtnBlock>
                    <BtnMove onClick={()=>{
                        // console.log("BoothListCard move click",props.data);
                        console.log("move to : ", props.data.name, props.data.position);
                        NavimeshManager.crowd.agentGoto(0, props.data.position);
                        mainContext.setBoothListView(false);       // 바로가기 창 닫기
                        // Utils.goDeepLink(props.data.link);
                    }}>길찾기</BtnMove>
                </MoveBtnBlock>
                {/* 바로열기 버튼 */}
                <OpenBtnBlock>
                    <BtnOpen 
                        onClick={()=>{
                            console.log("BoothListCard move click",props.data);
                            // KDG 아래 전부 주석 처리함.
                            // props.funcSendTenant(props.data);
                            // ReactGA.event({
                            //     category: '부스 리스트_이동 버튼',
                            //     action: '부스 이동',
                            //     label:props.data.name+" 입장"
                            // });

                            // customEvent('부스 리스트_이동 버튼','부스 이동',props.data.name+" 입장")
                            // window.location.href = props.data.link;
                            console.log("Link to : ", btoa(props.data.link.split(':')[1]));

                            Utils.goDeepLink(props.data.link);
                        }}>열기
                    </BtnOpen>
                </OpenBtnBlock>
                
            </CardBox>
        </ContainerBox>
    )
}

export default BoothListCard;