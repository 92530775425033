import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {ContainerBox,TextBox} from "../../common/StyleMasterComp"
import Utils_MediaQuery from "../../common/Utils_MediaQuery";

let AuthTextBox = styled(TextBox)`
    font-family: 'NIXGONFONTS V2.0';
    /* border: 1px solid #fff; */
`;

function ToptitleTxt(props){

    let [txtInfo1, setTxtInfo1] = useState({ FontSize:"70px" });
    let [txtInfo2, setTxtInfo2] = useState({ FontSize:"25px" });

    useEffect(() => {
        let CurrMediaType = Utils_MediaQuery.GetMediaDetailType(props.objWindowSize.Width,props.objWindowSize.Height);

        if(CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_M_FOLD_S ||
            CurrMediaType === Utils_MediaQuery.P_M_IPHONE_S ||
            CurrMediaType === Utils_MediaQuery.P_M_ANDROID_S){ 
            setTxtInfo1({ FontSize: "36px", MarginTop: "0px" });
            setTxtInfo2({ FontSize: "15px", MarginTop: "0px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_L){
            setTxtInfo1({ FontSize: "36px", MarginTop: "0px" });
            setTxtInfo2({ FontSize: "15px", MarginTop: "0px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_S){
            setTxtInfo1({ FontSize: "40px", MarginTop: "0px" });
            setTxtInfo2({ FontSize: "20px", MarginTop: "0px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.P_T_GALAXY_S ||
            CurrMediaType === Utils_MediaQuery.P_T_IPAD_S ||
            CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_L){
            setTxtInfo1({ FontSize: "55px", MarginTop: "0px" });
            setTxtInfo2({ FontSize: "25px", MarginTop: "0px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_D_DESKTOP_S ||
            CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_L){
            setTxtInfo1({ FontSize: "70px", MarginTop: "0px" });
            setTxtInfo2({ FontSize: "25px", MarginTop: "0px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.L_M_FOLD_S){
            setTxtInfo1({ FontSize: "14px", MarginTop: "0px" });
            setTxtInfo2({ FontSize: "6px", MarginTop: "0px" });
        }
        
        else if(CurrMediaType === Utils_MediaQuery.L_M_IPHONE_S ||
            CurrMediaType === Utils_MediaQuery.L_M_ANDROID_S){
            setTxtInfo1({ FontSize: "18px", MarginTop: "0px" });
            setTxtInfo2({ FontSize: "6px", MarginTop: "0px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_L){
            setTxtInfo1({ FontSize: "20px", MarginTop: "0px" });
            setTxtInfo2({ FontSize: "7px", MarginTop: "0px" });
        }
        
        else if(CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.L_T_GALAXY_S ||
            CurrMediaType === Utils_MediaQuery.L_T_IPAD_S){
            setTxtInfo1({ FontSize: "26px", MarginTop: "0px" });
            setTxtInfo2({ FontSize: "9px", MarginTop: "0px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_L){
            setTxtInfo1({ FontSize: "35px", MarginTop: "0px" });
            setTxtInfo2({ FontSize: "12px", MarginTop: "0px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.L_D_DESKTOP_S ||
            CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_L){
            setTxtInfo1({ FontSize: "60px", MarginTop: "0px" });
            setTxtInfo2({ FontSize: "20px", MarginTop: "0px" });
        }
    },[props.objWindowSize])

    return(
        <ContainerBox style={{WebkitTextStrokeColor:"lightgray", WebkitTextStrokeWidth:"thin"}}>
            <AuthTextBox FontSize={txtInfo1.FontSize} FontColor="#fff">버츄얼 새만금 <span style={{color:"#F6C944"}}>웹 포털</span></AuthTextBox>
            <AuthTextBox FontSize={txtInfo2.FontSize} FontColor="#fff">Welcome to Virtual Saemangeum</AuthTextBox>
            {/* <AuthTextBox FontSize={txtInfo1.FontSize} FontColor="">웹 포털</AuthTextBox> */}
        </ContainerBox>
    );
}

export default ToptitleTxt;