import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Block } from "./common/StyleMasterComp";

import DataManager from '../common/data/DataManager';
import Constants_ui from './common/Constants_ui';
import Utils_ui from './common/Utils_ui';

import PageAuth from './page/PageAuth';
import PageLoading from './page/PageLoading';
import PageMain from './page/PageMain';
import { _deepLinkList } from './components/ModalBoothList/ListContents';
import Constants from '../common/Constants';
import Utils from '../common/Utils';
import { useParams } from 'react-router-dom';

function SMGDeepLink() {
    let { seqnum } = useParams();
    const links = Constants.DEEP_LINK_LIST;
    let flagFirstCall = false;


    useEffect(()=>{
        if ( flagFirstCall ) // 한번 call 되었을 경우 다시 실행하지 않는다.
            return;
        Utils.goDeepLink(links[seqnum].link);
    },[]);

    return (
        <>
        </>
    );
}

export default SMGDeepLink