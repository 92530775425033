import React,{useState, useEffect} from "react";


function AudioBgm(props){
    const [audio] = useState(new Audio('./sound/Netstream_Master.mp3'));
    const [playing, setPlaying] = useState(true);

    useEffect(() => {
        playing ? audio.play() : audio.pause();
        audio.loop = true;
        // KDG 볼륨 사운드
        // audio.volume = 1;
        audio.volume = 0.3;
    },[playing]);

    useEffect(() => {
        setPlaying(props.isBgmPlay);
    }, [props.isBgmPlay]);

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(true));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(true));
        };
    }, [playing]);

    function handleVisibilityChange() {
        if (document.hidden) {
            // the page is hidden
            setPlaying(false);
        } else {
            // the page is visible
            setPlaying(true);
        }
    }

    useEffect(() => {
        document.addEventListener("visibilitychange", handleVisibilityChange, false);
    }, [playing]);
    return(
        <>
        </>
    );
}

export default AudioBgm;