/**
 @Class          : Snow
 @Maker          : 윤휘원
 @Date           : 2022-01-06
 @Debug DATE     : 
 @Debug Contents : -
 @Version        : 1.0
 @Description    : 별 파티클 클래스
 */

import * as BABYLON from 'babylonjs';
import Constants from '../common/Constants';
import EventDispatcher from '../common/event/EventDispatcher';

export default class Snow extends EventDispatcher {
    'use strict';

    static LOAD_COMPLETE = "Load_Complete";

    /**
     *===============================================================================
     * constructor()      :
     * @param _strMeshURL :  
     *===============================================================================
     */
    constructor(_strMeshURL) {
        super();

        this.theta = 0;
        this.solidParticleSystem = null;
        this.meshSnow = null;
    }

    /**
     *===============================================================================
     * dispose()             :
     *===============================================================================*/
    dispose() {
        this.particleSystem.dispose();
        Constants.SCENE.onAfterRenderObservable.remove(this.move.bind(this));
    }

    /**
     *===============================================================================
     * init()             :
     *===============================================================================*/
    async init(_intValue) {
        this.particleSystem = new BABYLON.ParticleSystem("particles", 200);

        this.particleSystem.minEmitBox = new BABYLON.Vector3(-200, -30, -100); // Starting all from
        this.particleSystem.maxEmitBox = new BABYLON.Vector3(200, 75, 100); // To...
        this.particleSystem.emitRate = 200;
        this.particleSystem.minSize = 0.4;
        this.particleSystem.maxSize = 0.8;
        this.particleSystem.minLifeTime = 3;
        this.particleSystem.maxLifeTime = 4;
        this.particleSystem.updateSpeed = .03;

        this.particleSystem.preWarmCycles = 100;
        this.particleSystem.preWarmStepOffset = 10;

        if (_intValue === 1) {
            this.particleSystem.particleTexture = new BABYLON.Texture("images/star.png");
            this.particleSystem.direction1 = new BABYLON.Vector3(0, 0, 0);
            this.particleSystem.direction2 = new BABYLON.Vector3(0, 0, 0);

            this.particleSystem.color1 = new BABYLON.Color4.FromHexString("#2bceffFF");
            this.particleSystem.color2 = new BABYLON.Color4.FromHexString("#ffbf2bFF");
            this.particleSystem.colorDead = new BABYLON.Color4.FromHexString("#FFAE000");
        } else {
            this.particleSystem.particleTexture = new BABYLON.Texture("images/petal.png");

            this.particleSystem.blendMode = BABYLON.BaseParticleSystem.BLENDMODE_STANDARD;
            this.particleSystem.color1 = new BABYLON.Color4.FromHexString("#ffffffff");
            this.particleSystem.color2 = new BABYLON.Color4.FromHexString("#ffffffff");
            this.particleSystem.colorDead = new BABYLON.Color4.FromHexString("#ffffff00");

            this.particleSystem.direction1 = new BABYLON.Vector3(-10, -5, -10);
            this.particleSystem.direction2 = new BABYLON.Vector3(10, -5, 10);

            this.particleSystem.minInitialRotation = Math.PI / 2;
            this.particleSystem.maxInitialRotation = Math.PI;
        }
        this.particleSystem.start(0);

        let container = new BABYLON.MeshBuilder.CreateBox("StarContainer", {
            size: 1
        }, Constants.SCENE);
        container.position = new BABYLON.Vector3(-300, 200, -50);
        container.rotation = new BABYLON.Vector3(0,0, Math.PI/3);
        container.visibility = 0;
    }

    /**
     *===============================================================================
     * move()             :
     *===============================================================================*/
    move() {
        //console.log("sdfsfddfs");
        this.solidParticleSystem.setParticles();
        this.theta += Math.PI / 5000;
        this.meshSnow.rotation.y = this.theta;
    }

    /**
     *===============================================================================
     * start()             :
     *===============================================================================*/
    start() {
        if (this.moveSnow !== null) return;
        this.moveSnow = Constants.SCENE.onAfterRenderObservable.add(this.move.bind(this));
    }

    /**
     *===============================================================================
     * stop()             :
     *===============================================================================*/
    stop() {
        this.theta = 0;
        Constants.SCENE.onAfterRenderObservable.remove(this.moveSnow);
        this.moveSnow = null;
    }
}