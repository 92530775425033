/**
 @Class          : BoothManager
 @Maker          : 윤휘원 (박정훈 팀장님 작업 상속)
 @Date           : 2022-01-05
 @Debug DATE     : 
 @Debug Contents : -
 @Version        : 1.0
 @Description    : 부스 관리 클래스
 */

import * as BABYLON from 'babylonjs';
import EventDispatcher from '../common/event/EventDispatcher';
import Constants from '../common/Constants';
import LoadingProgressManager from './LoadingProgressManager';
import DataManager from '../common/data/DataManager';
import Booth from './Booth';
import SelectableObject from './SelectableObject';
import Utils from '../common/Utils';

export default class BoothManager extends EventDispatcher
{
    'use strict';

    static LOAD_COMPLETE = "Load_Complete";

    /**
    *===============================================================================
    * constructor()      : 
    *===============================================================================*/
    constructor()
    {
        super();
        
        this.meshBooth = null;
        this.arrBooth = [];
        
        this.init();
    }

    /**
    *===============================================================================
    * dispose()               : 
    *===============================================================================*/
    dispose(){

        for(let i=0; i < this.arrBooth.length; ++i)
        {
            const booth = this.arrBooth[i];
            booth.dispose();
        }
    }
    
    init(){
        this.setPortalsSMG();
        this.setBoothsSMG();
        this.loadBoothMesh();
    }

    /**
    *===============================================================================
    * loadBoothMesh()    : 부스 메시 로드
    *===============================================================================*/
    loadBoothMesh(){
        
        let loadData = 0;
        const onMeshLoadProgress = (_data) => {
            let dataUnit = _data.loaded - loadData;
            loadData = _data.loaded;
            LoadingProgressManager.AddData(dataUnit);
        }

        let onMeshLoadComplete = (_newMeshes) => {
            this.meshBooth = _newMeshes[0];
            this.setBooths();
        }
        try
        {
        BABYLON.SceneLoader.ImportMesh("", Constants.ASSETS_URL, Constants.BOOTH_FILE_NAME, Constants.SCENE, onMeshLoadComplete, onMeshLoadProgress);

        }
        catch(e) {}
    }

    /**
    *===============================================================================
    * setBooths()    : 개별 부스 생성
    *===============================================================================*/
    setBooths(){
        this.arrBooth =[];
        let arrDatas = DataManager.GetCompanyDatas();
        let objData;
        let meshBooth;
        let i = 0;

        for(i=0; i < arrDatas.length; ++i)
        {
            objData = arrDatas[i];
            meshBooth =  this.meshBooth.clone("booth_" + String(i));
            const booth = new Booth(objData, meshBooth);
            booth.addEventListener(Booth.SETTING_COMPLETE,()=>{
                LoadingProgressManager.AddData(10000);
                booth.removeEventListener(Booth.SETTING_COMPLETE);
            })
            this.arrBooth.push(booth);
           
        }
        this.dispatchEvent({
            type: BoothManager.LOAD_COMPLETE,
            data: null
        })
        this.meshBooth.dispose();
    }

    /**
     */
    setBoothsSMG()
    {
        /*  새만금 맵에는 portal로 시작하는 포털들이 이미 정의 되어 있음  */
        const booths = Constants.SCENE.getNodeByName('booth').getChildren(null, true);

        // console.log("All Nodes : ", booths);
        booths.forEach(i => {
            // console.log(i.initial);
            const company = Constants.SMG_BOOTH_LIST.find(j=>j.initial===i.name)
            this.arrBooth.push(
                new SelectableObject(null, null, i, 
                    ()=>{ 
                        alert(company.name + " 기업 홈페이지로 이동합니다. 이동하지 않을 경우 상단 주소창에서 팝업 차단을 해제하고 다시 객체를 선택해 주세요.");
                        window.open(company.link, '_blank');
                    }, null, null, Constants.BOOTH_MOUSE_OVER_COLOR));
        });
    }

    setPortalsSMG()
    {
        /*  새만금 맵에는 portal로 시작하는 포털들이 이미 정의 되어 있음  */
        const portals = Constants.SCENE.getNodes().filter(i=>i.name.toLowerCase().indexOf('portal')>=0);

        // console.log("All Nodes : ", portals);
        // 포털 하위 객체들에 대해 select가 가능하도록 처리한다. 클릭시 goDeepLink를 수행.
        portals.forEach(i => {
            // console.log(i.name);
            const dl = Constants.DEEP_LINK_LIST.find(j=>j.portal===i.name).link;
            this.arrBooth.push(new SelectableObject(null, null, i, ()=>{ Utils.goDeepLink(dl)} ));
        });
    }
};

