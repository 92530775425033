export default class Utils_ui
{
    'use strict';
    
    /**===============================================================================
    * SortJSON()        : JSON 데이터 소팅
    * @param data       : JSON 데이터
    * @param key        : attribute명(소팅기준 값)
    * @param type       : "asc","desc"
    *===============================================================================*/
    static SortJSON (data, key, type) 
    {
        if (type === undefined) type = "asc";

        return (data.sort(function (a, b) {
            let x = a[key];
            let y = b[key];
            let returnData=null;
            if (type === "desc") {
                returnData =  x > y ? -1 : x < y ? 1 : 0;
            } else if (type === "asc") {
                returnData =  x < y ? -1 : x > y ? 1 : 0;
            }

            return returnData;
        }));
    };

    /**===============================================================================
    * PercentToPx()        : full size기준으로 입력%만큼 리턴
    * @param nFullSize     : 대상 full 사이즈
    * @param nPercent      : 리턴받기 원하는 %
    * @param isAddPx       : 뒤에 "px" 붙여서 리턴 여부 결정
    *===============================================================================*/
    static PercentToPx (nFullSize, nPercent, isAddPx=true) 
    {
        let returnData = ""
        
        if(isAddPx) returnData = (nFullSize / 100)*nPercent+"px";
        else returnData = (nFullSize / 100)*nPercent;

        return returnData;
    };

    /**===============================================================================
    * ObjDeepCopy()        : 문자,obj만 들어있는 원본 Object를 복사해야 할 때 사용(함수, 정규표현식,.. 데이터 유실위험있음)
    * @param objOri        : 복사할 원본 Object
        *===============================================================================*/
    static ObjDeepCopy(objOri) {
        let objClone = {};
        for (let key in objOri) {
            if (typeof objOri[key] == "object" && objOri[key] != null) {
                objClone[key] = Utils_ui.ObjDeepCopy(objOri[key]);
            } else {
                objClone[key] = objOri[key];
            }
        }
    
        return objClone;
    };
};

