import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Block, ContainerBox } from "../common/StyleMasterComp";
import { ModalBoothList, ModalCatalog, ModalMovie } from "../modals";
import Utils_ui from "../common/Utils_ui";


import Communicator from "../../common/Communicator";
import CommunicatorEvent from "../../common/event/CommunicatorEvent";
import Constants_ui from "../common/Constants_ui";

import {AudioBgm, Topmenu} from "../components/pageMain";
import ChatingBox from "../components/pageMain/ChatingBox";

// import ReactGA from 'react-ga';
import { customEvent, pageView } from "../common/ga";
import ModalLoading from "../modals/ModalLoading";
import ModalTutorial from "../modals/ModalTutorial";

let ChatingBoxContainer = styled.div`
    position: absolute;
    bottom: 15px;
    left: 15px;
`;


let ChatdataDefault = [
    { roomType: "Room_Type_Normal", messageType: "chatting", name: "SMG", msg: "안녕하세요, 버츄얼 새만금에 오신 것을 환영합니다. 왼쪽 상단에는 전체를 볼 수 있는 월드맵이, 오른쪽 상단에는 인게이지 메타버스에 쉽게 접근할수 있는 목록이 있습니다." }
    , { roomType: "Room_Type_Lobby", messageType: "chatting", name: "SMG", msg: "안녕하세요, 버츄얼 새만금에 오신 것을 환영합니다. 왼쪽 상단에는 전체를 볼 수 있는 월드맵이, 오른쪽 상단에는 인게이지 메타버스에 쉽게 접근할수 있는 목록이 있습니다." }
]


export const MainContext = createContext(null);

function PageMain(props){
    let [isControlMode, setControlMode] = useState(true);
    let [isBoothListView, setBoothListView] = useState(false);
    let [isEnterBooth, setIsEnterBooth] = useState(false);
    let [flagOpenTutorial, setFlagOpenTutorial] = useState(true);

    let [isUseChatRoom, setUseChatRoom] = useState(false);
    let [chatData, setChatData] = useState(ChatdataDefault);

    let [isOpenCatalog, setOpenCatalog] = useState(false);
    let [arrCatalogData, setCatalogData] = useState([]);
    
    const sampleRef = useRef({});
    const contextValue = {
        setBoothListView, // context에 bootListView를 show할 flag변수의 set 함수를 설정
        setFlagOpenTutorial,
    }

    let worldMapMode = (_isWorldMapView) =>{
        if(_isWorldMapView === null) return;

        if(_isWorldMapView) Communicator.sendEvent(CommunicatorEvent.CHANGE_CAMERA_MODE, "Play_Mode");
        else Communicator.sendEvent(CommunicatorEvent.CHANGE_CAMERA_MODE, "Mini_Map_Mode");
        
        setControlMode(_isWorldMapView);
    }

    // 만족도 조사 버튼 클릭
    let satisfactionUrl = () => {
        window.open("https://forms.gle/2xVdNY99sbABrwnG8", '_blank');
    }

    // 부스 리스트 버튼 클릭
    let boothListModal = () => {
        setBoothListView(true);
    }

    //부스 리스트 팝업에서 이동 버튼 클릭 시
    let sendTenant = (_objVar) => {
        console.log("sendTenant")
        Communicator.sendEvent(CommunicatorEvent.GO_TO_TENANT, { id: _objVar.id });
        setBoothListView(false)
        setControlMode(true);
    }

    //서버에서 보내온 신규 챗 데이터
    let updateChatData = (_objVar) => {
        ChatdataDefault = [...ChatdataDefault, { roomType: _objVar.roomType, messageType: _objVar.messageType, name: _objVar.name, msg: _objVar.msg }];
        //ChatdataDefault.push({ roomType: _objVar.roomType, messageType: _objVar.messageType, name: _objVar.name, msg: _objVar.msg });
        setChatData(ChatdataDefault);
    }


    let sendChatData = (_objVar) => {
        if (_objVar.menuIndex === 0) {
            if(!Constants_ui.TESTMODE_CHAT)
            {
                Communicator.sendEvent(CommunicatorEvent.SEND_CHAT_MESSAGE,
                    { roomType: "Room_Type_Normal", messageType: "chatting", msg: _objVar.msg });
            }else{
                updateChatData({ roomType: "Room_Type_Normal", messageType: "chatting", msg: _objVar.msg, name:"테스트모드"})
            }
        } else {
            Communicator.sendEvent(CommunicatorEvent.SEND_CHAT_MESSAGE,
                { roomType: "Room_Type_Lobby", messageType: "chatting", msg: _objVar.msg });
        }
    }

    let [isBgmPlay,setBgmPlay] = useState(true);
    let [isOpenVideo, setOpenVideo] = useState(false);
    let [strVideoUrl, setVideoUrl] = useState('');

    let onOpenVideo = (_strVars) => {
        //console.log('비디오 팝업 URL:', _strVars);
        if (_strVars !== "") {
            setVideoUrl(_strVars);
            setOpenVideo(true);
            setBgmPlay(false);
        }
    }

    let onOpenBoardVideo= (_objVar) => {
        console.log("onOpenBoardVideo :", _objVar);
        if (_objVar.boardType === "A") {
            // ReactGA.event({
            //     category: "총장님 말씀 전광판"
            //     , action: "전광판 클릭"
            //     , label: "동영상 팝업"
            // });
            customEvent("총장님 말씀 전광판","전광판 클릭","동영상 팝업")
        } else if (_objVar.boardType === "B") {
            // ReactGA.event({
            //     category: "산관학 전광판"
            //     , action: "전광판 클릭"
            //     , label: "동영상 팝업"
            // });
            customEvent("산관학 전광판","전광판 클릭","동영상 팝업");
        }
        onOpenVideo(_objVar.url);
    }

    let onOpenCatalog = (_strVars) => {
        //console.log('이미지 팝업 URL:', _strVars);
        if (_strVars !== "") {
            setCatalogData(_strVars);
            setOpenCatalog(true);
        }
    }

    let onOpenUrl = (_strUrl) => {
        if (_strUrl !== "") window.open(_strUrl, '_blank');
    }

    let onOpenPDFUrl=(_strUrl)=>{
        if (_strUrl !== "") window.open(_strUrl, '_blank');
    }

    let onEnterBooth=(_objVar)=>{
        console.log("onEnterBooth :", _objVar);
        if(_objVar !== null && _objVar.name !== "")
        // ReactGA.event({
        //     category: '부스 입장',
        //     action: '부스 이동',
        //     label:_objVar.name+" 입장"
        // });
        setIsEnterBooth(true); // 부스 애니메이션 시작
        customEvent('부스 입장','부스 이동',_objVar.name+" 입장");  //통계
    }

    useEffect(() => {
        
        Communicator.reciveEvent(CommunicatorEvent.OPEN_VIDEO, onOpenVideo);
        Communicator.reciveEvent(CommunicatorEvent.OPEN_CATALOG, onOpenCatalog);
        
        //전광판 클릭 시 영상 재생팝업
        Communicator.reciveEvent(CommunicatorEvent.OPEN_BOARDVIDEO, onOpenBoardVideo);

        Communicator.reciveEvent(CommunicatorEvent.OPEN_URL_LINK, onOpenUrl);
        Communicator.reciveEvent(CommunicatorEvent.OPEN_PDF, onOpenPDFUrl);

        Communicator.reciveEvent(CommunicatorEvent.ENTER_BOOTH, onEnterBooth);
        Communicator.reciveEvent(CommunicatorEvent.RECEIVE_CHAT_MESSAGE, updateChatData);

        Communicator.reciveEvent(CommunicatorEvent.ANIMEND_BOOTH,()=>{setIsEnterBooth(false)});
        pageView("/main");  //통계
    }, [])

    return(
        <MainContext.Provider value={contextValue}>
            <ContainerBox>
                <AudioBgm isBgmPlay={isBgmPlay}/>
                <Block ContainerHeight={Utils_ui.PercentToPx(props.objWindowSize.Height,50)}>
                    <Topmenu isControlMode={isControlMode} funcWorldMapMode={worldMapMode}
                            objWindowSize={props.objWindowSize} 
                            funcBoothListModal={boothListModal}
                            funcSatisfactionUrl={satisfactionUrl}
                    />
                </Block>
                <Block ContainerHeight={Utils_ui.PercentToPx(props.objWindowSize.Height,50)} style={{display:"none"}}>
                    <ChatingBoxContainer>
                    {
                        (isControlMode) 
                        ? <ChatingBox
                            funcUseChatRoom={setUseChatRoom}
                            funcSendChatData={sendChatData}
                            arrChatdata={chatData}
                            isUseChatRoom={isUseChatRoom}
                            objWindowSize={props.objWindowSize}
                            sampleRef={sampleRef} /> 
                        : null
                    }
                    </ChatingBoxContainer>
                </Block>

                {isBoothListView ?  <ModalBoothList arrBoothData = {props.arrBoothData} funcSetBoothListView = {setBoothListView} 
                    funcSendTenant = {sendTenant} objWindowSize={props.objWindowSize}/> :null }
                {isOpenVideo ? <ModalMovie strVideoUrl={strVideoUrl} funcSetOpenVideo={setOpenVideo} 
                    funcSetPlaying={setBgmPlay} objWindowSize={props.objWindowSize} /> : null}
                {isOpenCatalog ? <ModalCatalog arrCatalogData={arrCatalogData} funcSetOpenCatalog={setOpenCatalog} objWindowSize={props.objWindowSize}/> : null}
                {isEnterBooth ? <ModalLoading/> : null}
                {flagOpenTutorial ? <ModalTutorial /> : null}
            </ContainerBox>

        </MainContext.Provider>
    )
}

export default PageMain;