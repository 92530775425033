import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { Block, ContainerBox } from "../common/StyleMasterComp";

import Communicator from "../../common/Communicator";
import CommunicatorEvent from "../../common/event/CommunicatorEvent";
import Constants_ui from "../common/Constants_ui";
import Utils_ui from "../common/Utils_ui";

import {ToptitleTxt, MidtitleTxt, BottomInputSet} from '../components/pageAuth';
import Utils_MediaQuery from "../common/Utils_MediaQuery";
import { pageView } from "../common/ga";
import axios from "axios";

let TopTxtBoxContainer = styled.div`
    position: absolute;
    left: ${props=>props.Left||"calc(1.27vw + 20.48px)"};
    top: ${props=>props.Top||"calc(1.27vw + 20.48px)"};
`;

let MidTxtBoxContainer = styled.div`
    position: absolute;
    left: ${props=>props.Left||"calc(1.27vw + 20.48px)"};
    bottom : ${props=>props.Bottom||"1vw"};
`;

let BottomInputSetBoxContainer = styled.div`
    position: absolute;
    left: ${props=>props.Left||"calc(1.27vw + 20.48px)"};
    top: ${props=>props.Top||"1vw"};
`;

function PageAuth(props) {
    //console.log("어스페이지", props);
    let [inputData, setInputData] = useState('');

    const onKeyPress = (e) => {
        if (inputData === "") return;
        if (e.key === 'Enter') onNextPageMove();
    }

    let onPushOkButton = () =>{
        onNextPageMove();
    }

    let onNextPageMove = () => {
        Communicator.sendEvent(CommunicatorEvent.START_CONTENT, { name: inputData });
        props.setSelectPageName(Constants_ui.PAGE_MAIN);
    }

    let [topBoxInfo, setTopBoxInfo] = useState({ Left:"59px", Top:"59px" });
    let [midBoxInfo, setMidBoxInfo] = useState({ Left:"59px", Bottom:"1vw" });
    let [bottomBoxInfo, setBottomBoxInfo] = useState({ Left:"59px", Top:"1vw" });

    async function connectionCount()
    {
        const _url = process.env.REACT_APP_COUNT_CALL_URL + process.env.REACT_APP_COUNT_CALL_PATH;
        console.log(":: Visit count : ", _url);
        axios.defaults.withCredentials = true;
        axios.get(_url, {}, { withCredentials: true })
            .then(function (response) {
                // 성공 핸들링
                console.log("Visit Count call 성공 : ", response);
            })
            .catch(function (error) {
                // 에러 핸들링
                console.error("Visit Count 문제 발생", error);
            })
            .finally(function () {
                // 항상 실행되는 영역
            });
    }
    useEffect(() => {

        (async () => {
            await connectionCount();
        })();
        
        let CurrMediaType = Utils_MediaQuery.GetMediaDetailType(props.objWindowSize.Width,props.objWindowSize.Height);
        console.log("pageAuth : ",CurrMediaType);
        if(CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_M_FOLD_S ||
            CurrMediaType === Utils_MediaQuery.P_M_IPHONE_S){
            setTopBoxInfo({ Left:"15px", Top:"25px" });
            setMidBoxInfo({ Left:"15px", Bottom:"0px" });
            setBottomBoxInfo({ Left:"calc("+ props.objWindowSize.Width/2+"px - (200px / 2))" , Top:"20px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.P_M_ANDROID_S){
            setTopBoxInfo({ Left:"20px", Top:"25px" });
            setMidBoxInfo({ Left:"20px", Bottom:"-10px" });
            setBottomBoxInfo({ Left:"calc("+ props.objWindowSize.Width/2+"px - (200px / 2))" , Top:"40px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_L){
            setTopBoxInfo({ Left:"20px", Top:"25px" });
            setMidBoxInfo({ Left:"20px", Bottom:"0px" });
            setBottomBoxInfo({ Left:"calc("+ props.objWindowSize.Width/2+"px - (200px / 2))" , Top:"20px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_S){
            setTopBoxInfo({ Left:"30px", Top:"35px" });
            setMidBoxInfo({ Left:"30px", Bottom:"0px" });
            setBottomBoxInfo({ Left:"30px", Top:"20px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.P_T_GALAXY_S || 
            CurrMediaType === Utils_MediaQuery.P_T_IPAD_S ||
            CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_L){
            setTopBoxInfo({ Left:"50px", Top:"50px" });
            setMidBoxInfo({ Left:"50px", Bottom:"0px" });
            setBottomBoxInfo({ Left:"50px", Top:"20px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_S || 
            CurrMediaType === Utils_MediaQuery.P_D_DESKTOP_S ||
            CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_L){
            setTopBoxInfo({ Left:"59px", Top:"89px" });
            setMidBoxInfo({ Left:"59px", Bottom:"-60px" });
            setBottomBoxInfo({ Left:"59px", Top:"100px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_S || 
            CurrMediaType === Utils_MediaQuery.L_M_FOLD_S){
            setTopBoxInfo({ Left:"20px", Top:"15px" });
            setMidBoxInfo({ Left:"20px", Bottom:"20px" });
            setBottomBoxInfo({ Left:"20px", Top:"-10px" });
        }

        else if( 
            CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_L){
            setTopBoxInfo({ Left:"20px", Top:"20px" });
            setMidBoxInfo({ Left:"20px", Bottom:"-5px" });
            setBottomBoxInfo({ Left:"20px", Top:"20px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_M_ANDROID_S ||
            CurrMediaType === Utils_MediaQuery.L_M_IPHONE_S){
            setTopBoxInfo({ Left:"20px", Top:"20px" });
            setMidBoxInfo({ Left:"20px", Bottom:"10px" });
            setBottomBoxInfo({ Left:"20px", Top:"0px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_S || 
            CurrMediaType === Utils_MediaQuery.L_T_GALAXY_S){
            setTopBoxInfo({ Left:"25px", Top:"20px" });
            setMidBoxInfo({ Left:"25px", Bottom:"10px" });
            setBottomBoxInfo({ Left:"25px", Top:"5px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_L){
            setTopBoxInfo({ Left:"35px", Top:"35px" });
            setMidBoxInfo({ Left:"35px", Bottom:"-15px" });
            setBottomBoxInfo({ Left:"35px", Top:"40px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_T_IPAD_S){
            setTopBoxInfo({ Left:"39px", Top:"35px" });
            setMidBoxInfo({ Left:"39px", Bottom:"0px" });
            setBottomBoxInfo({ Left:"39px", Top:"20px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_S || 
            CurrMediaType === Utils_MediaQuery.L_D_DESKTOP_S || 
            CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_L){
            setTopBoxInfo({ Left:"59px", Top:"59px" });
            setMidBoxInfo({ Left:"59px", Bottom:"0px" });
            setBottomBoxInfo({ Left:"59px", Top:"20px" });
        }
    },[props.objWindowSize])

    useEffect(()=>{
        pageView("/");  //통계
    },[])
    return (
        <ContainerBox style={{backgroundColor:"rgba(5, 5, 5, 0.6)"}}>
            <Block ContainerHeight={Utils_ui.PercentToPx(props.objWindowSize.Height,50)} >
                <TopTxtBoxContainer Left={topBoxInfo.Left} Top={topBoxInfo.Top}>
                    <ToptitleTxt objWindowSize={props.objWindowSize}/>
                </TopTxtBoxContainer>
            </Block>
            <Block ContainerHeight={Utils_ui.PercentToPx(props.objWindowSize.Height,25)}>
                <MidTxtBoxContainer Left={midBoxInfo.Left} Bottom={midBoxInfo.Bottom}>
                    <MidtitleTxt objWindowSize={props.objWindowSize}/>
                </MidTxtBoxContainer>
            </Block>
            <Block ContainerHeight={Utils_ui.PercentToPx(props.objWindowSize.Height,25)}>
                <BottomInputSetBoxContainer Left={bottomBoxInfo.Left} Top={bottomBoxInfo.Top}>
                    <BottomInputSet 
                        objWindowSize={props.objWindowSize}
                        setInputData={setInputData} 
                        onKeyPress={onKeyPress} 
                        onPushOkButton={onPushOkButton}
                        inputData={inputData}/>
                </BottomInputSetBoxContainer>
            </Block>
        </ContainerBox>
    );
}

export default PageAuth;