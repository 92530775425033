/**
 @Class          : DataManager
 @Maker          : 윤휘원 (박정훈 팀장님 작업 상속)
 @Date           : 2022-01-05
 @Debug DATE     : 
 @Debug Contents : -
 @Version        : 1.0
 @Description    : 데이터 관리 클래스
                   데이터 로드및 관리, 싱글톤
 */

import Constants from "../Constants.js";
import DataLoader from "./DataLoader";

export default class DataManager
{
    static DATA_LOAD_COMPLETE = "Data_Load_Complete" // 데이터 로드 완료 이벤트

    static m_objInit;                   // 객체 생성 확인용
    static m_dataManager;               // 인스턴스 객체
    static m_objUserData = null;        // 사용자 데이터
    static m_arrCompanyDatas = null;    // 업체 데이터 배열
    static m_objUOSData = null;         // 시립대 데이터
    

    /**
    *===============================================================================
    * constructor()      : 
    * @param _objInit   : 싱글톤 생성 확인용 객체
    *===============================================================================*/
    constructor(_objInit)
    {
        //super();

        if (_objInit !== DataManager.m_objInit) 
        {
            throw new Error( "DataManager 생성은 다음과 같이 해주세요 - DataManager.GetInstance()" ); 
           // return;
        }
    }
    
    /**
    *===============================================================================
    * GetInstance()      : 
    * @return            : 커뮤니케이터 인스턴스 리턴
    *===============================================================================*/
    static GetInstance()
    {
        if(DataManager.m_dataManager == null) 
        {
            DataManager.m_dataManager = new DataManager(DataManager.m_objInit);
            DataManager.m_objInit = {};
            DataManager.m_arrCompanyDatas = [];
        }
        return DataManager.m_dataManager;
    }

    /**
    *===============================================================================
    * dispose()         : 
    *===============================================================================*/
    static dispose(){
        DataManager.m_arrCompanyDatas = null;
        DataManager.m_objInit = null;
        DataManager.m_dataManager = null;
    }

    /**
    *===============================================================================
    * reciveEvent()                 : 이벤트 수신
    * @param _funcCallBack          : 데이터 로드 성공 콜벡함수
    * @param _funcCallBackError     : 데이터 로드 실패 콜벡함수
    *===============================================================================*/
    static async LoadData(_funcCallBack, _funcCallBackError)
    {
        if(DataManager.m_dataManager == null) DataManager.GetInstance();
        DataManager.m_funcCallBack = _funcCallBack;
        
        //서버 URL 정보 로드
        // let objServerData = await DataLoader.LoadData(Constants.CONFIG_DATA_URL);
        // Constants.MULTI_SERVER_URL.url = String(objServerData.MULTI_SERVER_URL);        
        // Constants.MULTI_SERVER_URL.port = String(objServerData.MULTI_SERVER_PORT);  
        
        //업체 리스트 데이터 로드
        let companyData = await DataLoader.LoadData(Constants.COMPANY_DATA_URL);
        DataManager.m_arrCompanyDatas = companyData.data;


        //시립대 데이터 로드
        let UOSData = await DataLoader.LoadData(Constants.UOS_DATA_URL);
        DataManager.m_objUOSData = UOSData.data;
        DataManager.m_funcCallBack();

    }

    /**
    *===============================================================================
    * SetUserData()         : 사용자 데이터 설정
    * @param _objUserData   : 사용자 데이터
    *===============================================================================*/
    static SetUserData(_objUserData){
        DataManager.m_objUserData = _objUserData;
    }

    /**
    *===============================================================================
    * GetUserData()     : 사용자 데이터 게터
    * @return           : 사용자 데이터 리턴
    *===============================================================================*/
    static GetUserData(){
        return DataManager.m_objUserData;
    }
    
    /**
    *===============================================================================
    * GetCompoanyDatas()    : 전체 업체 데이터 게터
    * @return               : 전체 업체 데이터 리턴
    *===============================================================================*/
    static GetCompanyDatas(){
        return DataManager.m_arrCompanyDatas;
    }

    /**
    *===============================================================================
    * GetCompoanyDataByID()     : 업체 데이터 게터
    * @param _strID             : 업체 아이디
    * @return                   : 업체 데이터 리턴
    *===============================================================================*/
    static GetCompoanyDataByID(_strID){
        let objData = null;
        let objDataReturn = null;
        for(let i = 0; i <  DataManager.m_arrCompanyDatas.length; i++){
            objData = DataManager.m_arrCompanyDatas[i];
            if(String(objData.id) === String(_strID)) 
            {
                objDataReturn = objData;
                break;
            }
        }
        return objDataReturn;
    }

    /**
    *===============================================================================
    * GetUOSData()          : 시립대 데이터 게터
    * @return               : 시립대 데이터 리턴
    *===============================================================================*/
    static GetUOSData(){
        return DataManager.m_objUOSData;
    }
};

