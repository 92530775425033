import { Vector3 } from "babylonjs";

export default class Constants
{
    'use strict';

    static DEBUG_MODE = false;   // 빌드시 false로 바꿀 것 @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    static CANVAS = null;
    static ENGINE = null;
    static SCENE = null;
    static AVATAR = null;
    static GALAXY_PARTICLE = null;
    //static MULTIPLAY_MANAGER = null;
    
    static MULTI_SERVER_URL = {
        url: 'ws://localhost',
        port: '4000'
    };
    static MULTI_MAX_NUM = 15;
    
    static TOTAL_DATA = 14514880;
    static ASSETS_URL = "./assets/";
    
    static BOOTH_FILE_NAME = "booth.glb";
    // static WORLD_FILE_NAME = "uni_seoul.glb";
    static WORLD_FILE_NAME = "SMG_map.glb";
    // static LOCATOR_FILE_NAME = "Yirumae_ani.glb";
    static LOCATOR_FILE_NAME = "Direction.glb";

    // static CONFIG_DATA_URL = "./json/config.json";
    static COMPANY_DATA_URL = "/json/company_datas.json";
    static UOS_DATA_URL = "/json/UOS_datas.json";
    static MAP_CENTER = {x:0 , y:0, z:0};
    static CONTAINER_MAP = null;

    static OS_ECT = "Os_Ect";
    static OS_IOS = "Os_Ios";
    static OS_ANDROID = "Os_Android";
    static OS_PLATFORM = null;

    // ################################################################################
    // 아바타 관련
    // static AVATAR_FILE_NAME = "Yirumae_ani.glb";
    static AVATAR_FILE_NAME = "SMG_avatar.glb";
    static AVATAR_SWEAT_FILE_NAME = "sweat.glb";
    static AVATAR_SHADOW_IMAGE_URL = "./images/shadow.png";
    static MARKER_IMG_URL = "./images/waypoint2.png";
    static STARTING_POINT = {x:0.07 , y:0.0, z:7};

    static AVATAR_THRESHOLD_SPEED = .4;

    // 봇 설정
    static BOT_MOVE_INTERVAL = 20; // 봇 이동 인터벌. 초 단위
    static BOT_NAVI_LOCATIONS = [
        new Vector3(2.44, 0, 6.94), // 메인 무대 옆
        new Vector3(0.2, 0, 34.64), // 하얀삼거리중심
        new Vector3(0.8, 0, 55.86), // 하단 하얀 사거리 북단
        new Vector3(0.06, 0, 64.48), // 하단 하얀 사거리 남단
        new Vector3(0.21, 0, 89.62), // 최남단 독립 영역
        new Vector3(29.53, 0, 53.16), // 좌측 부스영역 중간
        new Vector3(-34.8, 0, 48.71), // 우측 부스영역 중간
        new Vector3(-48.59, 0, 16.09), // 컨벤션 앞
        new Vector3(-53.02, 0, 4.06), // 귀신의 집 앞
        new Vector3(-47.27, 0, -2.22), // hub3
        new Vector3(-20.28, 0, -48.15), // 핵심보전지구
        new Vector3(0.56, 0, -53.19), // 저스트다운
        new Vector3(14.95, 0, -50.02), // 저스트 고
        new Vector3(52.8, 0, -7.57), // 맵 좌측 분수대
        new Vector3(54.74, 0, 18.46), // hub2
    ]
    static BOT_NAMES = [
        // 'David',
        '양념용량',
        // '디캐릭',
        '만금이',
        'NEW10kGold',
        'Burning',
        'Spark',
        'ONCE',
    ]
// NAME을 설정한 만큼 봇이 나옴
    // 부스 및 포털 설정
    static BOOTH_MOUSE_OVER_COLOR = {r:1.0, g:0.0, b:0.0};
    static PORTAL_MOUSE_OVER_COLOR = {r:1.0, g:0.8, b:0.0};

    static DEEP_LINK_LIST = 
    [
        {"id":"Wy4VO", "name":"미래의 새만금", "desc":"허브1", "link":"engage:eyJjb21tYW5kIjoiT3BlblNlc3Npb24iLCJzbHVnIjoiV3k0Vk8vcm9vbS12aWV3IyJ9", "portal":"portal_hub1", "position":new Vector3(0.15, 1.25, 3.88)},
        {"id":"0Omnn", "name":"새만금청장실", "desc":"허브1", "link":"engage:eyJjb21tYW5kIjoiT3BlblNlc3Npb24iLCJzbHVnIjoiME9tbm4vcm9vbS12aWV3IyJ9", "portal":"portal_governmentoffice", "position":new Vector3(53.87, 1.08, 18.95)},
        {"id":"eXryP", "name":"새만금 저스트다운", "desc":"허브1", "link":"engage:eyJjb21tYW5kIjoiT3BlblNlc3Npb24iLCJzbHVnIjoiZVhyeVAvcm9vbS12aWV3IyJ9", "portal":"Portal_justdown", "position":new Vector3(0.56, 0, -53.19)},
        {"id":"J6EWe", "name":"관광레저의 새만금", "desc":"허브2", "link":"engage:eyJjb21tYW5kIjoiT3BlblNlc3Npb24iLCJzbHVnIjoiSjZFV2Uvcm9vbS12aWV3IyJ9", "portal":"Portal_hub2", "position":new Vector3(54.74, 0, 18.46)},
        {"id":"AZQ8V", "name":"새만금 저스트고", "desc":"허브2", "link":"engage:eyJjb21tYW5kIjoiT3BlblNlc3Npb24iLCJzbHVnIjoiQVpROFYvcm9vbS12aWV3IyJ9", "portal":"Portal_justgo", "position":new Vector3(14.95, 0, -50.02)},
        {"id":"7m4qQ", "name":"현재의 새만금", "desc":"허브3", "link":"engage:eyJjb21tYW5kIjoiT3BlblNlc3Npb24iLCJzbHVnIjoiN200cVEvcm9vbS12aWV3IyJ9", "portal":"Portal_hub3", "position":new Vector3(-46.94, 1.08, -3.75)},
        {"id":"oDWO8", "name":"새만금 핵심보전지구", "desc":"허브3", "link":"engage:eyJjb21tYW5kIjoiT3BlblNlc3Npb24iLCJzbHVnIjoib0RXTzgvcm9vbS12aWV3IyJ9", "portal":"Portal_park", "position":new Vector3(-17.84, 1.08, -47.89)},
        {"id":"4v0oq", "name":"국립새만금간척박물관", "desc":"허브3", "link":"engage:eyJjb21tYW5kIjoiT3BlblNlc3Npb24iLCJzbHVnIjoiNHYwb3Evcm9vbS12aWV3IyJ9", "portal":"Portal_museum", "position":new Vector3(-55.23, 1.08, -34.61)},
        {"id":"pOrZg", "name":"VR 호러 방탈출", "desc":"허브3", "link":"engage:eyJjb21tYW5kIjoiT3BlblNlc3Npb24iLCJzbHVnIjoicE9yWmcvcm9vbS12aWV3IyJ9", "portal":"Portal_ghost", "position":new Vector3(-53.02, 0, 4.06)},
        {"id":"BZaJE", "name":"새만금 컨벤션", "desc":"컨벤션", "link":"engage:eyJjb21tYW5kIjoiT3BlblNlc3Npb24iLCJzbHVnIjoiQlphSkUvcm9vbS12aWV3IyJ9", "portal":"Portal_convention", "position":new Vector3(-48.59, 0, 16.09)},
    ];

    static allowedMeshNames = 
    [
        "floor",
        "스타팅포인트-Mat",
    ]

    static downloadURLs =
    {
        appStoreUrl     : 'https://apps.apple.com/us/app/engage-professional-metaverse/id1532036164',
        playStoreUrl    : 'https://play.google.com/store/apps/details?id=com.ivre.engage&pli=1',
        pcDownURL       : 'https://metadcarrick.co.kr/33',
        // winDownURL    : 'https://engagevr.io/installer/ENGAGE_Installer.exe';
        // macDownURL    : 'https://engagevr.io/installer/ENGAGE.dmg';
        // pcDownURL     : 'https://engagevr.io/desktop-computer-downloads/';
    }

    static SMG_BOOTH_LIST = 
    [
        { "name": "한국건설생활환경시험연구원", "initial": "KCL", "link": "https://kcl.re.kr" },
        { "name": "OCISE", "initial": "OCISE", "link": "https://www.ocise.co.kr" },
        { "name": "건설기계부품연구원", "initial": "koceti", "link": "https://koceti.re.kr" },
        { "name": "에스씨", "initial": "SC", "link": "http://www.s-cable.co.kr" },
        { "name": "자동차융합기술원", "initial": "jiat", "link": "https://www.jiat.re.kr" },
        { "name": "전북테크노파크", "initial": "jbtp", "link": "https://www.jbtp.or.kr" },
        { "name": "제이아이테크", "initial": "ji", "link": "https://www.ji-tech.co.kr" },
        { "name": "한국산업기술시험원", "initial": "kti", "link": "https://customer.ktl.re.kr" },
        { "name": "한국에너지공단", "initial": "e", "link": "https://www.energy.or.kr" },
        { "name": "한국에너지기술평가원", "initial": "ketep", "link": "https://www.ketep.re.kr" },
        { "name": "다스코", "initial": "dasco", "link": "https://dascohex.com" },
        { "name": "현대플라스포", "initial": "hd", "link": "http://www.hd-hyundaiplaspo.com" },
    ]


}