import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Utils_ui from "../../common/Utils_ui";
import {ContainerBox, Block, TextBox} from "../../common/StyleMasterComp";
import Utils_MediaQuery from "../../common/Utils_MediaQuery";

let Progress = styled.div`
    position: absolute;
    bottom: 0px;
    background-color: white;
    width: ${props => props.percent}%;
    transition: width 400ms ease-in-out;
    height: 1vh;
`;

function LoadingProgressBar(props) {

    let [positionInfo, setPositionInfo] = useState({ FontSize:"30px", Height_Box1:"60", Height_Box2:"40" });
    
    useEffect(() => {

        let CurrMediaType = Utils_MediaQuery.GetMediaDetailType(props.objWindowSize.Width,props.objWindowSize.Height);
        console.log("LoadingProgressBar : ",CurrMediaType);
        if(CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_S){
            setPositionInfo({ FontSize:"25px", Height_Box1:"60", Height_Box2:"40"  });
        }
        else if(CurrMediaType === Utils_MediaQuery.P_M_FOLD_S ||
            CurrMediaType === Utils_MediaQuery.P_M_IPHONE_S ||
            CurrMediaType === Utils_MediaQuery.P_M_ANDROID_S){
            setPositionInfo({ FontSize:"20px", Height_Box1:"60", Height_Box2:"40"  });
        }
        else if(CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_L){
            setPositionInfo({ FontSize:"30px", Height_Box1:"70", Height_Box2:"30"  });
        }
        
        else if(CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_T_GALAXY_S ||
            CurrMediaType === Utils_MediaQuery.P_T_IPAD_S ||
            CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_L ||
            CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_D_DESKTOP_S ||
            CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_L){
            setPositionInfo({ FontSize:"35px", Height_Box1:"60", Height_Box2:"40"  });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_S){
            setPositionInfo({ FontSize:"20px", Height_Box1:"95", Height_Box2:"5"  });
        }
        else if(CurrMediaType === Utils_MediaQuery.L_M_FOLD_S){
            setPositionInfo({ FontSize:"15px", Height_Box1:"90", Height_Box2:"10"  });
        }
        else if(CurrMediaType === Utils_MediaQuery.L_M_IPHONE_S ||
            CurrMediaType === Utils_MediaQuery.L_M_ANDROID_S){
            setPositionInfo({ FontSize:"20px", Height_Box1:"100", Height_Box2:"0"  });
        }
        
        else if(CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_L){
            setPositionInfo({ FontSize:"25px", Height_Box1:"95", Height_Box2:"5"  });
        }
        else if(CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_S){
            setPositionInfo({ FontSize:"25px", Height_Box1:"90", Height_Box2:"10"  });
        }
        else if(CurrMediaType === Utils_MediaQuery.L_T_GALAXY_S){
            setPositionInfo({ FontSize:"35px", Height_Box1:"90", Height_Box2:"10"  });
        }
        else if(CurrMediaType === Utils_MediaQuery.L_T_IPAD_S){
            setPositionInfo({ FontSize:"35px", Height_Box1:"95", Height_Box2:"5"  });
        }
        else if(CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_L){
            setPositionInfo({ FontSize:"35px", Height_Box1:"80", Height_Box2:"20"  });
        }
        
        else if(CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_L ||
            CurrMediaType === Utils_MediaQuery.L_D_DESKTOP_S){
            setPositionInfo({ FontSize:"40px", Height_Box1:"70", Height_Box2:"30"  });
        }

    },[props.objWindowSize])

    return (
        <ContainerBox>

            <Block
                ContainerWidth="100%"
                ContainerHeight={Utils_ui.PercentToPx(props.compHeight, positionInfo.Height_Box1)} >
                    <TextBox FontSize={positionInfo.FontSize} FontColor="#fff" FontWeight="200" TextAlign="center">
                        {props.percent}%
                    </TextBox>
                <Progress percent={props.percent}/>
            </Block>
            <Block
                ContainerWidth="100%" 
                ContainerHeight={Utils_ui.PercentToPx(props.compHeight, positionInfo.Height_Box2)}></Block>
        </ContainerBox>
    );
};

export default LoadingProgressBar;