import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Block } from "./common/StyleMasterComp";

import DataManager from '../common/data/DataManager';
import Constants_ui from './common/Constants_ui';
import Utils_ui from './common/Utils_ui';

import PageAuth from './page/PageAuth';
import PageLoading from './page/PageLoading';
import PageMain from './page/PageMain';

let AppContainerBox = styled.div`
    margin:0; padding:0;
    width: 100vw; 
    height: ${props => ((props.ContainerHeight))};
    pointer-events:none;
    position: fixed;
`;

let m_arrData = [];

function App() {
    const [strSelectPageName, setSelectPageName] = useState(Constants_ui.PAGE_START);
    const [windowSize, setWindowSize] = useState({
        Width: window.innerWidth,
        Height: window.innerHeight
    });

      // handleResize 함수를 debounce로 감싸고, 시간을 설정한다
      // 1000ms = 1sec
    const handleResize = debounce(() => {
        setWindowSize({
            Width: window.innerWidth,
            Height: window.innerHeight
        });
    }, 500);
    
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => { // cleanup 
            window.removeEventListener('resize', handleResize);
        }
    }, [handleResize]);


    
    useEffect(()=>{
        m_arrData = DataManager.GetCompanyDatas();
        let tempData = [...m_arrData];
        tempData = Utils_ui.SortJSON(tempData, "id", "asc");
        m_arrData = [...tempData];
        console.log(tempData)

        console.log("ENV : ", process.env);
    },[]);

    return (
        <AppContainerBox ContainerHeight={Utils_ui.PercentToPx(windowSize.Height,100)}>
            {
                Constants_ui.TESTMODE
                ?<Block Position="absolute" BackColor="#ffffff50" ContainerHeight="30px">testMode : {windowSize.Width},{windowSize.Height}, ver : {process.env.REACT_APP_VERSION} </Block>
                :null
            }
            
            <SelectSubPage
                strPageName={strSelectPageName}
                funcSetSelectPageName={setSelectPageName}
                objWindowSize={windowSize}
            />
        </AppContainerBox>
    );
}

function SelectSubPage(props) {
    if (props.strPageName === Constants_ui.PAGE_LOADING){
        return <PageLoading setSelectPageName={props.funcSetSelectPageName} objWindowSize={props.objWindowSize}/>;
    } else if (props.strPageName === Constants_ui.PAGE_AUTH) {
        return <PageAuth setSelectPageName={props.funcSetSelectPageName} objWindowSize={props.objWindowSize}/>;
    } else if (props.strPageName === Constants_ui.PAGE_MAIN) {
        return <PageMain setSelectPageName={props.funcSetSelectPageName} arrBoothData={m_arrData}  objWindowSize={props.objWindowSize}/>;
    }
}

export default App;