import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {ContainerBox, Block, ImgBox, ImgButton} from "../../common/StyleMasterComp"

import {TopTxt, ListContents} from './';

// import ImgBottomLogo from "../../images/img_ListBottomLogo.png";
import ImgBottomLogo from "../../images/ico_blue_character.png";
import ImgCloseList from "../../images/ico_x.png";
import Utils_MediaQuery from "../../common/Utils_MediaQuery";


let ContantBgBox = styled.div`
    position:relative;
    box-sizing:border-box;
    width: ${props => props.Width || "768px"}; 
    height: ${props => props.Height || "762px"};
    background-color: white;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    z-index: 120;
`;

let TopTxtContainer = styled.div`
    position: absolute;
    left: ${props=>props.Left || "20px"};
    top: ${props=>props.Top ||"27px"};
`;

let BottomLogoBlock = styled(Block)`
    border-top:1px solid #ddd;
`;

function BoothListContents(props){

    //let [Selected, setSelected] = useState("전체 부스");
    let Selected = "전체 부스";
    let [listContentAreaSize, setListContentAreaSize] = useState({ Width:"768px", Height:"762px" });
    let [topTxtContainerSize, setTopTxtContainerSize] = useState({ Left:"20px", Top:"27px" });
    let [blockAreaSize, setBlockAreaSize] = useState({ Height_Block1:"132px", Height_Block2:"38px", Height_Block3:"470px", Height_Block4:"121px" });
    let [bottomLogoInfo, setBottomLogoInfo] = useState({ Top:"20px", Left:"20px" })

    useEffect(() => {
        let CurrMediaType = Utils_MediaQuery.GetMediaDetailType(props.objWindowSize.Width,props.objWindowSize.Height);
        console.log("BoothListContents : ",CurrMediaType);
        if(CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_M_FOLD_S){
            setListContentAreaSize({ Width:props.objWindowSize.Width+"px", Height:props.objWindowSize.Height+"px"  });
            setTopTxtContainerSize({ Left:"14px", Top:"14px" });
            setBlockAreaSize({ Height_Block1:"100px", Height_Block2:"38px", Height_Block3:(props.objWindowSize.Height-100-38-92)+"px", Height_Block4:"92px" });
            setBottomLogoInfo({ Top:"20px", Left:"10px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.P_M_IPHONE_S){
            setListContentAreaSize({ Width:props.objWindowSize.Width+"px" , Height:props.objWindowSize.Height+"px"   });
            setTopTxtContainerSize({ Left:"14px", Top:"14px" });
            setBlockAreaSize({ Height_Block1:"90px", Height_Block2:"38px", Height_Block3:"480px", Height_Block4:"70px" });
            setBottomLogoInfo({ Top:"5px", Left:"10px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.P_M_ANDROID_S){
            setListContentAreaSize({ Width:props.objWindowSize.Width+"px" , Height:props.objWindowSize.Height+"px"  });
            setTopTxtContainerSize({ Left:"14px", Top:"14px" });
            setBlockAreaSize({ Height_Block1:"90px", Height_Block2:"38px", Height_Block3:(props.objWindowSize.Height-90-38-90)+"px", Height_Block4:"90px" });
            setBottomLogoInfo({ Top:"10px", Left:"10px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_L){
            setListContentAreaSize({ Width:props.objWindowSize.Width+"px" , Height:props.objWindowSize.Height+"px"  });
            setTopTxtContainerSize({ Left:"14px", Top:"14px" });
            setBlockAreaSize({ Height_Block1:"90px", Height_Block2:"38px", Height_Block3:(props.objWindowSize.Height-90-38-90)+"px", Height_Block4:"90px" });
            setBottomLogoInfo({ Top:"10px", Left:"10px" });
        }
                
        else if(CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_T_GALAXY_S){
            setListContentAreaSize({ Width:props.objWindowSize.Width+"px" , Height:props.objWindowSize.Height+"px"  });
            setTopTxtContainerSize({ Left:"20px", Top:"40px" });
            setBlockAreaSize({ Height_Block1:"150px", Height_Block2:"38px", Height_Block3:(props.objWindowSize.Height-150-38-167)+"px", Height_Block4:"167px" });
            setBottomLogoInfo({ Top:"50px", Left:"20px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.P_T_IPAD_S ||
            CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_L){
            setListContentAreaSize({ Width:props.objWindowSize.Width+"px" , Height:props.objWindowSize.Height+"px"  });
            setTopTxtContainerSize({ Left:"20px", Top:"40px" });
            setBlockAreaSize({ Height_Block1:"150px", Height_Block2:"38px", Height_Block3:(props.objWindowSize.Height-150-38-144)+"px", Height_Block4:"144px" });
            setBottomLogoInfo({ Top:"40px", Left:"20px" });
        }
        
        else if(CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_D_DESKTOP_S ||
            CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_L){
            setListContentAreaSize({ Width:"768px", Height:"762px" });
            setTopTxtContainerSize({ Left:"14px", Top:"14px" });
            setBlockAreaSize({ Height_Block1:"100px", Height_Block2:"38px", Height_Block3:"560px", Height_Block4:"102px" });
            setBottomLogoInfo({ Top:"20px", Left:"20px" });
        }
        
        else if(CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_S){
            setListContentAreaSize({ Width:props.objWindowSize.Width, Height:"440px"});
            setTopTxtContainerSize({ Left:"14px", Top:"14px" });
            setBlockAreaSize({ Height_Block1:"80px", Height_Block2:"38px", Height_Block3:"160px", Height_Block4:"141px" });
            setBottomLogoInfo({ Top:"17px", Left:"10px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_M_FOLD_S){
            setListContentAreaSize({ Width:props.objWindowSize.Width, Height:"440px"});
            setTopTxtContainerSize({ Left:"14px", Top:"14px" });
            setBlockAreaSize({ Height_Block1:"80px", Height_Block2:"38px", Height_Block3:"160px", Height_Block4:"141px" });
            setBottomLogoInfo({ Top:"17px", Left:"10px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_M_IPHONE_S){
            setListContentAreaSize({ Width:props.objWindowSize.Width, Height:"440px"  });
            setTopTxtContainerSize({ Left:"14px", Top:"14px" });
            setBlockAreaSize({ Height_Block1:"80px", Height_Block2:"38px", Height_Block3:"160px", Height_Block4:"141px" });
            setBottomLogoInfo({ Top:"10px", Left:"10px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.L_M_ANDROID_S){
            setListContentAreaSize({ Width:props.objWindowSize.Width, Height:"440px"  });
            setTopTxtContainerSize({ Left:"14px", Top:"14px" });
            setBlockAreaSize({ Height_Block1:"80px", Height_Block2:"38px", Height_Block3:"160px", Height_Block4:"141px" });
            setBottomLogoInfo({ Top:"17px", Left:"10px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_L){
            setListContentAreaSize({ Width:props.objWindowSize.Width, Height:"440px"  });
            setTopTxtContainerSize({ Left:"14px", Top:"14px" });
            setBlockAreaSize({ Height_Block1:"80px", Height_Block2:"38px", Height_Block3:"160px", Height_Block4:"141px" });
            setBottomLogoInfo({ Top:"17px", Left:"10px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_S) {
            setListContentAreaSize({ Width:"557px", Height:"506px"  });
            setTopTxtContainerSize({ Left:"14px", Top:"14px" });
            setBlockAreaSize({ Height_Block1:"80px", Height_Block2:"38px", Height_Block3:"320px", Height_Block4:"70px" });
            setBottomLogoInfo({ Top:"20px", Left:"10px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.L_T_GALAXY_S){
            setListContentAreaSize({ Width:"605px", Height:"550px"  });
            setTopTxtContainerSize({ Left:"14px", Top:"14px" });
            setBlockAreaSize({ Height_Block1:"80px", Height_Block2:"38px", Height_Block3:"400px", Height_Block4:"70px" });
            setBottomLogoInfo({ Top:"5px", Left:"10px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.L_T_IPAD_S ||
            CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_L){
            setListContentAreaSize({ Width:"605px", Height:"600px"  });
            setTopTxtContainerSize({ Left:"14px", Top:"14px" });
            setBlockAreaSize({ Height_Block1:"80px", Height_Block2:"38px", Height_Block3:"400px", Height_Block4:"120px" });
            setBottomLogoInfo({ Top:"30px", Left:"20px" });
        }
        
        else if(CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.L_D_DESKTOP_S ||
            CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_L){
            setListContentAreaSize({ Width:"768px", Height:"762px"  });
            setTopTxtContainerSize({ Left:"14px", Top:"14px" });
            setBlockAreaSize({ Height_Block1:"100px", Height_Block2:"38px", Height_Block3:"560px", Height_Block4:"102px" });
            setBottomLogoInfo({ Top:"20px", Left:"20px" });
        }
    },[props.objWindowSize])

    return(
        <ContainerBox>
            <ContantBgBox Width={listContentAreaSize.Width} Height={listContentAreaSize.Height}>
                {/* 닫기 버튼 */}
                <ImgButton BackImg={ImgCloseList} BtnWidth="18px" BtnHeight="18px" 
                    Position="absolute" Right="14px" Top="14px"
                    onClick={() => {
                        props.funcSetBoothListView(false);
                    }}
                />
                <Block ContainerHeight={blockAreaSize.Height_Block1}>
                    <TopTxtContainer Left={topTxtContainerSize.Left} Top={topTxtContainerSize.Top}>
                        <TopTxt objWindowSize={props.objWindowSize}/>
                    </TopTxtContainer>
                </Block>
                {/* <Block ContainerHeight={blockAreaSize.Height_Block2}>
                    <MidSelector setSelected={setSelected} Selected ={Selected}/>
                </Block> */}
                {/* 리스트 */}
                <Block ContainerHeight={blockAreaSize.Height_Block3}>
                    <ListContents arrBoothData={props.arrBoothData} 
                        Selected ={Selected}
                        funcSendTenant = {props.funcSendTenant}
                        ListHeight={blockAreaSize.Height_Block3}
                        ListWidth={listContentAreaSize.Width}
                        objWindowSize={props.objWindowSize}/>
                </Block>
                {/* 하단 로고 */}
                <BottomLogoBlock ContainerHeight={blockAreaSize.Height_Block4} ContainerWidth={listContentAreaSize.Width}>
                    <ImgBox BackImg={ImgBottomLogo} Width="60px" Height="58px" Left={bottomLogoInfo.Left} Position="absolute" Top={bottomLogoInfo.Top}/>
                </BottomLogoBlock>
            </ContantBgBox>
        </ContainerBox>
    );
}

export default BoothListContents;