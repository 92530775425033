import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Communicator from "../../common/Communicator";
import CommunicatorEvent from "../../common/event/CommunicatorEvent";
import Constants_ui from "../common/Constants_ui";
import { Block, ContainerBox, TextBox, ImgBox } from "../common/StyleMasterComp";
import Utils_MediaQuery from "../common/Utils_MediaQuery";
import Utils_ui from "../common/Utils_ui";

import LoadingProgressBar from "../components/pageLoading/LoadingProgressBar";

// KDG
// import ImgLogo from "../images/loading_img_logo.png";
import ImgLogo from "../images/SMG_logo.png";

let BgBox = styled.div`
    width:100vw;
    height:${props => props.Height};
    background: linear-gradient(#000000 0%, #00245C 53.12%, #005BAD 100%);
    position: absolute;
    pointer-events:all;
`;

let LogoImg = styled(ImgBox)`
    position: absolute;
    background-image:url(${props => props.URL});
    left: calc(50% - ((${props => props.Width} / 2)));
    bottom:3vh;
    width: ${props => props.Width || "115px"};
    height: ${props => props.Height ||"113px"};
`;

function PageLoading(props) {
    //console.log("로딩페이지", props);
    let [NumPercent, setNumPercent] = useState(0);

    let [isLoadReady, setIsLoadReady] = useState(false);
    let [isTimeReady, setIsTimeReady] = useState(false);

    let onChangeLoadCount = (_nVars) => {
        let nVars = parseInt(_nVars);

        (nVars > 100)
            ? nVars = 100
            : nVars = parseInt(_nVars);
        setNumPercent(nVars);
    }

    let onWorldLoadComp = () => {
        // console.log('onWorldLoadComp');
        setIsLoadReady(true);
    }

    useEffect(() => {
        let pageMoveTimer = setTimeout(() => {
            // console.log('setTimeout');
            setIsTimeReady(true);
        }, Constants_ui.FIRSTLOADING_TIME);
        return() => {
            clearTimeout(pageMoveTimer)
        }
    }, []);

    useEffect(() => {
        Communicator.reciveEvent(CommunicatorEvent.WORLD_LOADING, onChangeLoadCount);
        Communicator.reciveEvent(
            CommunicatorEvent.WORLD_LOAD_COMPLETE,
            onWorldLoadComp
            );
    }, []);

    useEffect(() => {
        if (isLoadReady && isTimeReady) props.setSelectPageName(Constants_ui.PAGE_AUTH);
    }, [isLoadReady,isTimeReady,props]);

    let [imgSize, setImgSize] = useState({ Width:"115px", Height:"113px" });
    let [txt1Size, setTxt1Size] = useState({ FontSize:"25px", MarginTop:"0px" });
    let [txt2Size, setTxt2Size] = useState({ FontSize:"25px", MarginTop:"0px" });

    useEffect(() => {

        let CurrMediaType = Utils_MediaQuery.GetMediaDetailType(props.objWindowSize.Width,props.objWindowSize.Height);
        console.log("PageLoading : ",CurrMediaType);
        if(CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_M_FOLD_S){
            setImgSize({ Width:"115px", Height:"113px" });
            setTxt1Size({ FontSize:"11px", MarginTop:"-10px" });
            setTxt2Size({ FontSize:"25px", MarginTop:"-5px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.P_M_ANDROID_S ||
            CurrMediaType === Utils_MediaQuery.P_M_IPHONE_S){
            setImgSize({ Width:"115px", Height:"113px" });
            setTxt1Size({ FontSize:"14px", MarginTop:"0px" });
            setTxt2Size({ FontSize:"35px", MarginTop:"-5px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_L){
            setImgSize({ Width:"115px", Height:"113px" });
            setTxt1Size({ FontSize:"14px", MarginTop:"-10px" });
            setTxt2Size({ FontSize:"35px", MarginTop:"-5px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_S){
            setImgSize({ Width:"130px", Height:"128px" });
            setTxt1Size({ FontSize:"15px", MarginTop:"-15px" });
            setTxt2Size({ FontSize:"40px", MarginTop:"-5px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_L ||
            CurrMediaType === Utils_MediaQuery.P_T_IPAD_S ||
            CurrMediaType === Utils_MediaQuery.P_T_GALAXY_S){
            setImgSize({ Width:"130px", Height:"128px" });
            setTxt1Size({ FontSize:"25px", MarginTop:"0px" });
            setTxt2Size({ FontSize:"50px", MarginTop:"-5px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_S){
            setImgSize({ Width:"130px", Height:"128px" });
            setTxt1Size({ FontSize:"25px", MarginTop:"-10px" });
            setTxt2Size({ FontSize:"50px", MarginTop:"-5px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.P_D_DESKTOP_S){
            setImgSize({ Width:"150px", Height:"147px" });
            setTxt1Size({ FontSize:"30px", MarginTop:"0px" });
            setTxt2Size({ FontSize:"60px", MarginTop:"0px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_L){
            setImgSize({ Width:"130px", Height:"128px" });
            setTxt1Size({ FontSize:"28px", MarginTop:"-20px" });
            setTxt2Size({ FontSize:"50px", MarginTop:"0px" });   
        }

        else if(CurrMediaType === Utils_MediaQuery.L_M_FOLD_S ||
            CurrMediaType === Utils_MediaQuery.L_M_IPHONE_S ||
            CurrMediaType === Utils_MediaQuery.L_M_ANDROID_S ||
            CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_S){
            setImgSize({ Width:"70px", Height:"69px" });
            setTxt1Size({ FontSize:"10px", MarginTop:"10px" });
            setTxt2Size({ FontSize:"18px", MarginTop:"-2px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_L){
            setImgSize({ Width:"80px", Height:"78px" });
            setTxt1Size({ FontSize:"15px", MarginTop:"5px" });
            setTxt2Size({ FontSize:"20px", MarginTop:"0px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_S){
            setImgSize({ Width:"115px", Height:"113px" });
            setTxt1Size({ FontSize:"20px", MarginTop:"10px" });
            setTxt2Size({ FontSize:"40px", MarginTop:"-5px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.L_T_GALAXY_S ||
            CurrMediaType === Utils_MediaQuery.L_T_IPAD_S){
            setImgSize({ Width:"115px", Height:"113px" });
            setTxt1Size({ FontSize:"20px", MarginTop:"5px" });
            setTxt2Size({ FontSize:"35px", MarginTop:"-5px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_L){
            setImgSize({ Width:"115px", Height:"113px" });
            setTxt1Size({ FontSize:"20px", MarginTop:"5px" });
            setTxt2Size({ FontSize:"40px", MarginTop:"-5px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_S){
            setImgSize({ Width:"115px", Height:"113px" });
            setTxt1Size({ FontSize:"25px", MarginTop:"0px" });
            setTxt2Size({ FontSize:"50px", MarginTop:"-5px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.L_D_DESKTOP_S){
            setImgSize({ Width:"115px", Height:"113px" });
            setTxt1Size({ FontSize:"20px", MarginTop:"5px" });
            setTxt2Size({ FontSize:"50px", MarginTop:"-5px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_L){
            setImgSize({ Width:"115px", Height:"113px" });
            setTxt1Size({ FontSize:"25px", MarginTop:"5px" });
            setTxt2Size({ FontSize:"50px", MarginTop:"-5px" });
        }

    },[props.objWindowSize])

    return (
        <ContainerBox>
            <BgBox Height={Utils_ui.PercentToPx(props.objWindowSize.Height, 100)}/>
            <Block ContainerWidth="100vw" ContainerHeight={Utils_ui.PercentToPx(props.objWindowSize.Height,22)} />
            <Block ContainerHeight={Utils_ui.PercentToPx(props.objWindowSize.Height,22)}  >
                <LogoImg URL={ImgLogo} Width={imgSize.Width} Height={imgSize.Height}/>
            </Block>
            <Block ContainerHeight={Utils_ui.PercentToPx(props.objWindowSize.Height,12)}  >
                <LoadingProgressBar percent={NumPercent} objWindowSize={props.objWindowSize} compHeight={Utils_ui.PercentToPx(props.objWindowSize.Height,12,false)}></LoadingProgressBar>
            </Block>
            <Block ContainerHeight={Utils_ui.PercentToPx(props.objWindowSize.Height,22)}  >
                <TextBox FontSize={txt1Size.FontSize} FontColor="#fff" FontWeight="400" MarginTop={txt1Size.MarginTop} TextAlign="center"> 
                버츄얼 새만금</TextBox>
                <TextBox FontSize={txt2Size.FontSize} FontColor="#fff" FontWeight="400" MarginTop={txt2Size.MarginTop} MarginBottom="20px" TextAlign="center">
                웹 포털</TextBox>
                {
                    Constants_ui.TESTMODE
                    ?<TextBox FontSize="12px" FontColor="#fff" FontWeight="400" MarginTop="0px" TextAlign="center"> 
                    isTimeReady : {String(isTimeReady)}, isLoadReady: {String(isLoadReady)}</TextBox>
                    : null
                }
                
            </Block>
            <Block ContainerHeight={Utils_ui.PercentToPx(props.objWindowSize.Height,22)}  />
        </ContainerBox>
    );
}

export default PageLoading;

