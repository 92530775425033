import React from 'react';
import styled from 'styled-components';


let Tabs = styled.ul`
    margin:0;
    padding:0;
`;

let InactiveTab = styled.li`
    pointer-events:all;
    font-family: 'Spoqa HanSansNeo'; font-size: 18px; color: #fff;  font-weight: 600;
    line-height: 38px;
    width:77px; 
    height:38px;
    display: block; 
    float:left;
    border-radius: 10px 10px 0 0;
    cursor:pointer;
    background: rgba(0, 0, 0, 0.26);
    text-align: center;
    opacity:0.5;
`;

let ActiveTab = styled.li`
    pointer-events:all;
    background: linear-gradient(
        to bottom, 
        rgba(0, 0, 0, 0.75) 10%,
        rgba(20, 20, 20, 0.65) 25%,
        rgba(20, 20, 20, 0.55) 50%,
        rgba(20, 20, 20, 0.5) 75%,
        rgba(0, 0, 0, 0.46) 100%
        );
    font-family: 'Spoqa HanSansNeo'; font-size: 18px; color: #fff;  font-weight: 600;
    line-height: 38px;
    width:77px;
    height:38px;
    display: block;
    float:left;
    border-radius: 10px 10px 0 0;
    text-align: center;
    cursor:pointer;
`;

function ChatTab(props){
    return(
        <Tabs>
            {
                (props.iSelectMenuIndex===0)
                ?   <div>
                        <ActiveTab style={{ marginRight: "3px" }} onClick={() => { props.funcSelectMenuIndex(0) }}>채널</ActiveTab>
                        <InactiveTab onClick={() => { props.funcSelectMenuIndex(1) }}>전체</InactiveTab>
                    </div>
                :   <div>
                        <InactiveTab style={{ marginRight: "3px" }} onClick={() => { props.funcSelectMenuIndex(0) }}>채널</InactiveTab>
                        <ActiveTab onClick={() => { props.funcSelectMenuIndex(1) }}>전체</ActiveTab>
                    </div>
            }
        </Tabs>
    )
}

export default ChatTab;