import Constants from "./Constants";

export default class Utils
{
    'use strict';
	//constructor(){};
	
    /**
    *===============================================================================
    * Distance()         : 두 객체간 거리 계산
    * @param _obj1       : 2D 좌표계를 가진 객체
    * @param _obj2        : 2D 좌표계를 가진 객체
    *===============================================================================
    */
    static Distance(_obj1, _obj2)
    {
        let nDistance = Math.sqrt(Math.pow((Number(_obj1.x) - Number(_obj2.x)),2) + Math.pow((Number(_obj1.y) - Number(_obj2.y)),2));
        return nDistance;
    }
    


    /**
    *===============================================================================
    * DegreeToRadian()   : Degree 값을 Radian 값으로 변환
    * @param _nDegree    : Degree 값
    * @return Number     : Radian 값 
    *===============================================================================
    */
    static DegreeToRadian(_nDegree)
    {
        return _nDegree / 180.0 * Math.PI;   
    }


    /**
    *===============================================================================
    * RadianToDegree()   : Radian 값을 Degree 값으로 변환
    * @param _nRadian    : Radian 값
    * @return Number     : Degree 값
    *===============================================================================
    */
    static RadianToDegree(_nRadian)
    {
         return _nRadian / Math.PI * 180.0;  
    }


    /**
    *===============================================================================
    * Random()     : 두 숫자 사이 랜덤값 리턴 함수
    * @param _iMin : 최소 범위 숫자 int
    * @param _iMax : 최대 범위 숫자 int
    * @return int     : 추출된 int 값 리턴
    *===============================================================================
    */
    static  Random( _iMin , _iMax)
    {
        return ((Math.random() * (_iMax + 1 - _iMin)) + _iMin).toFixed(); 
    }

    /**
     * 
     * @param {*} _iMin  최소값. 정수
     * @param {*} _iMax  최대값. 정수
     * @returns 
     */
    static  RandomInt( _iMin , _iMax)
    {
        return Math.floor((Math.random() * (_iMax+1 - _iMin)) + _iMin);
    }

    /**
    *===============================================================================
    * HexToRgb()     : Hex Color RGB 변환
    * @param _strHex : Hex Color
    *===============================================================================
    */
    static HexToRgb(_strHex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(_strHex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
    }

    /**
     * 간단 버전의 배열 셔플. 셔플 결과는 고르지 않을 수 있다.
     */
    static arrayShuffle(arr) { return arr.sort( ()=>(Math.random()-.5) ); }

    // deep link 를 입력했을 때 앱의 install 여부에 따라 
    // 설치 되지 않았을 경우 스토어나 다운로드 페이지로,
    // 설치 되었을 경우 deep link를 실행시켜주도록 해주는 함수
    static goDeepLink(deeplink) 
    {
        const downloadURLs = Constants.downloadURLs;
        var div = document.createElement('div');
        if ( Constants.DEBUG_MODE )
            document.body.appendChild(div);
        div.innerHTML = `( Deep Link ) (${process.env.REACT_APP_VERSION}) <br>`;
        div.style = "position: absolute;top:0;"
        const appStoreUrl   = downloadURLs.appStoreUrl;
        const playStoreUrl  = downloadURLs.playStoreUrl;
        const pcDownURL     = downloadURLs.pcDownURL;

        let OS = "";
        let storeVisited = false;

        // const deeplink = 'yourapp://';

        const userAgent = window.navigator.userAgent.toLowerCase();
        let storeURL = "";
        if (/iphone|ipad|ipod/.test(userAgent)) // iOS
        {
            storeURL = appStoreUrl;
            OS = "iOS";
        }
        else if (/android/.test(userAgent)) // Android
        {
            storeURL = playStoreUrl;
            OS = "And";
        }
        else if ( navigator.appVersion.indexOf('Win') != -1 ) // Windows
            storeURL = pcDownURL;
        else if (navigator.appVersion.indexOf('Mac') != -1) // Mac
            storeURL = pcDownURL;
        
        window.onblur = function () 
        { 
            changed = true; 
            div.innerHTML += "on blur changed?: " + changed + ", hidden : " + document.hidden + "<br>";
            console.log("onblur!!!!!", changed)
        };
        window.onfocus = function() 
        { 
            changed = false; 
            div.innerHTML += "on focus changed?: " + changed + ", hidden : " + document.hidden + "<br>";
            console.log("onfocus!!!!!", changed)
            if ( OS==="iOS" && !storeVisited ) moveToStore();
        }

        // 일단 deeplink 로 이동 시도
        try
        {
            window.location.href = deeplink;
        }
        catch(e)
        {
            div.innerHTML += "catch 들어옴";
        }
        var changed = false;
        function moveToStore()
        {
            div.innerHTML += '[MTS] changed? ' + changed + ", hidden : " + document.hidden + "<br>";
            // alert('changed? ' + changed);
            if ( (OS==="iOS" && !changed) || (OS==="And" && !document.hidden) || ( OS!=="iOS" && OS!=="And" && !changed) )
            {
                if ( window.confirm('다운로드 링크로 이동하시겠습니까? (이동되지 않을 경우 상단 주소창에서 팝업 차단을 해제하고 다시 객체를 선택해 주세요. 인게이지를 새로 설치 했을 경우 앱을 실행해 로그인 하고 다시 시도해 주세요.)') )
                {
                    storeVisited = true;
                    // window.location.href = storeURL;
                    window.open(storeURL, '_blank');
                }
            }
        }
        setTimeout(moveToStore, 2000);
    }

    static checkMobileByWidth()
    {
        console.log("[I] checkMobileByWidth ", window.screen.width, window.screen.width<1024);
        if ( window.screen.width<1024 )
            return true;
        else
            return false;
    }
};

