/**
 @Class          : Avatar
 @Maker          : 윤휘원 (박정훈 팀장님 작업 상속)
 @Date           : 2022-01-05
 @Debug DATE     : 
 @Debug Contents : -
 @Version        : 1.0
 @Description    : 아바타 클래스
 */

import * as BABYLON from 'babylonjs';
import LoadingProgressManager from './LoadingProgressManager';
import * as GUI from 'babylonjs-gui';
import Constants from '../common/Constants';
import EventDispatcher from '../common/event/EventDispatcher';
import gsap from "gsap";
import Utils from '../common/Utils';

export default class Avatar extends EventDispatcher {
    'use strict';

    static LOAD_COMPLETE = "Load_Complete";
    static MAKE_COUNT = 0;
    static AVATAR_MESH = null;

    /**
     *===============================================================================
     * constructor()      :
     * @param _strMeshURL :  
     *===============================================================================
     */
    constructor(_strMeshURL = null, _isHost = true, name=null) {
        super();

        Avatar.MAKE_COUNT += 1;
        this.strUniqueID = "_" + String(Avatar.MAKE_COUNT);

        this.strMeshURL = _strMeshURL;
        this.meshAvatar = null;
        this.meshShadow = null;
        this.meshBody = null;
        this.meshHead = null;
        this.meshNavi = null;
        this.strName = name;
        this.planeText = null;
        this.isGo = false;
        this.isHost = _isHost;
        this.nNamePosition = 1.6;
        this.iSwingCountFrame = 0;
        this.iSwingCycle = Utils.Random(30, 50);
        this.nSwingSpeed = 2;
        this.nFloatingAmplitude = .2;
        this.sweatParticle1 = null;
        this.sweatParticle2 = null;
        this.sweat = null;
        this.walkAnimation = null;
        this.isIdleAnim = false;
        this.agentIndex = null;

        this.statusData = {
            position: {
                x: 0,
                y: 0,
                z: 0
            },
            rotation: {
                x: 0,
                y: 0,
                z: 0
            },
            name: "younghee"
        }

        this.camera = null;
        this.isBoothMode = false;
        this.init();
    }

    /**
     *===============================================================================
     * dispose()             :
     *===============================================================================*/
    dispose() {
        //console.log(":::::: Avatar dipose  ::::::");
        this.iSwingCountFrame = 0;
        Constants.SCENE.onBeforeAnimationsObservable.removeCallback(this.onMoveAvatar.bind(this));
        Constants.SCENE.onBeforeAnimationsObservable.removeCallback(this.onFlowNameteg.bind(this));
        Constants.SCENE.onBeforeAnimationsObservable.removeCallback(this.floating.bind(this));

        if (this.textblock) this.textblock.dispose();
        if (this.planeText) this.planeText.dispose();

        this.strMeshURL = null;
        if ( this.meshShadow )
        {
            this.meshShadow.material.dispose();
            this.meshShadow.dispose();
        }
        this.meshAvatar.dispose();
    }

    /**
     *===============================================================================
     * init()             :
     *===============================================================================*/
    async init() {
        this.setNavimesh();
        await this.loadMesh();

        // KDG
        // this.setFirstPersonMove();
    }

    /**
     *===============================================================================
     * loadMesh()             : 아바타 로드
     *===============================================================================*/
    async loadMesh() {
        let loadData = 0;

        const onMeshLoadProgress = (_data) => {
            let dataUnit = _data.loaded - loadData;
            loadData = _data.loaded;
            LoadingProgressManager.AddData(dataUnit);
        }
        // 아바타 파일 로드
        let avatarLoad = null;
        if ( Avatar.AVATAR_MESH && !this.strMeshURL )
        {
            console.log("---=== 기존 아바타 사용");
            this.meshAvatar = Avatar.AVATAR_MESH.clone(this.strName);
            this.setShadow(true);
        }
        else
        {
            console.log("---=== 아바타 새로 로드");
            avatarLoad = await BABYLON.SceneLoader.ImportMeshAsync("", Constants.ASSETS_URL, this.strMeshURL, Constants.SCENE, onMeshLoadProgress);
            this.meshAvatar = avatarLoad.meshes[0];
            if ( !Avatar.AVATAR_MESH )
                Avatar.AVATAR_MESH = this.meshAvatar;
            this.meshAvatar.name = "player" + this.strUniqueID;
            this.meshAvatar.position = new BABYLON.Vector3(Constants.STARTING_POINT.x, Constants.STARTING_POINT.y, Constants.STARTING_POINT.z);
            this.setShadow();

        }
        // KDG
        // let scale = .7;
        let scale = 1.0;
        this.meshAvatar.scaling = new BABYLON.Vector3(scale, scale, scale);
        this.meshAvatar.animations.forEach( i=> { i.speedRatio=.9; } );
        // console.log("avatar animation : ", this.mesh, Constants.SCENE.animationGroups);
        // console.log("avatar animation : ", this.meshAvatar, this.meshAvatar.animations);

        // this.meshBody = Constants.SCENE.getMeshByName("Yirumae_Idle");
        // this.meshBody = Constants.SCENE.getMeshByName("Null.2 (Copy)");
        // this.meshBody.material.name = "Mat_Yirumae";

        // this.meshBody = this.meshAvatar.clone("MeshBody");
        // this.meshBody.material = new BABYLON.StandardMaterial("matMeshBody", Constants.SCENE);
        // console.log("메쉬 바디 : ", this.meshBody);
        // this.meshBody.material._roughness = 1
        // this.meshBody.name = this.meshBody.name + this.strUniqueID;

        if (Constants.SCENE.animationGroups.length > 0) {
            for (let i = 0; i < Constants.SCENE.animationGroups.length; i++) {
                if (Constants.SCENE.animationGroups[i].name === "Armature|Take 001|BaseLayer") {
                    this.walkAnimation = Constants.SCENE.animationGroups[i];
                    this.walkAnimation.name = "PlayerAnimation"
                    this.walkAnimation.speedRatio = 0.3;
                    this.walkAnimation.start(true, 0.3, this.walkAnimation.from, this.walkAnimation.to/2)
                }
            }
        }

        let loadData2 = 0;
        const onMeshLoadProgress2 = (_data) => {
            let dataUnit = _data.loaded - loadData2;
            loadData2 = _data.loaded;
            LoadingProgressManager.AddData(dataUnit);
        }

        // 아바타 이동시 땀 파일 로드
        let avatarSweatLoad = await BABYLON.SceneLoader.ImportMeshAsync("", Constants.ASSETS_URL, Constants.AVATAR_SWEAT_FILE_NAME, Constants.SCENE, onMeshLoadProgress2);
        this.sweat = avatarSweatLoad.meshes[0];
        this.sweat.parent = this.meshAvatar;
        this.sweat.setEnabled(false);
    }

    /**
     *===============================================================================
     * setShadow()            : 그림자 설정
     *===============================================================================*/
    // KDG - player와 봇의 그림자를 사용하면 느려져 성능이 대폭 저하되어 일단 뺌
    setShadow( flagIsBot=false ) {
        // let mat = new BABYLON.StandardMaterial("mat_shadow", Constants.SCENE);
        // this.meshShadow = new BABYLON.Mesh.CreatePlane("avatar_shadow", 2, Constants.SCENE);
        // this.meshShadow.material = mat;
        // mat.opacityTexture = new BABYLON.Texture(Constants.AVATAR_SHADOW_IMAGE_URL, Constants.SCENE);
        // mat.opacityTexture.hasAlpha = true;
        // mat.opacityTexture.level = 2;
        // mat.alpha = .3;
        // mat.diffuseColor = new BABYLON.Color3(0, 0, 0);

        // // const p = this.meshAvatar.position;
        // // this.meshShadow.position = new BABYLON.Vector3(p.x, 0.1, p.z);
        // this.meshShadow.position.y = 0.3;
        // this.meshShadow.rotation = new BABYLON.Vector3(BABYLON.Tools.ToRadians(90), BABYLON.Tools.ToRadians(180), 0);
        // this.meshShadow.scaling = new BABYLON.Vector3(.7, .7, .7);

        // this.meshShadow.visibility = .4;
        // this.meshShadow.parent = this.meshAvatar;;

        Constants.SCENE.onBeforeAnimationsObservable.add(this.floating.bind(this));
        if (this.isHost) Constants.SCENE.onBeforeAnimationsObservable.add(this.onMoveAvatar.bind(this));

        this.dispatchEvent({
            type: Avatar.LOAD_COMPLETE,
            data: null
        })
    }

    /**
     *===============================================================================
     * setNavimesh()            : navimesh용 객체 생성
     *===============================================================================*/
    setNavimesh() {
        // KDG 아바타 발밑 네모
        this.meshNavi = BABYLON.MeshBuilder.CreateBox("NavimeshAgent", {
            size: .01,
            height: .01
        }, this.scene);
        this.meshNavi.position.y = 0.2;
        // KDG
        // this.meshNavi.isVisible = false;
        this.meshNavi.isVisible = true;
        this.meshNavi.visibility = .3;
    }

    /**
     *===============================================================================
     * makeNameText()          : 아바타 네임택 설정
     *===============================================================================*/
    makeNameText() {
        if (this.strName === undefined) this.strName = "Nickname"

        const planeText = this.meshAvatar.getChildren(null, false).find(i=>i.name.indexOf('NameBoard')>=0);
        console.log("이름 세팅 ", planeText, this.meshAvatar.getChildren(null, false));
        if ( planeText )
        {
            planeText.parent = null;
            planeText.dispose();
        }

        this.planeText = BABYLON.Mesh.CreatePlane("NameBoard", 3, Constants.SCENE, false);
        this.planeText.isPickable = false;
        this.planeText.material = new BABYLON.StandardMaterial("NameBoard_met", Constants.SCENE);
        this.planeText.scaling.scaleInPlace(1.5);
        //this.planeText.material.transparencyMode = 3;

        // if (this.meshAvatar) {
        //     this.planeText.position.x = this.meshAvatar.position.x;
        //     this.planeText.position.z = this.meshAvatar.position.z;
        this.planeText.position.y = this.meshAvatar.position.y + this.nNamePosition; //2.5;
        // }
        this.planeText.parent = this.meshAvatar;

        this.planeText.billboardMode = BABYLON.AbstractMesh.BILLBOARDMODE_ALL;

        var advancedTexture = GUI.AdvancedDynamicTexture.CreateForMesh(this.planeText, 512, 512, true, true);
        advancedTexture.isPickable = false;
        //advancedTexture.transparencyMode = 3;

        var panel = new GUI.StackPanel();
        advancedTexture.addControl(panel);

        this.textblock = new GUI.TextBlock();
        this.textblock.height = "1000px";
        this.textblock.color = "#FFFFFF";
        this.textblock.fontSize = 30;
        this.textblock.fontFamily = 'Nanum Gothic';
        this.textblock.fontStyle = "Bold";
        this.textblock.disabledColor = "#555555";
        this.textblock.text = this.strName;
        this.textblock.drawOutline = true;
        this.textblock.outlineColor = "#999999";
        this.textblock.outlineWidth = 5;

        console.log("NameText : ", this.textblock.text);

        panel.addControl(this.textblock);

        Constants.SCENE.onBeforeAnimationsObservable.add(this.onFlowNameteg.bind(this));
    }

    /**
     *===============================================================================
     * get status()  : status getter
     * @return       :   status 객체
     *===============================================================================*/
    get status() {
        return this.statusData;
    }

    /**
     *===============================================================================
     * set name()          : 회원 닉네임 setter
     * @param _strName     : 회원 닉네임 스트링
     *===============================================================================*/
    set name(_strName) {
        this.strName = _strName;
        this.statusData.name = _strName;

        if (_strName.indexOf("[GM]") !== -1) {
            this.meshAvatar.scaling = new BABYLON.Vector3(1.5, 1.5, 1.5);
            this.meshShadow.scaling = new BABYLON.Vector3(1.5, 1.5, 1.5);
            this.nNamePosition = 3;
        }
        this.makeNameText();
    }

    /**
     *===============================================================================
     * get name()        : 회원 닉네임 getter
     * @return           :   회원 닉네임 스트링
     *===============================================================================*/
    get name() {
        return this.statusData.name;
    }

    /**
     *===============================================================================
     * set position()         : 아바타 포지션 setter
     * @param _vecPosition    : 아바타 포지션 BABYLON.Vector3
     *===============================================================================*/
    set position(_vecPosition) {
        this.meshAvatar.position = _vecPosition;
        if ( this.meshShadow )
        {
            this.meshShadow.position.x = _vecPosition.x;
            this.meshShadow.position.z = _vecPosition.z;
        }

        //this.meshAvatar.position.y = _vecPosition.y;
    }

    /**
     *===============================================================================
     * get position()    : 아바타 포지션 getter
     * @return           : 아바타 포지션 BABYLON.Vector3
     *===============================================================================*/
    get position() {

        return this.meshAvatar.position;
    }

    /**
     *===============================================================================
     * set rotation()         : 아바타 로테이션 setter
     * @param _vecRotation    : 아바타 로테이션 BABYLON.Vector3
     *===============================================================================*/
    set rotation(_vecRotation) {
        this.meshAvatar.rotation = _vecRotation;
    }

    /**
     *===============================================================================
     * get rotation()    : 아바타 로테이션 getter
     * @return           : 아바타 로테이션 BABYLON.Vector3
     *===============================================================================*/
    get rotation() {

        return this.meshAvatar.rotation;
    }

    /**
     *===============================================================================
     * set mesh()         : 아바타 포지션 setter
     * @param _vecPosition    : 아바타 포지션 BABYLON.Vector3
     *===============================================================================*/
    get mesh() {
        return this.meshAvatar;
    }

    /**
     *===============================================================================
     * get position()    : nevimesh용 객체 getter
     * @return           : nevimesh용 mesh 객체
     *===============================================================================*/
    get meshNevi() {
        return this.meshNavi;
    }

    /**
     *===============================================================================
     * onMoveAvatar()          : 아바타 이동 -- 포지션 및 로테이션 상태 업데이트
     *===============================================================================*/
    onMoveAvatar() {
        this.meshAvatar.position.x = this.meshNavi.position.x;
        this.meshAvatar.position.z = this.meshNavi.position.z;
        this.meshAvatar.rotation.y = this.meshNavi.rotation.y;
        // this.meshShadow.position.y = this.meshNavi.position.y;

        // 멀티플레이 전송용
        this.statusData.position.x = this.meshAvatar.position.x;
        this.statusData.position.z = this.meshAvatar.position.z;
        this.statusData.rotation.x = this.meshAvatar.rotation.x;
        this.statusData.rotation.y = this.meshAvatar.rotation.y;
        this.statusData.rotation.z = this.meshAvatar.rotation.z;

        if ( this.meshShadow )
        {
        // this.statusData.position.x = this.meshShadow.position.x = this.meshAvatar.position.x;
            this.meshShadow.position.y = this.meshNavi.position.y;
        // this.statusData.position.z = this.meshShadow.position.z = this.meshAvatar.position.z;
            this.meshShadow.rotation = new BABYLON.Vector3(BABYLON.Tools.ToRadians(90), this.meshAvatar.rotation.y, 0);

        }

    }

    /**
     *===============================================================================
     * onFlowNameteg()          : 네임텍 Flowing 이벤트
     *===============================================================================*/
    onFlowNameteg() {
        // this.planeText.position.x = this.meshAvatar.position.x;
        // this.planeText.position.z = this.meshAvatar.position.z;
        // this.planeText.position.y = this.meshAvatar.position.y + this.nNamePosition; //2.5;
    }

    /**
     *===============================================================================
     * floating()    :  플로팅 애니메이션
     *===============================================================================*/
    floating() {
        this.iSwingCountFrame++;
        let degree = Math.sin(this.iSwingCountFrame / (this.iSwingCycle) * this.nSwingSpeed) * this.nFloatingAmplitude + 0.75;
        if (this.meshAvatar) this.meshAvatar.position.y = degree;
    }

    /**
     *===============================================================================
     * onMoveStart()    :  캐릭터 움직임 시작 -- 캐릭터 이동 모션
     *===============================================================================*/
    onMoveStart() {
        if (this.isGo) return;

        this.sweat.setEnabled(true);
        if ( this.walkAnimation )
            this.walkAnimation.speedRatio = 1;

        this.isGo = true;
        this.meshAvatar.rotation = new BABYLON.Vector3(0, this.meshNavi.rotation.y, BABYLON.Tools.ToRadians(0));

        gsap.to(this.meshAvatar.rotation, {
            duration: .5,
            x: BABYLON.Tools.ToRadians(20),
            ease: "power1.outout"
        });
    }

    /**
     *===============================================================================
     * onMoveStop()    :  캐릭터 멈춤 -- 캐릭터 멈춤 모션
     *===============================================================================*/
    onMoveStop() {
        this.isGo = false;
        gsap.to(this.meshAvatar.rotation, {
            duration: 1,
            x: BABYLON.Tools.ToRadians(0),
            ease: "power1.outout"
        });

        if ( this.sweat )
        {
            this.sweat.setEnabled(false);
            if ( this.walkAnimation )
                this.walkAnimation.speedRatio = 0.3;

        }
    }

    // KDG
    setFirstPersonMove()
    {
        // console.log("physics ok? : ", Constants.SCENE.getPhysicsEngine().isSupported());
        // var physicsPlugin = new BABYLON.CannonJSPlugin();
        // var physicsPlugin = new BABYLON.OimoJSPlugin();
        // Constants.SCENE.enablePhysics(new BABYLON.Vector3(0, -9,8, 0), physicsPlugin);

        this.meshAvatar.physicsImpostor = new BABYLON.PhysicsImpostor(this.meshAvatar, BABYLON.PhysicsImpostor.BoxImpostor, { mass: 1, restitution: 0.0, friction: 0.1 }, Constants.SCENE);
        var moveForward = false;
        var moveBackward = false;
        var moveRight = false;
        var moveLeft = false;

        var onKeyDown = function (event)
        {
            switch (event.keyCode)
            {
                case 38: // up
                case 87: // w
                    moveForward = true;
                    break;

                case 37: // left
                case 65: // a
                    moveLeft = true; break;

                case 40: // down
                case 83: // s
                    moveBackward = true;
                    break;

                case 39: // right
                case 68: // d
                    moveRight = true;
                    break;

                case 32: // space
                    break;
            }
        };

        var onKeyUp = function (event)
        {
            switch (event.keyCode)
            {
                case 38: // up
                case 87: // w
                    moveForward = false;
                    break;

                case 37: // left
                case 65: // a
                    moveLeft = false;
                    break;

                case 40: // down
                case 83: // a
                    moveBackward = false;
                    break;

                case 39: // right
                case 68: // d
                    moveRight = false;
                    break;
            }
        };

        document.addEventListener('keydown', onKeyDown, false);
        document.addEventListener('keyup', onKeyUp, false);


        Constants.SCENE.registerBeforeRender(function ()
        {
            //Your code here
            //Step
            //let stats = document.getElementById("stats");
            //stats.innerHTML = "";             

            // camera.position.x = hero.position.x;
            // camera.position.y = hero.position.y + 1.0;
            // camera.position.z = hero.position.z;
            // pointer.position = camera.getTarget();

            // var forward = camera.getTarget().subtract(camera.position).normalize();
            var forward = new BABYLON.Vector3(0, 0, 1);
            var right = new BABYLON.Vector3(1, 0, 0);
            // right.y = 0;

            var SPEED = 20;
            let f_speed = 0;
            var s_speed = 0;
            var u_speed = 0;

            if (moveForward)
            {
                f_speed = SPEED;
            }
            if (moveBackward)
            {
                f_speed = -SPEED;
            }

            if (moveRight)
            {
                s_speed = SPEED;
            }

            if (moveLeft)
            {
                s_speed = -SPEED;
            }

            var move = (forward.scale(f_speed)).subtract((right.scale(s_speed))).subtract(new BABYLON.Vector3(0, 1, 0).scale(u_speed));

            this.meshAvatar.physicsImpostor.physicsBody.velocity.x = move.x;
            this.meshAvatar.physicsImpostor.physicsBody.velocity.z = move.z;
            this.meshAvatar.physicsImpostor.physicsBody.velocity.y = move.y;

        });
    }
}