import Constants from "../common/Constants";
import * as BABYLON from 'babylonjs';
import {Client} from "colyseus.js";
import Avatar from "./Avatar";
import Communicator from "../common/Communicator";
import CommunicatorEvent from "../common/event/CommunicatorEvent";
import DataManager from "../common/data/DataManager";
import Utils from "../common/Utils";
import NavimeshManager from "./NavimeshManager";

/**
 * 특정 인터벌마다 맵 내의 특정 위치 사이를 이동하는 봇을 운영한다.
 * 봇의 움직임은 setInterval을 이용하고 있다.
 */
export default class BotManager {
    'use strict';

    constructor()
    {
        // super(_strURL);

        this.bots = {}; // Avatar 객체가 들어감. key는 봇의 이름
        this.avatar = Constants.AVATAR;
        this.scene = Constants.SCENE;
        this.botMoveInterval = null;

        this.init();
    }

    /**
    *===============================================================================
    * init()      :
    *===============================================================================
    */
    init(){
        // 봇은 처음에 이름이 설정된 개수만큼 생긴다.
        const bots = Constants.BOT_NAMES;

        bots.forEach( (name) =>{setTimeout(()=>{this.makeABot(name)}, 200)} );
        this.botMoveInterval = setInterval( this.moveBot.bind(this), Constants.BOT_MOVE_INTERVAL*1000 );
        // this.sendPosition = () =>{
        //     //if(this.strRoomType === MultiPlayManager.ROOM_TYPE_LOBBY) return;
        //     try{
        //         if(this.room) this.room.send('move', this.avatar.status);
        //     }catch(_err){

        //         console.log("ERR ::",_err.message);
        //     }
        // } 
    }

    /**
     * 새로운 봇을 하나 만든다
     * 봇이 생성되는 위치는 봇들의 이동 포인트중 하나가 된다. 봇들은 유저가 월드에 입장하면 
     * @param {*} name 
     */
    makeABot(name)
    {
        const positions = Constants.BOT_NAVI_LOCATIONS;
        const botPosIdx = Utils.RandomInt(0, positions.length-1);
        const botPos = positions[ botPosIdx];
        const avatar = new Avatar(null, false, name);
        // Constants.CONTAINER_MAP.addChild(avatar.mesh);
        this.bots[name] = avatar;
        console.log("[Bot] ", name, " - (", botPos, ") ", botPosIdx);
        avatar.name = name;     // Avatar 객체의 set name 에서 하는 일이 있으므로 꼭 수행해야 함.
        avatar.position = botPos;


        NavimeshManager.AddAvatar(avatar, null);
        // avatar.mesh.position = new BABYLON.Vector3(0, 0, 0);

    }

    moveBot()
    {
        const positions = Constants.BOT_NAVI_LOCATIONS;
        const players = Object.keys(this.bots);
        const arrIdx = Utils.arrayShuffle(positions.map( (i, idx)=>idx)); // 0~장소 수 사이의 숫자를 배열에서 셔플해 둠
        players.forEach( (i, idx)=> // i는 bots의 name
        {
            const avatar = this.bots[i];
            // const botPosIdx = Utils.RandomInt(0, positions.length-1);
            const nextBotPos = positions[ arrIdx[idx] ];
            // const naviClosestPoint = NavimeshManager.navigationPlugin.getClosestPoint(nextBotPos);
            // if (naviClosestPoint.x === 0 && naviClosestPoint.z === 0) return;

            // console.log("이동 : ", nextBotPos, botPosIdx);
            // console.log("무브 아바타 : ", i, avatar.agentIndex, avatar, NavimeshManager.crowd);

            // NavimeshManager.crowd.agentGoto(NavimeshManager.agents[avatar.agentIndex], nextBotPos);
            NavimeshManager.crowd.agentGoto(avatar.agentIndex, nextBotPos);
        });
    }

    /**
    *===============================================================================
    * connect()                :
    * @param _strRoomName      : 
    *===============================================================================
    */
    // connect(_strRoomName, _isLobby = false)
    // {
    //     this.strRoomType = _isLobby ? MultiPlayManager.ROOM_TYPE_LOBBY : MultiPlayManager.ROOM_TYPE_NORMAL;

    //     this.joinOrCreate(_strRoomName).then((room) => {
    //         this.room = room;
    //         //console.log("_strRoomName ::::", _strRoomName, this.room);
    //         this.room.state.players.onAdd = (player, key) => {
    //             this.key = key;
                
    //             if(this.room.sessionId === key)
    //             {
    //                 if(this.strRoomType  === MultiPlayManager.ROOM_TYPE_NORMAL) {
    //                     this.objPlayers[key] = this.avatar;
    //                     this.scene.onBeforeAnimationsObservable.add(this.sendPosition);
    //                 }
                    
    //                 this.onLogin(this.avatar.status);
    //             }else { 
    //                 if(this.strRoomType  === MultiPlayManager.ROOM_TYPE_NORMAL){
    //                     let onAvatarLoded = () => {
    //                         this.objPlayers[key] = avatar;
    //                         this.objPlayers[key].position = new BABYLON.Vector3(player.position.x, player.position.y, player.position.z);
    //                         this.objPlayers[key].rotation = new BABYLON.Vector3(player.rotation.x, player.rotation.y, player.rotation.z);
    //                         this.objPlayers[key].name = player.name;
    //                         this.objPlayers[key].grade = player.grade;
    //                         this.objPlayers[key].color = player.color;
    //                         this.objPlayers[key].image = player.image;
    
    //                         player.position.onChange = () => {
    //                             if(this.objPlayers[key]) {
    //                                 this.objPlayers[key].position = new BABYLON.Vector3(player.position.x, player.position.y, player.position.z);
    //                             }
    //                         };
            
    //                         player.rotation.onChange = () => {
    //                             if(this.objPlayers[key]) 
    //                             {
    //                                 this.objPlayers[key].rotation = new BABYLON.Vector3(player.rotation.x, player.rotation.y, player.rotation.z);
    //                             }
    //                         };
    //                     }
    //                     let avatar
    
    //                     setTimeout(() => {
    //                         load();
    //                     }, 200);
    
    //                     const load = ()=>{
                            
    //                         let meshURL = Constants.AVATAR_FILE_NAME;
    //                         avatar = new Avatar(meshURL, false);
    //                         avatar.addEventListener(Avatar.LOAD_COMPLETE, onAvatarLoded);
    //                     }
    //                 }
    //             }
    //         }
            
            
            
    //         this.room.connection.transport.ws.onclose = (_e)=>{
    //             console.log("CLOSE MULTI SERVER");
    //             if(this.scene && this.sendPosition) this.scene.onBeforeAnimationsObservable.removeCallback(this.sendPosition);
    //         }; 

    //         this.room.onMessage("chat", this.onMessageChatting);
    //         this.room.state.players.onRemove =  (player, key)=> {
    //             if(this.objPlayers[key]){
    //                 this.objPlayers[key].dispose();
    //             }
    //             this.onLogout(player);
    //         }; 

    //         this.room.onLeave(this.onLeave);
    //         this.room.onError(this.onError);
            
    //     }).catch(e => {
    //         //console.log("JOIN ERROR", e);
    //     });
    // }

    dispose(){
        //console.log("MultiPlayManager.dispose ::::::")
        for(const key in this.bots){
            this.bots[key].dispose();
        }
        this.room.connection.close();
        this.bots = null;
        this.key = null;
        this.room = null;
        this.scene = null;

        if ( this.botMoveInterval )
            clearInterval( this.botMoveInterval );

    }
}