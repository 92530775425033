import React, { useContext } from "react";
import styled from "styled-components";
import ImgTutorialPC from "../images/img_guide_PC.png";
import ImgTutorialMobile from "../images/img_guide_Mobile.png";
import { ImgBox, ImgButton } from "../common/StyleMasterComp";
import { MainContext } from "../page/PageMain";
import ImgCloseList from "../images/ico_x.png";
import Utils from "../../common/Utils";


let ContainerBox = styled.div`
    position: fixed;
    left: 0;
    top:0;
    pointer-events:all;    
    z-index: 1000;
`;
    // height:520vh;
    // width:120vw;

let TutorialImg = styled(ImgBox)`
    background-image:url(${!Utils.checkMobileByWidth()?ImgTutorialPC:ImgTutorialMobile});
    width: ${props => cw};
    height: ${props => ch};
`;
    // top: 50px;
    // position: absolute;
    // left: ${props => cl};
    // left: calc(50% - ((${props => props.Width} / 2)));

const _minWidth = 300;
// Calculate Width
function cw( props )
{
    const _w = props.Width || "340";
    return (parseInt(_w)>_minWidth?_minWidth:_w) + "px";
}

// Calculate Width
function ch( props )
{
    return parseInt( cw(props) ) * (Utils.checkMobileByWidth()?18:18.3)/17 + "px";
}

function cl( props )
{
    const _w = props.Width || "300";
    console.log("aaaaaaaaaa", window.screen.width - parseInt(_w/2), window.screen.width, parseInt(_w/2), window.screen)
    return (window.screen.width - parseInt(_w/2))+"px";

}
function ModalTutorial(props){
    const mainContext = useContext(MainContext);

    const squareSize = () => {
        const min = Math.min( window.screen.width, window.screen.height );
        console.log("squareSize : ", min, window.screen.width, window.screen.height);
        return min;
    }
  
    return(
        <ContainerBox style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width:"100%"}} >
            {/* <div style={{width: squareSize()*0.7+"px"}}> */}
            <div style={{position:"relative"}}>
                <ImgButton BackImg={ImgCloseList} BtnWidth="18px" BtnHeight="18px"
                    Position="absolute" Right="14px" Top="14px"
                    onClick={() => { mainContext.setFlagOpenTutorial(false); }}
                />
                <TutorialImg Width={squareSize()*0.7+"px"} />
            </div>
        </ContainerBox>
    )
}

export default ModalTutorial;