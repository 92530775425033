    /*a
     @Class          : CameraManager
    @Maker          : 윤휘원 (박정훈 팀장님 작업 상속)
    @Date           : 2022-01-05
    @Debug DATE     : 
    @Debug Contents : -
    @Version        : 1.0
    @Description    : 카메라 뷰 컨트롤을 위한 클래스
    */

    import * as BABYLON from 'babylonjs';
    import gsap from "gsap";
    import Communicator from '../common/Communicator';
    import Constants from '../common/Constants';
    import DataManager from '../common/data/DataManager';
    import CommunicatorEvent from '../common/event/CommunicatorEvent';
    import LocationIndicator from './LocationIndicator';
    import NavimeshManager from './NavimeshManager';

    export default class CameraManager {
    static PLAY_MODE = "Play_Mode";
    static MINI_MAP_MODE = "Mini_Map_Mode";
    static BOOTH_MODE = "Booth_Mode";

    static meshLookAt;
    static meshTarget;
    static avatar;
    static currentMode;
    static oldMode;
    static locator;
    static aniFlow;

    static m_iSwingCountFrame = 0;
    static m_iSwingCycle = 20;
    static m_iSwingSpeed = .05;
    static m_iFloatingAmplitude = .07;

    static pipeline = null;

    /**
     *===============================================================================
    * constructor()      : 
    * @param _objInit   : 싱글톤 생성 확인용 객체
    *===============================================================================*/
    constructor(_objInit) {
        //super();

        if (_objInit !== CameraManager.m_objInit) {
            throw new Error("CameraManager 생성은 다음과 같이 해주세요 - CameraManager.GetInstance()");
            // return;
        }
    }

    /**
     *===============================================================================
    * dispose()               : 
    *===============================================================================*/
    static dispose() {

        gsap.killTweensOf(CameraManager.meshLookAt);
        gsap.killTweensOf(CameraManager.camera);

        CameraManager.m_iSwingCountFrame = 0;

        CameraManager.avatar = null;
        CameraManager.meshTarget = null;
        CameraManager.camera.dispose();
        CameraManager.m_cameraManager = null;
        CameraManager.m_objInit = null;
    }

    /**
     *===============================================================================
    * GetInstance()      : 
    * @return            : 인스턴스 리턴
    *===============================================================================*/
    static GetInstance() {
        if (CameraManager.m_cameraManager == null) {
            CameraManager.m_cameraManager = new CameraManager(CameraManager.m_objInit);
            CameraManager.m_objInit = {};
            CameraManager.GetInstance().setLooAtMesh();

            Communicator.reciveEvent(CommunicatorEvent.CHANGE_CAMERA_MODE, (_data) => {
                CameraManager.cameraMode = _data;
                CameraManager.aniFlow = null;
            })

        }
        return CameraManager.m_cameraManager;
    }

    /**
     *===============================================================================
    * set cameraMode()      : 카메라 모드 변경 setter
    * @param _mode      : 카메라 모드 /미니맵모드/플래이모드/부스모드
    *===============================================================================*/
    static set cameraMode(_mode) {
        CameraManager.oldMode = CameraManager.currentMode;
        CameraManager.currentMode = _mode;
        
        //console.log("cameraMode ::: ",_mode);
        switch (_mode) {
            case CameraManager.PLAY_MODE:
                CameraManager.camera.inputs.attached.mousewheel.detachControl(Constants.CANVAS); // 휠 이벤트 해제
                CameraManager.GetInstance().playMode();
                break;
            case CameraManager.MINI_MAP_MODE:
                CameraManager.GetInstance().miniMapMode();
                break;
            case CameraManager.BOOTH_MODE:
                CameraManager.camera.inputs.attached.mousewheel.detachControl(Constants.CANVAS); // 휠 이벤트 해제
                CameraManager.GetInstance().boothMode();
                break;
            default:
                break;
        }
    }

    /**
     *===============================================================================
    * get cameraMode()      : 현재 카메라 모드 getter
    * @return               : 현재 카메라 모드 /미니맵모드/플래이모드/부스모드
    *===============================================================================*/
    static get cameraMode() {
        return CameraManager.currentMode;
    }
    /**
     *===============================================================================
    * set target()              : 아바타 설정 setter 
    * @param _avatar        : 아바타 인스턴스
    *===============================================================================*/
    static set target(_avatar) {
        CameraManager.avatar = _avatar;
        CameraManager.meshTarget = CameraManager.avatar.meshNevi;
    }

    /**
     *===============================================================================
    * get lookAt()      : 카메라 룩엣 객체 getter
    * @return           : 카메라 룩엣 객체
    *===============================================================================*/
    static get lookAt() {
        return CameraManager.meshLookAt;
    }

    /**
     *===============================================================================
    * setPostProcessing()    : 포스트 프로세싱 
    *===============================================================================*/
    static setPostProcessing() {
        // 포스트 프로세싱
        CameraManager.pipeline = new BABYLON.DefaultRenderingPipeline("postprocess", true, CameraManager.scene, [CameraManager.camera]);
        CameraManager.pipeline.bloomEnabled = true;
        CameraManager.pipeline.fxaaEnabled = true;
        CameraManager.pipeline.bloomWeight = .4;
        CameraManager.pipeline.imageProcessingEnabled = true;

        // BlurPostProcess 생성
        // var horizontalBlur = new BABYLON.BlurPostProcess("horizontalBlur", new BABYLON.Vector2(1, 0), 2, 1.0, CameraManager.camera);
        // var verticalBlur = new BABYLON.BlurPostProcess("verticalBlur", new BABYLON.Vector2(0, 1), 2, 1.0, CameraManager.camera);

        // var bloomEffect = BABYLON.BloomEffect(Constants.SCENE, 0.5, 1, 3);

        // // PostProcessManager에 BlurPostProcess 추가
        // var postProcessManager = new BABYLON.PostProcessManager(Constants.SCENE);
        // postProcessManager.addEffect(bloomEffect)
        // console.log("post process manager : ", Constants.SCENE.postProcess);
        // postProcessManager.addEffect(horizontalBlur);
        // postProcessManager.addEffect(verticalBlur);
    }

    /**
     *===============================================================================
    * setLooAtMesh()    : 룩엣 객체 생성 
    *===============================================================================*/
    setLooAtMesh() {
        CameraManager.meshLookAt = BABYLON.MeshBuilder.CreateBox("cube", {
            size: 1.1,
            height: 1.1
        }, CameraManager.scene);
        CameraManager.meshLookAt.isVisible = false;
        CameraManager.meshLookAt.position = new BABYLON.Vector3(Constants.MAP_CENTER.x, Constants.MAP_CENTER.y, Constants.MAP_CENTER.z);
        CameraManager.GetInstance().setCamera();
        CameraManager.GetInstance().setLocator();
    }

    /**
     *===============================================================================
    * setCamera()    :  카메라 생성
    *===============================================================================*/
    setCamera() {
        CameraManager.camera = new BABYLON.ArcRotateCamera("Camera", -Math.PI / 90, //회전 최초방위값은 이값을 바꾸면 됨
            0.8, //기울기
            50, // 스케일
            new BABYLON.Vector3.Zero(), Constants.SCENE);
        CameraManager.camera.attachControl(Constants.CANVAS, true);
        CameraManager.camera.inputs.attached.mousewheel.detachControl(Constants.CANVAS);

        CameraManager.setPostProcessing();

        /*          var lensEffect = new BABYLON.LensRenderingPipeline('lens', {
                    edge_blur: 1.0,
                    chromatic_aberration: 1.0,
                    distortion: 1.0,
                    dof_focus_distance: 150,
                    dof_aperture: 6.0,			// set this very high for tilt-shift effect
                    grain_amount: 1.0,
                    dof_pentagon: true,
                    dof_gain: 1.0,
                    dof_threshold: 1.0,
                    dof_darken: 0.25
                }, Constants.SCENE, 1.0, CameraManager.camera); */

    }

    /**
     *===============================================================================
    * setLocator()    :  로케이터 설정
    *===============================================================================*/
    setLocator() {
        CameraManager.locator = new LocationIndicator();
    }


    /**
     *===============================================================================
    * setCamera()    :  카메라 컨트롤 리미트 설정
    *===============================================================================*/
    setLimit() {
        //트위닝을 위한 범위 설정
        // CameraManager.camera.lowerAlphaLimit = BABYLON.Tools.ToRadians(15); //회전 각도 제한
        // CameraManager.camera.upperAlphaLimit = BABYLON.Tools.ToRadians(165); //회전 각도 제한
        CameraManager.camera.lowerBetaLimit = BABYLON.Tools.ToRadians(5); //탑뷰 각도 제한
        CameraManager.camera.upperBetaLimit = BABYLON.Tools.ToRadians(85); // 버텀뷰 각도 제한
        CameraManager.camera.lowerRadiusLimit = 2; //최대 사이즈 제한
        CameraManager.camera.upperRadiusLimit = 300; //최소 사이즈 제한
    }

    /**
     *===============================================================================
    * miniMapMode()    :  미니맵모드 카메라 설정
    *===============================================================================*/
    miniMapMode() {
        gsap.killTweensOf(CameraManager.meshLookAt);
        gsap.killTweensOf(CameraManager.camera);

        if (Constants.SNOW_PARTICLE) Constants.SNOW_PARTICLE.start();

        CameraManager.camera.setTarget(CameraManager.meshLookAt);

        Constants.SCENE.onBeforeAnimationsObservable.remove(CameraManager.aniFlow);
        CameraManager.camera.zoomToMouseLocation = false;

        CameraManager.onFloatingMap()
        CameraManager.GetInstance().setLimit();

        CameraManager.locator.StartFlow();

        const endTween = () => {
            if (CameraManager.currentMode !== CameraManager.MINI_MAP_MODE) return;
            CameraManager.meshLookAt.position = new BABYLON.Vector3(Constants.MAP_CENTER.x, Constants.MAP_CENTER.y, Constants.MAP_CENTER.z);
            CameraManager.camera.inputs.attached.mousewheel.attachControl(Constants.CANVAS);    // 휠 이벤트 연결
            //모드 제한 설정
            CameraManager.camera.lowerBetaLimit = BABYLON.Tools.ToRadians(45); //탑뷰 각도 제한
            CameraManager.camera.lowerRadiusLimit = 100; //최대 사이즈 제한
            CameraManager.camera.upperRadiusLimit = 150; //최소 사이즈 제한
            CameraManager.camera.minZ = 1;

        }

        gsap.to(CameraManager.meshLookAt.position, {
            duration: 1,
            x: Constants.MAP_CENTER.x,
            y: Constants.MAP_CENTER.y,
            z: Constants.MAP_CENTER.z,
            ease: "power1.outout",
            onComplete: endTween
        });

        gsap.to(CameraManager.camera, {
            duration: 1,
            radius: 150,
            beta: BABYLON.Tools.ToRadians(75),
            alpha: BABYLON.Tools.ToRadians(45),
            ease: "power1.outout"
        });


    }


    /**
     *===============================================================================
    * playMode()    :  플레이모드 카메라 설정
    *===============================================================================*/
    playMode() {
        gsap.killTweensOf(CameraManager.meshLookAt);
        gsap.killTweensOf(CameraManager.camera);

        //if(Constants.SNOW_PARTICLE)Constants.SNOW_PARTICLE.stop();

        if (CameraManager.meshTarget === null || CameraManager.meshTarget === undefined) return;
        let nDuration = CameraManager.oldMode === CameraManager.BOOTH_MODE ? .7 : 1;

        CameraManager.m_iSwingCountFrame = 0;
        CameraManager.stopFloatingMap();
        CameraManager.camera.zoomToMouseLocation = false;

        // KDG
        // if (CameraManager.oldMode === CameraManager.MINI_MAP_MODE)
        //     NavimeshManager.ForceMove(new BABYLON.Vector3(CameraManager.meshTarget.position.x, CameraManager.meshTarget.position.y, CameraManager.meshTarget.position.z));

        CameraManager.GetInstance().setLimit();

        const endTween = () => {
            if (CameraManager.currentMode !== CameraManager.PLAY_MODE) return;
            CameraManager.locator.StopFlow();
            // CameraManager.camera.zoomToMouseLocation = true;
            // CameraManager.camera.lowerAlphaLimit = BABYLON.Tools.ToRadians(15); //회전 각도 제한
            // CameraManager.camera.upperAlphaLimit = BABYLON.Tools.ToRadians(165); //회전 각도 제한
            CameraManager.camera.lowerBetaLimit = BABYLON.Tools.ToRadians(5); //탑뷰 각도 제한
            CameraManager.camera.upperBetaLimit = BABYLON.Tools.ToRadians(85); // 버텀뷰 각도 제한
            CameraManager.camera.lowerRadiusLimit = 16; //최대 사이즈 제한
            CameraManager.camera.upperRadiusLimit = 16; //최소 사이즈 제한
            CameraManager.camera.minZ = 0.01;

            CameraManager.camera.setTarget(CameraManager.meshTarget);
            if (CameraManager.aniFlow === null) CameraManager.aniFlow = Constants.SCENE.onBeforeAnimationsObservable.add(CameraManager.GetInstance().flowAvatar);
        }

        gsap.to(CameraManager.meshLookAt.position, {
            duration: nDuration,
            x: CameraManager.meshTarget.position.x,
            y: CameraManager.meshTarget.position.y,
            z: CameraManager.meshTarget.position.z,
            ease: "power1.outout",
            onComplete: endTween
        });

        gsap.to(CameraManager.camera, {
            duration: nDuration,
            radius: 16,
            beta: BABYLON.Tools.ToRadians(45),
            alpha: BABYLON.Tools.ToRadians(90),
            ease: "power1.outout",
        });

        //맵 기울기 원상 복귀
        gsap.to(Constants.CONTAINER_MAP.rotation, {
            duration: nDuration,
            x: 0,
            y: 0,
            z: 0,
            ease: "power1.outout"
        });
    }

    /**
     *===============================================================================
    * boothMode()    :  부스모드 카메라 설정
    *===============================================================================*/
    boothMode() {

        gsap.killTweensOf(CameraManager.meshLookAt);
        gsap.killTweensOf(CameraManager.camera);

        //if(Constants.SNOW_PARTICLE)Constants.SNOW_PARTICLE.stop();

        if (CameraManager.meshTarget === null || CameraManager.meshTarget === undefined) return;
        let nDuration = CameraManager.oldMode === CameraManager.PLAY_MODE ? .7 : 1;
        CameraManager.m_iSwingCountFrame = 0;
        // Constants.SCENE.onBeforeAnimationsObservable.removeCallback(this.floating)
        CameraManager.stopFloatingMap();
        CameraManager.locator.StopFlow();
        CameraManager.GetInstance().setLimit();
        CameraManager.camera.zoomToMouseLocation = false;


        const endTween = () => {
            if (CameraManager.currentMode !== CameraManager.BOOTH_MODE) return;
            // CameraManager.camera.lowerAlphaLimit = BABYLON.Tools.ToRadians(30); //회전 각도 제한
            // CameraManager.camera.upperAlphaLimit = BABYLON.Tools.ToRadians(90); //회전 각도 제한
            CameraManager.camera.lowerBetaLimit = BABYLON.Tools.ToRadians(5); //탑뷰 각도 제한
            CameraManager.camera.upperBetaLimit = BABYLON.Tools.ToRadians(85); // 버텀뷰 각도 제한
            CameraManager.camera.lowerRadiusLimit = 8; //최대 사이즈 제한
            CameraManager.camera.upperRadiusLimit = 8; //최소 사이즈 제한
            // CameraManager.camera.zoomToMouseLocation = true;

            Communicator.sendEvent(CommunicatorEvent.ANIMEND_BOOTH);

            CameraManager.camera.setTarget(CameraManager.meshTarget);
        }

        gsap.to(CameraManager.meshLookAt.position, {
            duration: nDuration,
            x: CameraManager.meshTarget.position.x,
            y: CameraManager.meshTarget.position.y,
            z: CameraManager.meshTarget.position.z,
            ease: "power1.outout",
        });

        gsap.to(CameraManager.camera, {
            duration: nDuration,
            radius: 8,
            beta: BABYLON.Tools.ToRadians(45),
            alpha: BABYLON.Tools.ToRadians(45),
            ease: "power1.outout",
            onComplete: endTween
        });


        //맵 기울기 원상 복귀
        gsap.to(Constants.CONTAINER_MAP.rotation, {
            duration: nDuration,
            x: 0,
            y: 0,
            z: 0,
            ease: "power1.outout"
        });
    }

    /**
     *===============================================================================
    * flowAvatar()    :  카메라 아바타 따라다니기
    *===============================================================================*/
    flowAvatar() {
        CameraManager.meshLookAt.position.x = CameraManager.meshTarget.position.x;
        CameraManager.meshLookAt.position.z = CameraManager.meshTarget.position.z;
        CameraManager.meshLookAt.position.y = CameraManager.meshTarget.position.y;
        CameraManager.meshLookAt.rotation = CameraManager.meshTarget.rotation;
    }


    /**
     *===============================================================================
    * floating()    :  미니맵 모드 비행기 플로팅 애니메이션
    *===============================================================================*/
    floating() {
        if (Constants.CONTAINER_MAP === null) return;
        CameraManager.m_iSwingCountFrame++;
        let digree = Math.sin(CameraManager.m_iSwingCountFrame / (CameraManager.m_iSwingCycle) * CameraManager.m_iSwingSpeed) * CameraManager.m_iFloatingAmplitude;
        let digree2 = Math.sin(CameraManager.m_iSwingCountFrame / (CameraManager.m_iSwingCycle) * CameraManager.m_iSwingSpeed) * (CameraManager.m_iFloatingAmplitude - .05);

        Constants.CONTAINER_MAP.rotation = new BABYLON.Vector3(digree2, 0, digree);
    }

    /**
     *===============================================================================
    * onFloatingMap()    :  맵 플로팅 시작
    *===============================================================================*/
    static onFloatingMap() {
        Constants.SCENE.onBeforeAnimationsObservable.add(CameraManager.GetInstance().floating);
    }

    /**
     *===============================================================================
    * stopFloatingMap()    :  맵 플로팅 멈춤
    *===============================================================================*/
    static stopFloatingMap() {
        Constants.SCENE.onBeforeAnimationsObservable.removeCallback(CameraManager.GetInstance().floating)
    }

    /**
     *===============================================================================
    * goToBooth()    :  부스로 이동
    * @param _iId    : 부스 아이디 int
    *===============================================================================*/
    static goToBooth(_iId) {
        let objCompanyData = DataManager.GetCompoanyDataByID(_iId);
        let vecTargetPoint = new BABYLON.Vector3(objCompanyData.positionNew.x, objCompanyData.positionNew.y, objCompanyData.positionNew.z);
        CameraManager.meshTarget.position = vecTargetPoint;
        NavimeshManager.ForceMove(vecTargetPoint);
        CameraManager.cameraMode = CameraManager.BOOTH_MODE;
    }
    };