/**
 @Class          : CommunicatorEvent
 @Maker          : 윤휘원 (박정훈 팀장님 작업 상속)
 @Date           : 2022-01-05
 @Debug DATE     : 
 @Debug Contents : -
 @Version        : 1.0
 @Description    : content(BabylonJS)와 UI(ReactJS)간 데이터 연동을 위한 이벤트 관리 클래스
 */

export default class CommunicatorEvent
{
    /*===============================================================================
    * Babylon to React
    *===============================================================================*/
    static WORLD_LOAD_COMPLETE = "World_Load_Complete";     // 월드 맵 로드 완료 이벤트
    static WORLD_LOADING = "World_Loading";                 // WebGL 컨텐츠 로드및 설정 진행율 표시
    static RECEIVE_CHAT_MESSAGE= "Receive_Chat_Message";    // 채팅메시지 수신
    static OPEN_CATALOG = "Open_Catalog";                   // 카탈로그 오픈
    static OPEN_URL_LINK = "Open_Url_Link";                 // URL Link - Homepage, Sns, 
    static OPEN_VIDEO = "Open_Video";                       // 비디오 오픈
    static OPEN_PDF = "Open_PDF";                           // PDF 오픈 
    static OPEN_BOARDVIDEO = "Open_BoardVideo"              // 보드A-총장말씀, 보드B-산학관,url: 유튜브링크
    static ENTER_BOOTH = "Enter_Booth"                      // 부스 입장 시 해당 부스 정보 넘기기
    static ANIMEND_BOOTH = "AnimEnd_Booth"                  // 애니메이션 끝

    /*===============================================================================
    * React to Babylon
    *===============================================================================*/
    static START_CONTENT = "Start_Content";                 // WebGL 컨텐츠 스타트
    static CHANGE_CAMERA_MODE = "Change_Camera_Mode";       // 맵 Change 이벤트
    static SEND_CHAT_MESSAGE = "SEND_CHAT_MESSAGE";         // 채팅메시지 송신
    static GO_TO_TENANT = "Go_Yo_Tenant";                   // 업체 부스로 이동
};