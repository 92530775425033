/**
 @Class          : ShadowManager
 @Maker          : 윤휘원 (박정훈 팀장님 작업 상속)
 @Date           : 2022-01-05
 @Debug DATE     : 
 @Debug Contents : -
 @Version        : 1.0
 @Description    : 그림자 관리 클래스
 */

import * as BABYLON from 'babylonjs';


export default class ShadowManager
{
    static m_directionalLight;
    static m_arrReciveObjects;
    static m_directionalLight;

    /**
    *===============================================================================
    * constructor()      : 
    * @param _objInit   : 싱글톤 생성 확인용 객체
    *===============================================================================*/
    constructor(_objInit)
    {
        //super();
        if (_objInit !== ShadowManager.m_objInit) 
        {
            throw new Error( "ShadowManager 생성은 다음과 같이 해주세요 - ShadowManager.GetInstance()" ); 
           // return;
        }
    }

    /**
    *===============================================================================
    * dispose()               : 
    *===============================================================================*/
    static dispose(){
        ShadowManager.m_directionalLight = null;
        ShadowManager.m_shadowManager = null;
        ShadowManager.m_objInit = null;
    }
    
    /**
    *===============================================================================
    * GetInstance()      : 
    * @return            : 인스턴스 리턴
    *===============================================================================*/
    static GetInstance(_directionalLight)
    {
        if(ShadowManager.m_shadowManager == null) 
        {
            ShadowManager.m_shadowManager = new ShadowManager(ShadowManager.m_objInit);
            ShadowManager.m_objInit = {};
            ShadowManager.m_directionalLight = _directionalLight;
            ShadowManager.GetInstance().setShadowGenerator();
        }
        return ShadowManager.m_shadowManager;
    }

    /**
    *===============================================================================
    * setShadowGenerator()     :  셰도우제너레이터 생성
    *===============================================================================*/
    setShadowGenerator(){
        ShadowManager.m_shadowGenerator = new BABYLON.ShadowGenerator(4096, ShadowManager.m_directionalLight);
        ShadowManager.m_shadowGenerator.useCloseBlurExponentialShadowMap = true;
        ShadowManager.m_shadowGenerator.forceBackFacesOnly = true;
        ShadowManager.m_shadowGenerator.usePoissonSampling = true;
        ShadowManager.m_shadowGenerator.useBlurExponentialShadowMap = true;
        ShadowManager.m_shadowGenerator.setDarkness(0);
        ShadowManager.m_shadowGenerator.enableSoftTransparentShadow = false;
        ShadowManager.m_shadowGenerator.transparencyShadow = true;
    }

    /**
    *===============================================================================
    * AddShadowMap()     :  그림자를 생성할 객체 추가
    * @param _mesh       :  대상 객체 mseh
    *===============================================================================*/
    static AddShadowMap(_mesh){
       // console.log("AddShadowMap :::: ", _mesh);
        // ShadowManager.m_shadowGenerator.getShadowMap().renderList.push(_mesh);
        ShadowManager.m_shadowGenerator.addShadowCaster(_mesh);
    }

    /**
    *===============================================================================
    * AddReceive()     :  그림자를 받아줄 객체 추가
    * @param _mesh      :  대상 객체 mseh
    *===============================================================================*/
    static AddReceive(_mesh){
        // console.log("AddReceive :::: ", _mesh);
        //_mesh.showBoundingBox = true;
        _mesh.receiveShadows = true;
    }

    /**
    *===============================================================================
    * AddReceive()     :  그림자를 생성할 객체에서 제외
    * @param _mesh      :  대상 객체 mseh
    *===============================================================================*/
    static RemoveCast(_mesh){
        // console.log("RemoveCast :::: ", _mesh);
        ShadowManager.m_shadowGenerator.removeShadowCaster(_mesh);
    }

    /**
    *===============================================================================
    * FreezingShadow()      :  그림자 프리징
    * @param _light         :  대상 라이트
    * @param _funcCallback  :  완료 콟백
    *===============================================================================*/
    static FreezingShadow(_light, _funcCallback){
    
        ShadowManager.m_shadowGenerator.getShadowMap().refreshRate = BABYLON.RenderTargetTexture.REFRESHRATE_RENDER_ONCE;
        _funcCallback();
    }
};

