import React from "react";
import styled from "styled-components";
import {ContainerBox} from "../../common/StyleMasterComp"

let MdBox = styled.div`
    position:absolute;
    border-top:1px solid #ddd;
    border-bottom:1px solid #ddd;
    width: 768px;
    height:39px;
`;

let MdForm = styled.form`   
    margin:0;
    padding:0;
    position:absolute;
    pointer-events: all;
`;

let ListSelect = styled.select`
    font-family: 'Spoqa HanSansNeo';
    padding: 0 5px; 
    width: 219px; 
    height: 39px; 
    border: 1px solid #ddd; 
    font-size: 15px;
    cursor: pointer;
    outline:none;
`;

let ListSelectOption = styled.option`
    outline:none;
`;

function MidSelector(props){
    let selectList = ["전체 부스","구로", "마포"];

    let handleSelect = (e) => {
        props.setSelected(e.target.value);
    };

    return(
        <ContainerBox>
            <MdBox/>
            <MdForm>
                <ListSelect onChange={handleSelect} value={props.Selected}>
                    {selectList.map((data,i) => (
                        <ListSelectOption value={data} key={i}>
                            {data}
                        </ListSelectOption>
                    ))}
                </ListSelect>
            </MdForm>
        </ContainerBox>
    );
}

export default MidSelector;