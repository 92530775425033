import * as BABYLON from 'babylonjs';
import EventDispatcher from '../common/event/EventDispatcher';
import Constants from '../common/Constants';

export default class LocationIndicator extends EventDispatcher {
    'use strict';

    static LOAD_COMPLETE = "Load_Complete";


    /**
     *===============================================================================
     * constructor()      :
     * @param _objItemData      : 
     *===============================================================================
     */
    constructor() {
        super();

        this.nScale = 0.15;
        this.meshArrow = null;

        this.init();

    }

    /**
     *===============================================================================
     * Init()             :
     *===============================================================================
     */
    init() {

        this.loadMesh();
    }


    loadMesh() {
        let onLoadComplete = (_newMeshes) => {

            this.meshArrow = _newMeshes[0];
            this.meshArrow.name = "Locator";

            _newMeshes[1].name = "Locator_Mesh";
            _newMeshes[1].material._roughness = 1;
            _newMeshes[1].material.emissiveColor = new BABYLON.Color3.FromHexString("#CF9677");
            _newMeshes[1].material.emissiveIntensity  = 0.08;

            // KDG 잘못된 색이 칠해지는것 방지
            // _newMeshes[1].material.albedoTexture = new BABYLON.Texture("images/Yirumae_Walk.png", Constants.SCENE);
            // _newMeshes[1].material.albedoTexture.uAng = Math.PI;
            this.meshArrow.scaling = new BABYLON.Vector3(this.nScale, this.nScale, this.nScale);
            this.meshArrow.isVisible = false;

            // this.meshArrow.scaling = new BABYLON.Vector3(this.nScale, this.nScale, this.nScale);

            for (var i = 0; i < this.meshArrow.getChildMeshes(false).length; i++) {
                this.meshArrow.getChildMeshes(false)[i].isVisible = false;
            }

            this.meshArrow.position.x = 0;
            this.meshArrow.position.y = 2;
            this.meshArrow.position.z = 0;
            
            this.flowAnimation = () => {
                this.onFlowAnimation();
            }

            this.aniRotate = Constants.SCENE.onBeforeAnimationsObservable.add(this.flowAnimation);
        }

        BABYLON.SceneLoader.ImportMesh("", Constants.ASSETS_URL, Constants.LOCATOR_FILE_NAME, Constants.SCENE, onLoadComplete);
    }

    onFlowAnimation() {
        if (Constants.AVATAR === null || Constants.AVATAR === undefined) return;
        if (Constants.AVATAR.mesh === null || Constants.AVATAR.mesh === undefined) return;
        if (this.meshArrow === null || this.meshArrow === undefined) return;

        let digree = this.meshArrow.rotation.y + .01;
        this.meshArrow.rotation = new BABYLON.Vector3(0, digree, 0);


        this.meshArrow.position.x = Constants.AVATAR.mesh.position.x;
        this.meshArrow.position.z = Constants.AVATAR.mesh.position.z;
        this.meshArrow.position.y = Constants.AVATAR.mesh.position.y + 5;

    }

    StartFlow() {
        this.show(true);
        if (this.aniRotate !== null) return;
        this.aniRotate = Constants.SCENE.onBeforeAnimationsObservable.add(this.flowAnimation);
    }

    StopFlow() {
        Constants.SCENE.onBeforeAnimationsObservable.remove(this.aniRotate);
        this.aniRotate = null;
        this.show(false);
    }


    show(_isShow) {
        if (this.meshArrow === null) return;
        this.meshArrow.isVisible = _isShow;

        for (var i = 0; i < this.meshArrow.getChildMeshes(false).length; i++) {
            this.meshArrow.getChildMeshes(false)[i].isVisible = _isShow;
        }
    }

    dispose() {
        this.meshArrow.dispose();
    }
}