/**
 @Class          : Communicator
 @Maker          : 윤휘원 (박정훈 팀장님 작업 상속)
 @Date           : 2022-01-05
 @Debug DATE     : 
 @Debug Contents : -
 @Version        : 1.0
 @Description    : content(BabylonJS)와 UI(ReactJS)간 데이터 연동을 위한 클래스
                   싱글톤 페턴을 사용하므로 한가지 아벤트는 한군데서만 사용가능함
 */
export default class Communicator
{
    static m_communicator;
    static m_objInit;
    static m_objEvents;
    
    /**
    *===============================================================================
    * constructor()      : 
    * @param _objInit   : 싱글톤 생성 확인용 객체
    *===============================================================================*/
    constructor(_objInit)
    {
        if (_objInit !== Communicator.m_objInit) 
        {
            throw new Error( "Communicator 생성은 다음과 같이 해주세요 - Communicator.GetInstance()" ); 
           // return;
        }
    }

    /**
    *===============================================================================
    * GetInstance()      : 
    * @return            : 커뮤니케이터 인스턴스 리턴
    *===============================================================================*/
    static GetInstance()
    {
        if(Communicator.m_communicator == null) 
        {
            Communicator.m_communicator = new Communicator(Communicator.m_objInit);
            Communicator.m_objInit = {};
            Communicator.m_objEvents = {};
        }
        return Communicator.m_communicator;
    }

    /**
    *===============================================================================
    * dispose()               : 
    *===============================================================================*/
    static dispose(){
        for(const event in Communicator.m_objEvents){
            Communicator.m_objEvents[event] = null;
        }

        Communicator.m_objEvents = null;
        Communicator.m_objInit = null;
        Communicator.m_communicator = null;
    }

    /**
    *===============================================================================
    * reciveEvent()             : 이벤트 수신
    * @param _strEvent          : 수신 이벤트
    * @param _funcCallBack      : 콜벡펑션
    *===============================================================================*/
    static reciveEvent(_strEvent, _funcCallBack){
        if (!Communicator.m_objInit) 
        {
            console.log( "Communicator 인스턴스가 생성되지 않아 이벤트를 " + _strEvent + " 등록할 수 없습니다." ); 
            return;
        } 
        if(!Communicator.m_objEvents[_strEvent]) Communicator.m_objEvents[_strEvent] = _funcCallBack;
        console.log(_strEvent, _funcCallBack);
    }

    /**
    *===============================================================================
    * sendEvent()               : 이벤트 발신
    * @param _strEvent          : 전송할 이벤트
    * @param _anyData           : 전송할 데이터 -- 
    *===============================================================================*/
    static sendEvent(_strEvent, _anyData = null){
        if (!Communicator.m_objInit) 
        {
            console.log( "Communicator 인스턴스가 생성되지 않아 이벤트를 전달할 수 없습니다.)" ); 
            return;
        }
        //console.log("Communicator sendEvent :: ", _strEvent, _anyData);
        if(Communicator.m_objEvents[_strEvent]) Communicator.m_objEvents[_strEvent](_anyData)
    }
};