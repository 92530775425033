import React, { useState, useEffect} from "react";
import styled from 'styled-components';

import { ModalBlackBox, ModalContainerBox } from "../common/StyleMasterComp";
import Utils_MediaQuery from "../common/Utils_MediaQuery";
import { BoothListContents } from "../components/ModalBoothList";

let BoothListContentsBoxContainer = styled.div`
    position: absolute;
    left:${props=> props.Left || "calc(50vw - (768px / 2))"};
    top:${props=> props.Top ||"calc(50vh - (762px / 2))"};
`;

function ModalBoothList(props){

    let [contentLocation, setContentLocation] = useState({ Left:"calc(50vw - (768px / 2))", Top:"calc(50vh - (762px / 2))" });

    useEffect(() => {
        let CurrMediaType = Utils_MediaQuery.GetMediaDetailType(props.objWindowSize.Width,props.objWindowSize.Height);
        // console.log("ModalBoothList : ",CurrMediaType);
        if(CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_M_FOLD_S ||
            CurrMediaType === Utils_MediaQuery.P_M_IPHONE_S ||
            CurrMediaType === Utils_MediaQuery.P_M_ANDROID_S ||
            CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_L){
            setContentLocation({ Left:"0px", Top:"0px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_T_GALAXY_S ||
            CurrMediaType === Utils_MediaQuery.P_T_IPAD_S ||
            CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_L){
            setContentLocation({ Left:"0px", Top:"0px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_D_DESKTOP_S ||
            CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_L){
            setContentLocation({ Left:"calc(50vw - (768px / 2))", 
                Top:"calc("+ props.objWindowSize.Height/2+"px - ( 762px / 2))" });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.L_M_FOLD_S ||
            CurrMediaType === Utils_MediaQuery.L_M_IPHONE_S ||
            CurrMediaType === Utils_MediaQuery.L_M_ANDROID_S ||
            CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_L){
            setContentLocation({ Left:"0px", Top:"0px" });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_S){
            setContentLocation({ Left:"calc(50vw - (557px / 2))", 
                Top:"calc("+ props.objWindowSize.Height/2+"px - ( 506px / 2))" });
        }
        else if(CurrMediaType === Utils_MediaQuery.L_T_GALAXY_S){
            setContentLocation({ Left:"calc(50vw - (605px / 2))", 
                Top:"calc("+ props.objWindowSize.Height/2+"px - ( 550px / 2))" });
        }
        else if(CurrMediaType === Utils_MediaQuery.L_T_IPAD_S ||
            CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_L){
            setContentLocation({ Left:"calc(50vw - (605px / 2))",
                Top:"calc("+ props.objWindowSize.Height/2+"px - ( 600px / 2))" });
        }

        else if(CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.L_D_DESKTOP_S ||
            CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_L){
            setContentLocation({ Left:"calc(50vw - (768px / 2))", 
                Top:"calc("+ props.objWindowSize.Height/2+"px - ( 762px / 2))" });
        }
    },[props.objWindowSize])

    return(
        <ModalContainerBox>
            <ModalBlackBox/>
            <BoothListContentsBoxContainer Left={contentLocation.Left} Top={contentLocation.Top}>
                <BoothListContents arrBoothData={props.arrBoothData}
                    funcSetBoothListView={props.funcSetBoothListView}
                    funcSendTenant = {props.funcSendTenant}
                    objWindowSize={props.objWindowSize}/>
            </BoothListContentsBoxContainer>
        </ModalContainerBox>
    );
}

export default ModalBoothList;