import * as BABYLON from 'babylonjs';
import EventDispatcher from '../common/event/EventDispatcher';
import gsap from "gsap";
import Constants from '../common/Constants';

export default class Marker extends EventDispatcher{
    'use strict';

    static LOAD_COMPLETE = "Load_Complete";


    /** 
     *===============================================================================
     * constructor()      :
     * @param _objItemData      : 
     *===============================================================================
     */
    constructor( ) {
        super();

        this.planeMarker = null;
        this.init();
    }

    /**
     *===============================================================================
     * Init()             :
     *===============================================================================
     */
    init() {
        
        this.makeMarker();
    }


    makeMarker(){

        this.planeMarker = BABYLON.Mesh.CreatePlane("outputplane", 1, Constants.SCENE, false);
        this.planeMarker.rotation.x = BABYLON.Tools.ToRadians(90);

        let met = new BABYLON.StandardMaterial("met_shadow", Constants.SCENE);
       
        met.opacityTexture = new BABYLON.Texture(Constants.MARKER_IMG_URL ,Constants.SCENE);
        met.opacityTexture.hasAlpha = true;
        met.opacityTexture.level = 2;
        //met.alpha = .3;
        met.diffuseColor = new BABYLON.Color3(0.18,0.62,0.54);

        this.planeMarker.material = met;
        this.planeMarker.isVisible = 0;

        

    }

    get mesh(){
        //console.log(this.planeMarker);
        return this.planeMarker;
    }

    onPointing(){

        this.planeMarker.isVisible = 1;
        this.planeMarker.scaling = new BABYLON.Vector3(1,1,1);
        //this.planeMarker.visibility = .5

        const endTweenA = () =>{ 
            
            this.planeMarker.isVisible = 0;
        }

        gsap.to(this.planeMarker.scaling, {
            duration: 3, 
            x: .01,
            y: .01,
            z: .01,
            ease: "elastic.out(1,0.4)",
            onComplete: endTweenA
          });

    }

}