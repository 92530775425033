import React from "react";
import styled from "styled-components";


let ContainerBox = styled.div`
    position: fixed;
    left: 0;
    top:0;
    pointer-events:all;    
    height:100vh;
    width:100vw;
    z-index: 1000;
`;

let AlphaBgBox = styled.div`
    position: fixed;
    left: 0;
    top:0;
    pointer-events:all;    
    height:100vh;
    width:100vw;
    background-color: black;
    opacity: 0;
    z-index: 1100;
`;



function ModalLoading(props){
  
    return(
        <ContainerBox>
            <AlphaBgBox/>
        </ContainerBox>
    )
}

export default ModalLoading;