/**
 @Class          : UOSManager
 @Maker          : 윤휘원
 @Date           : 2022-01-05
 @Debug DATE     : 
 @Debug Contents : -
 @Version        : 1.0
 @Description    : 서울시립대 컨텐츠 바인딩 및 인터렉티브 관리자
 */

import * as BABYLON from "babylonjs";
import Communicator from "../common/Communicator";
import EventDispatcher from "../common/event/EventDispatcher";
import Constants from "../common/Constants";
import CommunicatorEvent from "../common/event/CommunicatorEvent";
import DataManager from "../common/data/DataManager";
import CameraManager from "./CameraManager";

export default class UOSManager extends EventDispatcher {
  "use strict";

  static LOAD_COMPLETE = "Load_Complete";

  /**
   *===============================================================================
   * constructor()      :
   *===============================================================================*/
  constructor() {
    super();
    this.UOSData = DataManager.GetUOSData();
    this.highlightLayer = null;

    //this.init();
  }

  /**
   *===============================================================================
   * dispose()               :
   *===============================================================================*/
  dispose() {
    this.highlightLayer.dispose();
    this.highlightLayer = null;
  }

  /**
   *===============================================================================
   * init()               :
   *===============================================================================*/
  init() {
    // this.setConferenceScreen();
    // this.setPresidentScreen();
    // this.setKiosk();   // 2023스펙에서 산학관 키오스크 제거에 따른 주석 처리
    // this.setInterective();
  }

  /**
   *===============================================================================
   * setConferenceScreen()               : 컨퍼런스 스크린(전광판, 좌측)
   *===============================================================================*/
  setConferenceScreen() {
    let mesh = Constants.SCENE.getNodeByName("screen_02");
    this.setAction(mesh);
    var mat = new BABYLON.StandardMaterial("screen02_mat", Constants.SCENE);

    /*         this.videoVidTex = new BABYLON.VideoTexture("video", this.strBaseURL + this.UOSData.video_big_url, Constants.SCENE, true);
                mat.diffuseTexture = this.videoVidTex;
                this.videoVidTex.video.pause();
                this.videoVidTex.video.volume = 0; */

    let texture = new BABYLON.Texture(
      this.UOSData.video_side_thumbnail,
      Constants.SCENE
    );
    mat.diffuseTexture = texture;
    mat.diffuseTexture.uAng = Math.PI;
    mat.emissiveColor = new BABYLON.Color3(0.5, 0.5, 0.5);
    mesh.material = mat;
  }
/**
   *===============================================================================
   * setKiosk()               : 키오스크(좌측)
   *===============================================================================*/
   setKiosk() {
    let mesh = Constants.SCENE.getNodeByName("uni_seoul_kiosk_primitive0");
    this.setAction(mesh);
    var mat = new BABYLON.StandardMaterial("kiosk_mat", Constants.SCENE);

    let texture = new BABYLON.Texture(
      this.UOSData.kiosk_img[0],
      Constants.SCENE
    );
    mat.diffuseTexture = texture;
    mat.diffuseTexture.uAng = Math.PI;
    mat.emissiveColor = new BABYLON.Color3(0.5, 0.5, 0.5);
    mesh.material = mat;
  }
  /**
   *===============================================================================
   * setPresidentScreen()               : 총장님 인삿말 영상 (전광판, 우측)
   *===============================================================================*/
  setPresidentScreen() {
    let mesh = Constants.SCENE.getNodeByName("Screen_01");
    this.setAction(mesh);
    var mat = new BABYLON.StandardMaterial("Screen01_ad_mat", Constants.SCENE);

    let texture = new BABYLON.Texture(
      this.UOSData.video_big_thumbnail,
      Constants.SCENE
    );
    mat.diffuseTexture = texture;
    mat.diffuseTexture.uAng = Math.PI;
    mat.emissiveColor = new BABYLON.Color3(0.5, 0.5, 0.5);
    mesh.material = mat;
  }

  /**
   *===============================================================================
   * setInterective()       : 홍보물 인터렉션 설정
   *===============================================================================*/
  setInterective() {
    this.highlightLayer = new BABYLON.HighlightLayer("glow", Constants.SCENE);

    var mesh = Constants.SCENE.getMeshByName("instar_primitive0");
    this.setAction(mesh);
    mesh = Constants.SCENE.getMeshByName("instar_primitive1");
    this.setAction(mesh);

    mesh = Constants.SCENE.getMeshByName("Homepage");
    this.setAction(mesh);

    mesh = Constants.SCENE.getMeshByName("youtube");
    this.setAction(mesh);

    // 2023스펙에서 산학관 키오스크 제거에 따른 주석 처리 =====
    // mesh = Constants.SCENE.getMeshByName("uni_seoul_kiosk_primitive0");
    // this.setAction(mesh);
    // mesh = Constants.SCENE.getMeshByName("uni_seoul_kiosk_primitive1");
    // this.setAction(mesh);
    // ====================================================
  }

  /**
   *===============================================================================
   * setAction()             : 인터렉션 액션 바인딩
   *===============================================================================*/
  setAction(_mesh) {
    _mesh.actionManager = new BABYLON.ActionManager(Constants.SCENE);

    _mesh.actionManager.registerAction(
      new BABYLON.ExecuteCodeAction(
        BABYLON.ActionManager.OnPointerOverTrigger,
        (_meshEvent) => {
          if (CameraManager.currentMode === CameraManager.PLAY_MODE)
            this.highlightLayer.addMesh(_mesh, new BABYLON.Color3(0.4, 0.5, 1));
        }
      )
    );
    _mesh.actionManager.registerAction(
      new BABYLON.ExecuteCodeAction(
        BABYLON.ActionManager.OnPointerOutTrigger,
        (_meshEvent) => {
          this.highlightLayer.removeMesh(_mesh);
        }
      )
    );

    _mesh.actionManager.registerAction(
      new BABYLON.ExecuteCodeAction(
        BABYLON.ActionManager.OnPickUpTrigger,
        (_meshEvent) => {
          this.highlightLayer.removeMesh(_mesh);
          this.onClickInfo(_mesh);
        }
      )
    );
  }

  /**
   *===============================================================================
   * onClickInfo()            :  셀렉트
   * @param _objMeshData       : 인포메이션 mesh
   *===============================================================================
   */
  onClickInfo(_mesh) {
    if (CameraManager.currentMode !== CameraManager.PLAY_MODE) return;

    switch (_mesh.name) {
      case "uni_seoul_kiosk_primitive0":
        Communicator.sendEvent(
          CommunicatorEvent.OPEN_CATALOG,
          this.UOSData.kiosk_img
        );
        break;
      case "billboard":
        Communicator.sendEvent(
          CommunicatorEvent.OPEN_URL_LINK,
          this.UOSData.webinar
        );
        break;
      case "instar_primitive0":
        Communicator.sendEvent(
          CommunicatorEvent.OPEN_URL_LINK,
          this.UOSData.instagram
        );
        break;
      case "Homepage":
        Communicator.sendEvent(
          CommunicatorEvent.OPEN_URL_LINK,
          this.UOSData.homepage
        );
        break;
      case "youtube":
        Communicator.sendEvent(
          CommunicatorEvent.OPEN_URL_LINK,
          this.UOSData.youtube_live
        );
        console.log(this.UOSData.youtube_live);

        break;
      case "Screen_01": // 우측 총장말씀 스크린
        Communicator.sendEvent(
          CommunicatorEvent.OPEN_BOARDVIDEO,
          this.UOSData.obj_boardA
        );
        break;
      case "screen_02": // 좌측 산관학스크린
        Communicator.sendEvent(
          CommunicatorEvent.OPEN_BOARDVIDEO,
          this.UOSData.obj_boardB
        );
        break;
      default:
        break;
    }
  }
}