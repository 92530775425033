import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {ContainerBox} from "../../common/StyleMasterComp"
import Utils_MediaQuery from "../../common/Utils_MediaQuery";


let InputIdBox = styled.input`
    display: block;

    width: ${props => props.Width || '298px'};
    height: ${props => props.Height || '57px'};
    font-size: ${props => props.FontSize || "20px"};
    margin-bottom: ${props => props.MarginBottom || '20px'};

    font-family: 'SEBANG_Gothic_Regular';
    
    color: #FFFFFF; text-align: center; 
    letter-spacing: 1px;

    background: #013A7D;
    box-shadow: 0px 1px 15px #FFFFFF;
    border-radius: 30px;
    outline: none;
    border: none;

    pointer-events:all;
    ::placeholder {
        color: #FFFFFF;  text-align: center; 
    }
`;

let OkBtn = styled.button`
    width: ${props => props.Width || '298px'};
    height: ${props => props.Height || '57px'};
    font-size: ${props => props.FontSize || "20px"};

    display: block;

    font-family: 'SEBANG_Gothic_Regular'; 
    color: #003C8F; font-weight: 600; letter-spacing: 2px; text-align: center; 
    
    background: #FFFFFF;
    box-shadow: 0px 1px 15px rgba(255, 255, 255, 0.47);
    border-radius: 30px;
    border: none;

    cursor: pointer;
    pointer-events:all;
`;

function BottomInputSet(props){

    let [inputBoxInfo, setInputBoxInfo] = useState({ Width:"298px", Height:"57px", FontSize:"20px", MarginBottom:"20px" });
    let [okBtnInfo, setOkBtnInfo] = useState({ Width:"298px", Height:"57px", FontSize:"20px" });

    useEffect(() => {
        let CurrMediaType = Utils_MediaQuery.GetMediaDetailType(props.objWindowSize.Width,props.objWindowSize.Height);
        console.log("BottomInputSet : ",CurrMediaType);
        if(CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_S){
            setInputBoxInfo({ Width:"200px", Height:"39px", FontSize:"14px", MarginBottom:"10px" })
            setOkBtnInfo({ Width:"200px", Height:"39px", FontSize:"14px" })
        }
        else if(CurrMediaType === Utils_MediaQuery.P_M_FOLD_S){
            setInputBoxInfo({ Width:"200px", Height:"39px", FontSize:"14px", MarginBottom:"10px" })
            setOkBtnInfo({ Width:"200px", Height:"39px", FontSize:"14px" })
        }
        else if(CurrMediaType === Utils_MediaQuery.P_M_IPHONE_S){
            setInputBoxInfo({ Width:"200px", Height:"39px", FontSize:"13px", MarginBottom:"10px" })
            setOkBtnInfo({ Width:"200px", Height:"39px", FontSize:"13px" })
        }
        else if(CurrMediaType === Utils_MediaQuery.P_M_ANDROID_S){
            setInputBoxInfo({ Width:"200px", Height:"39px", FontSize:"14px", MarginBottom:"10px" })
            setOkBtnInfo({ Width:"200px", Height:"39px", FontSize:"14px" })
        }
        else if(CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_L){
            setInputBoxInfo({ Width:"200px", Height:"39px", FontSize:"14px", MarginBottom:"10px" })
            setOkBtnInfo({ Width:"200px", Height:"39px", FontSize:"14px" })
        }

        else if(CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_S){
            setInputBoxInfo({ Width:"250px", Height:"45px", FontSize:"16px", MarginBottom:"15px" })
            setOkBtnInfo({ Width:"250px", Height:"45px", FontSize:"16px" })
        }
        else if(CurrMediaType === Utils_MediaQuery.P_T_GALAXY_S){
            setInputBoxInfo({ Width:"298px", Height:"57px", FontSize:"20px", MarginBottom:"20px" })
            setOkBtnInfo({ Width:"298px", Height:"57px", FontSize:"20px" })
        }
        else if(CurrMediaType === Utils_MediaQuery.P_T_IPAD_S){
            setInputBoxInfo({ Width:"298px", Height:"57px", FontSize:"20px", MarginBottom:"20px" })
            setOkBtnInfo({ Width:"298px", Height:"57px", FontSize:"20px" })
        }
        else if(CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_L){
            setInputBoxInfo({ Width:"270px", Height:"57px", FontSize:"19px", MarginBottom:"20px" })
            setOkBtnInfo({ Width:"270px", Height:"57px", FontSize:"19px" })
        }

        else if(CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_S){
            setInputBoxInfo({ Width:"350px", Height:"67px", FontSize:"26px", MarginBottom:"20px" })
            setOkBtnInfo({ Width:"350px", Height:"67px", FontSize:"26px" })
        }
        else if(CurrMediaType === Utils_MediaQuery.P_D_DESKTOP_S){
            setInputBoxInfo({ Width:"350px", Height:"67px", FontSize:"26px", MarginBottom:"20px" })
            setOkBtnInfo({ Width:"350px", Height:"67px", FontSize:"26px" })
        }
        else if(CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_L){
            setInputBoxInfo({ Width:"350px", Height:"67px", FontSize:"26px", MarginBottom:"20px" })
            setOkBtnInfo({ Width:"350px", Height:"67px", FontSize:"26px" })
        }

        else if(CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_S){
            setInputBoxInfo({ Width:"141px", Height:"25px", FontSize:"9px", MarginBottom:"6px" })
            setOkBtnInfo({ Width:"141px", Height:"25px", FontSize:"9px" })  
        }
        else if(CurrMediaType === Utils_MediaQuery.L_M_FOLD_S){
            setInputBoxInfo({ Width:"139px", Height:"25px", FontSize:"9px", MarginBottom:"6px" })
            setOkBtnInfo({ Width:"139px", Height:"25px", FontSize:"9px" })  
        }
        else if(CurrMediaType === Utils_MediaQuery.L_M_IPHONE_S){
            setInputBoxInfo({ Width:"150px", Height:"27px", FontSize:"10px", MarginBottom:"8px" })
            setOkBtnInfo({ Width:"150px", Height:"27px", FontSize:"10px" })  
        }
        else if(CurrMediaType === Utils_MediaQuery.L_M_ANDROID_S){
            setInputBoxInfo({ Width:"150px", Height:"29px", FontSize:"10px", MarginBottom:"8px" })
            setOkBtnInfo({ Width:"150px", Height:"29px", FontSize:"10px" }) 
        }
        else if(CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_L){
            setInputBoxInfo({ Width:"148px", Height:"28px", FontSize:"9px", MarginBottom:"8px" })
            setOkBtnInfo({ Width:"148px", Height:"28px", FontSize:"9px" })  
        }

        else if(CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_S){
            setInputBoxInfo({ Width:"200px", Height:"39px", FontSize:"14px", MarginBottom:"10px" })
            setOkBtnInfo({ Width:"200px", Height:"39px", FontSize:"14px" })
        }
        else if(CurrMediaType === Utils_MediaQuery.L_T_GALAXY_S){
            setInputBoxInfo({ Width:"200px", Height:"39px", FontSize:"14px", MarginBottom:"10px" })
            setOkBtnInfo({ Width:"200px", Height:"39px", FontSize:"14px" })
        }
        else if(CurrMediaType === Utils_MediaQuery.L_T_IPAD_S){
            setInputBoxInfo({ Width:"200px", Height:"39px", FontSize:"14px", MarginBottom:"10px" })
            setOkBtnInfo({ Width:"200px", Height:"39px", FontSize:"14px" })
        }
        else if(CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_L){
            setInputBoxInfo({ Width:"220px", Height:"44px", FontSize:"15px", MarginBottom:"10px" })
            setOkBtnInfo({ Width:"220px", Height:"44px", FontSize:"15px" })
        }

        else if(CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_S){
            setInputBoxInfo({ Width:"298px", Height:"57px", FontSize:"20px", MarginBottom:"20px" })
            setOkBtnInfo({ Width:"298px", Height:"57px", FontSize:"20px" })
        }
        else if(CurrMediaType === Utils_MediaQuery.L_D_DESKTOP_S){
            setInputBoxInfo({ Width:"298px", Height:"57px", FontSize:"20px", MarginBottom:"20px" })
            setOkBtnInfo({ Width:"298px", Height:"57px", FontSize:"20px" })
        }
        else if(CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_L){
            setInputBoxInfo({ Width:"298px", Height:"57px", FontSize:"20px", MarginBottom:"20px" })
            setOkBtnInfo({ Width:"298px", Height:"57px", FontSize:"20px" })
        }
    },[props.objWindowSize])

    return(
        <ContainerBox>
            <InputIdBox Width={inputBoxInfo.Width} Height={inputBoxInfo.Height}
                FontSize={inputBoxInfo.FontSize} MarginBottom={inputBoxInfo.MarginBottom}
                maxLength="10" placeholder="닉네임을 입력해주세요."
                onFocus={(e) => { e.target.placeholder = "" }}
                onBlur={(e) => { e.target.placeholder = "닉네임을 입력해주세요." }}
                onChange={(e) => { props.setInputData(e.target.value) }}
                onKeyPress={props.onKeyPress}
                value={props.inputData}
                style={{color:'darkgray'}}
            />
            <OkBtn Width={okBtnInfo.Width} Height={okBtnInfo.Height}
                FontSize={okBtnInfo.FontSize}
                type="button" onClick={() => {
                    if (props.inputData === "") {
                        window.alert("닉네임을 입력해주세요.");
                        return;
                    }
                    props.onPushOkButton();
                }}>입장하기</OkBtn>
        </ContainerBox>
    );
}

export default BottomInputSet;