/**
 @Class          : Booth
 @Maker          : 윤휘원 (박정훈 팀장님 작업 상속)
 @Date           : 2022-01-05
 @Debug DATE     : 
 @Debug Contents : -
 @Version        : 1.0
 @Description    : 부스 클래스
 */

import * as BABYLON from 'babylonjs';
import gsap from "gsap";
import Constants from '../common/Constants';
import EventDispatcher from '../common/event/EventDispatcher';
import CameraManager from './CameraManager';
import ShadowManager from './ShadowManager';
import NavimeshManager from './NavimeshManager';
import CommunicatorEvent from '../common/event/CommunicatorEvent';
import Communicator from '../common/Communicator';
import DataManager from '../common/data/DataManager';

export default class Booth extends EventDispatcher {
    'use strict';
    static SETTING_COMPLETE = "Setting_Complete";

    /**
     *===============================================================================
     * constructor()      :
     * @param _objData :  
     * @param _meshBooth :  
     *===============================================================================
     */
    constructor(_objData, _meshBooth) {
        super();

        this.objCompanyData = _objData
        this.meshBooth = _meshBooth;
        this.isIntersects = false;
        this.videoVidTex = null;
        this.strBaseURL = null;
        this.highlightLayer = null;
        this.glowLayer = null;
        this.timeOut = null;
        this.linesBooth = null;
        this.linesBooth2 = null;
        this.linesBooth3 = null;
        this.tweenIn = null;
        this.tweenOut = null;

        this.init();
    }

    /**
     *===============================================================================
     * dispose()             :
     *===============================================================================*/
    dispose() {
        clearTimeout(this.timeOut);
        this.highlightLayer.dispose();
        this.highlightLayer = null;
        this.meshBooth.dispose();
        this.meshBooth = null;
    }

    /**
     *===============================================================================
     * init()             :
     *===============================================================================*/
    init() {
        Constants.CONTAINER_MAP.addChild(this.meshBooth);

        this.strBaseURL = "";

        this.highlightLayer = new BABYLON.HighlightLayer("highlight", Constants.SCENE);
        this.glowLayer = new BABYLON.GlowLayer("glow", Constants.scene);
        this.glowLayer.intensity = .5;
        this.glowLayer.isEnabled = false;

        this.setIntersectsBox();
        this.setBooth();

        if (Constants.OS_PLATFORM === Constants.OS_ECT) this.setShadow();
        //this.setNavimesh();
        this.setInterective();

        Constants.SCENE.onBeforeAnimationsObservable.add(this.onCheckIntersects.bind(this));

        setTimeout(() => {
            // this.dispatchEvent({
            //     type: Booth.SETTING_COMPLETE,
            //     data: null
            // })
        }, 200);
    }

    /**
     *===============================================================================
     * setIntersectsBox()             :  부스 진입시 충돌체크 객체 설정
     *===============================================================================*/
    setIntersectsBox() {
        this.IntersectsBox = BABYLON.MeshBuilder.CreateBox("Booth_IntersectsBox", {
            size: 6,
            height: 4
        }, Constants.scene);
        this.IntersectsBox.position.y = 0;
        this.IntersectsBox.isVisible = false;
        this.meshBooth.addChild(this.IntersectsBox);

        const myPoints = [
            new BABYLON.Vector3(-3, 0, -3),
            new BABYLON.Vector3(-3, 0, 3),
            new BABYLON.Vector3(3, 0, 3),
            new BABYLON.Vector3(3, 0, -3),
            new BABYLON.Vector3(-3, 0, -3)
        ]

        const myColors = [
            new BABYLON.Color4(1, 0, 0, 1)
        ]

        this.linesBooth = BABYLON.MeshBuilder.CreateLines("lines1", {
            points: myPoints
        });
        this.linesBooth.alpha = .5;
        this.linesBooth.position.y = 3;
        this.linesBooth.isVisible = false;

        this.linesBooth2 = BABYLON.MeshBuilder.CreateLines("lines2", {
            points: myPoints
        });
        this.linesBooth2.alpha = .5;
        this.linesBooth2.position.y = 3;
        this.linesBooth2.isVisible = false;

        this.linesBooth3 = BABYLON.MeshBuilder.CreateLines("lines3", {
            points: myPoints
        });
        this.linesBooth3.alpha = .5;
        this.linesBooth3.position.y = 3;
        this.linesBooth3.isVisible = false;

        this.meshBooth.addChild(this.linesBooth);
        this.meshBooth.addChild(this.linesBooth2);
        this.meshBooth.addChild(this.linesBooth3);
    }

    /**
     *===============================================================================
     * setShadow()             : 부스 그림자 설정
     *===============================================================================*/
    setShadow() {
        ShadowManager.AddShadowMap(this.meshBooth);

        let meshReceive = Constants.SCENE.getNodeByName(this.meshBooth.name + ".booth.booth_floor");
        ShadowManager.RemoveCast(meshReceive);  // 바닥 스스로에게 받는 그림자 제거를 위해 추가
        ShadowManager.AddReceive(meshReceive);
    }

    /**
     *===============================================================================
     * setInterective()       : 홍보물 인터렉션 설정
     *===============================================================================*/
    setInterective() {
        var mesh = Constants.SCENE.getMeshByName(this.meshBooth.name + ".booth.booth_kiosk");
        mesh.name = this.meshBooth.name + "_kiosk";
        this.setAction(mesh);

        if (this.objCompanyData.homepage !== "") {
            mesh = Constants.SCENE.getMeshByName(this.meshBooth.name + ".booth.booth_homepage");
            mesh.name = this.meshBooth.name + "_homepage";
            this.setAction(mesh);
        }
        if (this.objCompanyData.sns !== "") {
            mesh = Constants.SCENE.getMeshByName(this.meshBooth.name + ".booth.booth_SNS");
            mesh.name = this.meshBooth.name + "_sns";
            this.setAction(mesh);
        }

        if (this.objCompanyData.video_url !== "") {
            mesh = Constants.SCENE.getMeshByName(this.meshBooth.name + ".booth.booth_screen");
            mesh.name = this.meshBooth.name + "_video";
            this.setAction(mesh);
        }

        // pdf 파일이 없을 경우 액션 연결하지 않도록 설정
        if (this.objCompanyData.ir_doc !== "") {
            mesh = Constants.SCENE.getMeshByName(this.meshBooth.name + ".booth.booth_disket");
            mesh.name = this.meshBooth.name + "_disket";
            this.setAction(mesh);
        }
    }

    /**
     *===============================================================================
     * setAction()             : 인터렉션 액션 바인딩
     *===============================================================================*/
    setAction(_mesh) {
        _mesh.isPickable = true;
        _mesh.actionManager = new BABYLON.ActionManager(Constants.SCENE);
        _mesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, (_meshEvent) => {
            if (this.isIntersects) this.highlightLayer.addMesh(_mesh, new BABYLON.Color3(.4, .5, 1));
        }));
        _mesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, (_meshEvent) => {
            this.highlightLayer.removeMesh(_mesh);
        }));

        _mesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickUpTrigger, (_meshEvent) => {
            this.highlightLayer.removeMesh(_mesh);
            this.onClickInfo(_mesh);
        }));
    }

    /**
     *===============================================================================
     * onClickInfo()            :  셀렉트
     * @param _objMeshData       : 인포메이션 mesh
     *===============================================================================
     */
    onClickInfo(_mesh) {
        if (!this.isIntersects) return;

        switch (_mesh.name) {
            case this.meshBooth.name + "_kiosk":
                Communicator.sendEvent(CommunicatorEvent.OPEN_CATALOG, this.objCompanyData.catalog_images);;
                break;
            case this.meshBooth.name + "_homepage":
                Communicator.sendEvent(CommunicatorEvent.OPEN_URL_LINK, this.objCompanyData.homepage);
                break;
            case this.meshBooth.name + "_sns":
                Communicator.sendEvent(CommunicatorEvent.OPEN_URL_LINK, this.objCompanyData.sns);
                break;
            case this.meshBooth.name + "_video":
                Communicator.sendEvent(CommunicatorEvent.OPEN_VIDEO, this.objCompanyData.video_url);
                break;
            case this.meshBooth.name + "_disket":
                Communicator.sendEvent(CommunicatorEvent.OPEN_PDF, this.objCompanyData.ir_doc);
                break;
            default:
                break;
        }
    }

    /**
     *===============================================================================
     * onCheckIntersects()         :  아바타 부스 진입 여부 확인
     *===============================================================================
     */
    onCheckIntersects() {
        if (Constants.AVATAR===null || Constants.AVATAR.mesh === null) return;
        if (CameraManager.cameraMode === CameraManager.MINI_MAP_MODE) return;
        if (Constants.AVATAR.mesh.intersectsMesh(this.IntersectsBox, true)) {
            if (!this.isIntersects) {
                // 진입한 부스정보
                let companyName = DataManager.m_arrCompanyDatas.filter(
                    element => element.id == Number(this.IntersectsBox.parent.name.replace("booth_", "")) + 1
                )
                console.log(companyName[0]);
                // 커뮤니케이터로 퍼블리싱쪽에 알려주기 
                Communicator.sendEvent(CommunicatorEvent.ENTER_BOOTH, companyName[0]);

                gsap.killTweensOf(this.linesBooth.position);
                gsap.killTweensOf(this.linesBooth2.position);
                gsap.killTweensOf(this.linesBooth3.position);
                this.isIntersects = true;

                this.linesBooth.isVisible = true;
                this.linesBooth.position.y = 3;

                this.linesBooth2.isVisible = true;
                this.linesBooth2.position.y = 2;

                this.linesBooth3.isVisible = true;
                this.linesBooth3.position.y = 1;

                this.glowLayer.addIncludedOnlyMesh(this.linesBooth);
                this.glowLayer.addIncludedOnlyMesh(this.linesBooth2);
                this.glowLayer.addIncludedOnlyMesh(this.linesBooth3);
                this.glowLayer.isEnabled = true;

                this.glowLayer.customEmissiveColorSelector = (mesh, subMesh, material, result) => {
                    if (mesh === this.linesBooth) {
                        result.r = 0;
                        result.g = 0;
                        result.b = 1;
                    }
                    if (mesh === this.linesBooth2) {
                        result.r = 0;
                        result.g = 0;
                        result.b = 1;
                    }
                    if (mesh === this.linesBooth3) {
                        result.r = 0;
                        result.g = 0;
                        result.b = 1;
                    }
                }

                //this.highlightLayer.addMesh(this.linesBooth,  new BABYLON.Color3(1, 1, 1));
                gsap.to(this.linesBooth.position, {
                    duration: 1.5,
                    y: 0,
                    onComplete: () => {
                        CameraManager.cameraMode = CameraManager.BOOTH_MODE;
                        this.glowLayer.isEnabled = false;
                    }
                })

                gsap.to(this.linesBooth2.position, {
                    duration: 1.5,
                    y: 0
                })

                gsap.to(this.linesBooth3.position, {
                    duration: 1.5,
                    y: 0
                })

                // this.videoVidTex.video.play();
            }
        } else {
            if (this.isIntersects) {
                gsap.killTweensOf(this.linesBooth.position);
                this.glowLayer.isEnabled = false;
                CameraManager.cameraMode = CameraManager.PLAY_MODE;
                this.isIntersects = false;

                //this.highlightLayer.removeMesh(this.linesBooth);
                this.linesBooth.isVisible = false;
                this.linesBooth.position.y = 3;

                this.linesBooth2.isVisible = false;
                this.linesBooth2.position.y = 2;

                this.linesBooth3.isVisible = false;
                this.linesBooth3.position.y = 1;

                //this.videoVidTex.video.pause();
            }
        }
    }

    /**
     *===============================================================================
     * setBooth()             : 부스 셋팅
     *===============================================================================*/
    setBooth() {
        let newPoint = this.rotateCoordinate(this.objCompanyData.position, 180, {
            x: 0,
            z: 0
        })

        this.meshBooth.position.x = newPoint.x;
        this.meshBooth.position.y = 0; //this.objCompanyData.position.y;
        this.meshBooth.position.z = newPoint.z;
        this.objCompanyData.positionNew = {
            x: this.meshBooth.position.x,
            y: this.meshBooth.position.y,
            z: this.meshBooth.position.z
        };

        this.setLogo();
        this.setVideo();
        this.setKiosk();
    }

    /**
     *===============================================================================
     * rotateCoordinate()     : 테넌트 좌표 이동 - MAX와 Babylon 좌표계 보정을 위함
     * @param _position       : 좌표계를 가진 객체 - 이동을 원하는 좌표
     * @param _nDegree        : 이동할 회전각
     * @param _objCenter      : 중심점 좌표 객체
     * @return                : 이동 후 좌표 객체
     *===============================================================================*/
    rotateCoordinate(_position, _nDegree, _objCenter) {
        let objReturn = {};

        objReturn.x = (_position.x - _objCenter.x) * Math.cos(_nDegree * Math.PI / 180) - (_position.z - _objCenter.z) * Math.sin(_nDegree * Math.PI / 180) + _objCenter.x;
        objReturn.z = (_position.x - _objCenter.x) * Math.sin(_nDegree * Math.PI / 180) + (_position.z - _objCenter.z) * Math.cos(_nDegree * Math.PI / 180) + _objCenter.z;

        return objReturn;
    }

    /**
     *===============================================================================
     * setLogo()             : 업체 로고 셋팅 - 배너 및 월
     *===============================================================================*/
    setLogo() {
        var mat = new BABYLON.StandardMaterial("logo_" + this.objCompanyData.name, Constants.SCENE);
        mat.diffuseTexture = new BABYLON.Texture(this.strBaseURL + this.objCompanyData.logo_image, Constants.SCENE);

        mat.diffuseTexture.wAng = Math.PI;
        mat.diffuseTexture.vAng = Math.PI;
        let strName = this.meshBooth.name + ".booth.booth_Logo";

        let meshLogoBoard = Constants.SCENE.getNodeByName(strName);
        meshLogoBoard.material = mat;

        // 부스 모델링 변경에 따라 부스 벽 설정이 불필요해져 주석 처리
        // strName = this.meshBooth.name + ".booth.booth_wall.booth_wall_primitive1";
        // let meshWall = Constants.SCENE.getNodeByName(strName);

        // meshWall.material = new BABYLON.StandardMaterial("BoothWall", Constants.SCENE);
        // meshWall.material.diffuseColor = new BABYLON.Color3.FromHexString("#9F7751");
        // meshWall.material.emissiveColor = new BABYLON.Color3.FromHexString("#342B25") 
    }

    /**
     *===============================================================================
     * setVideo()             : 비디오 홍보물 썸네일 설정
     *===============================================================================*/
    setVideo() {
        // let strName = this.meshBooth.name + ".booth_main.booth_Video";
        console.log(this.meshBooth.name);
        let strName = this.meshBooth.name + ".booth.booth_screen";
        let meshVideo = Constants.SCENE.getNodeByName(strName);
        var mat = new BABYLON.StandardMaterial("video_" + this.objCompanyData.name, Constants.SCENE);

        //if(Constants.OS_PLATFORM === Constants.OS_IOS || Constants.OS_PLATFORM === Constants.OS_ANDROID){

        let texture = new BABYLON.Texture(this.strBaseURL + this.objCompanyData.video_thumbnail, Constants.SCENE);

        // 동영상 URL이 없을 경우 동영상 텍스처 처리
        // if (this.objCompanyData.video_url === "")
        //     texture = new BABYLON.Texture(this.strBaseURL + this.objCompanyData.logo_image, Constants.SCENE);
        
        mat.diffuseTexture = texture;
        mat.diffuseTexture.uAng = Math.PI;
        mat.emissiveColor = new BABYLON.Color3(.7, .7, .7);
        meshVideo.material = mat;

        /*         }else{
                    this.videoVidTex = new BABYLON.VideoTexture("video", this.strBaseURL + this.objCompanyData.video_url, Constants.SCENE, true);
                    mat.diffuseTexture = this.videoVidTex;
                    this.videoVidTex.video.pause();
                    this.videoVidTex.video.volume = 0;

                } */
    }

    /**
     *===============================================================================
     * setKiosk()             : 이미지 홍보물 썸네일 설정
     *===============================================================================*/
    setKiosk() {
        var mat = new BABYLON.StandardMaterial("kiosk_" + this.objCompanyData.name, Constants.SCENE);
        mat.diffuseTexture = new BABYLON.Texture(this.strBaseURL + this.objCompanyData.catalog_images[0], Constants.SCENE);
        mat.diffuseTexture.uAng = Math.PI;
        mat.diffuseTexture.level = 2;

        let strName = this.meshBooth.name + ".booth.booth_kiosk";
        let meshKiosk = Constants.SCENE.getNodeByName(strName);
        meshKiosk.material = mat;
        meshKiosk.material.diffuseColor = new BABYLON.Color3(.5, .5, .5);
    }
}