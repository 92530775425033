import React from 'react';
import ReactDOM from 'react-dom';
import App from './ui/App';
import "./css/font.css";
import WebFont from "webfontloader";
import World from './content/BasicWorld';
import DataManager from "./common/data/DataManager";
import Communicator from './common/Communicator';
import Constants from './common/Constants';
import {BrowserRouter, RouterProvider, createBrowserRouter} from 'react-router-dom';
import Constants_ui from './ui/common/Constants_ui';
import SMGDeepLink from './ui/SMGDeepLink';

window.addEventListener('load', function () {
    window.removeEventListener('load', this);
    Constants.DEBUG_MODE = process.env.REACT_APP_DDEVELOP_MODE === "TRUE";
    Constants.MULTI_SERVER_URL.url = process.env.REACT_APP_MULTI_SERVER_URL;  
    Constants.MULTI_SERVER_URL.port = process.env.REACT_APP_MULTI_SERVER_PORT;  
    Constants_ui.TESTMODE = Constants.DEBUG_MODE;
	Init();
});


const Init =() =>{
    if(!Constants.DEBUG_MODE) console.log = () =>{};    // 디버그모드일 때 로그 삭제

    console.log("::: FONT LOAD START :::");

    WebFont.load({
            custom: {
            families: ['Spoqa HanSansNeo', 'SEBANG_Gothic_Regular']
            },
            /* fontloading: function(name, fvd) {
                console.log(`fontloading ${name}:${fvd}`);
            }, */
            fontactive: (name, fvd) => {
                console.log(`::: LOADING FONT ${name}:${fvd} :::`);
            },
            /* fontinactive: function(name, fvd) {
                console.log(`fontinactive ${name}:${fvd}`);
            }, */
            active: () => {
                console.log("::: ALL FONTS LOADED :::");
                startApp();
            },
            
            inactive: () => {
                console.error("::: ERROR LOADING FONTS :::");
                // REPORT ERROR here
            },
    }); 
}

let world = null;

const startApp = () =>{
    console.log("::: START APP ::::");

    if( /Android/i.test(navigator.userAgent)) {
        Constants.OS_PLATFORM = Constants.OS_ANDROID;
    }else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        Constants.OS_PLATFORM = Constants.OS_IOS;
    }else {
       Constants.OS_PLATFORM = Constants.OS_ECT;
    }

    Communicator.GetInstance();
    DataManager.GetInstance();
    DataManager.LoadData(onDataLoadComplete, onDataLoadFail);
}

const onDataLoadFail = () =>{
    
}


const onDataLoadComplete = () =>{
    console.log("::: DATALOAD COMPLETE ::: ");
    reactStart();
    const canvas = document.getElementById('World');
    world = new World(canvas);
    world.addEventListener("WOLD_SETTING_COMPLETE", worldSettingComplete);
    world.addEventListener("WORLD_LOADING", worldLoading);
}



const worldLoading = (_ev) =>{
    
}

const worldSettingComplete = (_ev) =>{
    
}



const router = createBrowserRouter(
[
    {
        path: "/",
        element: <App />,
        // loader: rootLoader,
        children: [
        ],
    },
    {
        path: "/link/:seqnum",
        element: <SMGDeepLink />,
        // loader: teamLoader,
    },
]);
const reactStart = ()=>{
    ReactDOM.render(
        <React.StrictMode>
            <RouterProvider router={router} fallbackElement={<App />}/>
            {/* <BrowserRouter 
                <App />
            </BrowserRouter> */}
        </React.StrictMode>,
        document.getElementById('root')
    );
}

