import React from "react";
import styled from "styled-components";
import {ContainerBox} from "../../common/StyleMasterComp"

import {BoothListCard} from './'
import Constants from "../../../common/Constants";

let ListContentArea = styled.div`
    pointer-events:all;
    margin:0; padding:0; margin-top:0;

    width: ${props=>props.Width ||"768px"}; 
    height: ${props=>props.Height ||"470px"};

    list-style-type: none;
    margin: 0; padding: 0;
    overflow-y:scroll;
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba(136,136,136,1);
    }
    ::-webkit-scrollbar { 
        border-radius: 10px;
        width:5px;
        background-color: rgba(0,0,0,0.3);
    }
    text-align: left;
    box-sizing:border-box;
    color:#000;
`;

let MdListDiv = styled.div`
    height:80px;
`;

function ListContents(props){
    // KDG
    // let returnData=[];
    let returnData=Constants.DEEP_LINK_LIST;

    let arrUseBoothData = () =>{
        props.arrBoothData.map((data) => {
            if (props.Selected ==="전체 부스"){
                returnData.push(data);
            } else if (props.Selected === "구로" && data.branch === 1) {
                returnData.push(data);
            } else if (props.Selected === "마포" && data.branch === 2) {
                returnData.push(data);
            }
            return null;
        })
    }

    arrUseBoothData();

    return(
        <ContainerBox>
            <ListContentArea Height={props.ListHeight} 
                Width={props.ListWidth} >
                <MdListDiv>
                    {
                        returnData.map((data, i) => {
                            return (
                                <BoothListCard
                                    data={data}
                                    funcSendTenant={props.funcSendTenant}
                                    objWindowSize={props.objWindowSize}
                                    key={i}
                                />
                            )
                        })
                    }
                </MdListDiv>
            </ListContentArea>
        </ContainerBox>
    );
}

export default ListContents;