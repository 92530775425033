import React, { useState, useEffect} from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";

import IcoClose from '../images/ico_close.png';
import Utils_MediaQuery from "../common/Utils_MediaQuery";

let ContainerBox = styled.div`
    position: fixed;
    left: 0;
    top:0;
    pointer-events:all;    
    height:100vh;
    width:100vw;
    z-index: 1000;
`;

let BlackBgBox = styled.div`
    position: fixed;
    left: 0;
    top:0;
    pointer-events:all;    
    height:100vh;
    width:100vw;
    background-color: black;
    opacity: 0.9;
    z-index: 1100;
`;


let MovieContentsBoxContainer = styled.div`
    position: absolute;
    left:${props=> props.Left || "calc(50vw - (768px / 2))"};
    top:${props=> props.Top ||"calc(50vh - (762px / 2))"};
    z-index: 1300;
`;

let BtnClose = styled.div`
    pointer-events:all;
    cursor: pointer;
    position: fixed;
    z-index: 1300;
    right: ${props => props.Right};
    top: ${props => props.top};
    width:${props => props.width};
    height:${props => props.height};
    background-image: url(${props => props.ImgURL});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
`;


function ModalMovie(props){

    let [movieSize, setMovieSize] = useState({ Width:"1280px", Height:"720px" });
    let [closeImgSize, setCloseImgSize] = useState({ Width:"29px", Height:"28px" });

    useEffect(() => {
        let CurrMediaType = Utils_MediaQuery.GetMediaDetailType(props.objWindowSize.Width,props.objWindowSize.Height);
        // console.log("ModalMovie : ",CurrMediaType);
        if(CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_M_FOLD_S){
            setMovieSize({ Width:"320px", Height:"180px" });
            setCloseImgSize({ Width:"30px", Height:"30px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.P_M_IPHONE_S){
            setMovieSize({ Width:"375px", Height:"211px" });
            setCloseImgSize({ Width:"30px", Height:"30px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.P_M_ANDROID_S ||
            CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_L){
            setMovieSize({ Width:"412px", Height:"232px" });
            setCloseImgSize({ Width:"30px", Height:"30px" });
        }
        
        else if(CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_T_GALAXY_S){
            setMovieSize({ Width:"753px", Height:"424px" });
            setCloseImgSize({ Width:"30px", Height:"30px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.P_T_IPAD_S ||
            CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_L){
            setMovieSize({ Width:"744px", Height:"419px" });
            setCloseImgSize({ Width:"30px", Height:"30px" });
        }
        
        else if(CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_D_DESKTOP_S ||
            CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_L){
            setMovieSize({ Width:"937px", Height:"527px" });
            setCloseImgSize({ Width:"30px", Height:"30px" });
        }
        
        else if(CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.L_M_FOLD_S){
            setMovieSize({ Width:"427px", Height:"240px" });
            setCloseImgSize({ Width:"13px", Height:"13px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.L_M_IPHONE_S){
            setMovieSize({ Width:"567px", Height:"319px" });
            setCloseImgSize({ Width:"13px", Height:"13px" });
        }
        else if(CurrMediaType === Utils_MediaQuery.L_M_ANDROID_S ||
            CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_L){
            setMovieSize({ Width:"590px", Height:"332px" });
            setCloseImgSize({ Width:"13px", Height:"13px" });
        }
        
        else if(CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.L_T_GALAXY_S ||
            CurrMediaType === Utils_MediaQuery.L_T_IPAD_S ||
            CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_L){
            setMovieSize({ Width:"1000px", Height:"563px" });
            setCloseImgSize({ Width:"20px", Height:"20px" });
        }
        
        else if(CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.L_D_DESKTOP_S ||
            CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_L){
            setMovieSize({ Width:"1666px", Height:"937px" });
            setCloseImgSize({ Width:"30px", Height:"30px" });
        }
    },[props.objWindowSize])
    console.log("rops.strVideoUrl",props.strVideoUrl)
    return(
        <ContainerBox>
            <BlackBgBox/>
            <BtnClose Right="1vw" top="1vw" width={closeImgSize.Width} height={closeImgSize.Height} ImgURL={IcoClose} onClick={() => {
                props.funcSetOpenVideo(false);
                props.funcSetPlaying(true);
            }} />
            <MovieContentsBoxContainer  Top={"calc("+ props.objWindowSize.Height/2+"px - (" + movieSize.Height + " / 2))" }
                Left={"calc("+ props.objWindowSize.Width/2+"px - (" + movieSize.Width + " / 2))" }>
                <ReactPlayer width={movieSize.Width} height={movieSize.Height}
                    url={props.strVideoUrl} playing controls/>
            </MovieContentsBoxContainer>
        </ContainerBox>
    )
}

export default ModalMovie;