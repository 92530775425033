export default class Utils_MediaQuery
{
    'use strict';

    static L_M_DEFAULT_S = "L_M_Default_S"; 
    static L_M_DEFAULT_L = "L_M_Default_L"; 
    static L_M_FOLD_S = "L_M_Fold_S";       
    static L_M_IPHONE_S = "L_M_IPhone_S";   
    static L_M_ANDROID_S = "L_M_Android_S"; 

    static L_T_DEFAULT_S = "L_T_Default_S"; 
    static L_T_DEFAULT_L = "L_T_Default_L"; 
    static L_T_IPAD_S = "L_T_IPad_S";       
    static L_T_GALAXY_S = "L_T_Galaxy_S";   
    
    static L_D_DEFAULT_S = "L_D_Default_S";   
    static L_D_DEFAULT_L = "L_D_Default_L";   
    static L_D_DESKTOP_S = "L_D_Desktop_S";   

    static P_M_DEFAULT_S = "P_M_Default_S"; 
    static P_M_DEFAULT_L = "P_M_Default_L"; 
    static P_M_FOLD_S = "P_M_Fold_S";       
    static P_M_IPHONE_S = "P_M_IPhone_S";   
    static P_M_ANDROID_S = "P_M_Android_S"; 

    static P_T_DEFAULT_S = "P_T_Default_S"; 
    static P_T_DEFAULT_L = "P_T_Default_L"; 
    static P_T_IPAD_S = "P_T_IPad_S";       
    static P_T_GALAXY_S = "P_T_Galaxy_S";   
    
    static P_D_DEFAULT_S = "P_D_Default_S";   
    static P_D_DEFAULT_L = "P_D_Default_L";   
    static P_D_DESKTOP_S = "P_D_Desktop_S";   
    
    /**===============================================================================
    * GetMediaDetailType()      : 받은 화면 크기를 지정 된 세부 미디어 타입으로 반환
    * @param width              : 화면 넓이 값(px제외한 숫자만)
    * @param height             : 화면 높이 값(px제외한 숫자만)
    * @returns string           : sample : P_D_Default_L  (화면방향 P,L)_(기기타입 M,T,D)_(기기명)_(사이즈 S,M,L)
    *===============================================================================*/
    static GetMediaDetailType (width, height) 
    {
        let returnType="";

        if (width <= height) {//PortraitScreen
            if (width <= 500){
                returnType = Utils_MediaQuery.P_M_DEFAULT_S;
                if (width <= 320 && height <= 752){//fold
                    returnType = Utils_MediaQuery.P_M_FOLD_S;
                }else if(width <= 375 && height <= 640){//iphone
                    returnType = Utils_MediaQuery.P_M_IPHONE_S;
                }else if(width <= 412 && height <= 725){//android
                    returnType = Utils_MediaQuery.P_M_ANDROID_S;
                }else if(width >= 412 ){
                    returnType = Utils_MediaQuery.P_M_DEFAULT_L;
                }
                return returnType;
            }else if (width <= 936){
                returnType = Utils_MediaQuery.P_T_DEFAULT_S;
                if(width <= 744 && height >= 1014){//ipad M
                    returnType = Utils_MediaQuery.P_T_IPAD_S;
                }else if(width <= 753 && height >= 1037){//Tablet
                    returnType = Utils_MediaQuery.P_T_GALAXY_S;
                }else if(width > 753){
                    returnType = Utils_MediaQuery.P_T_DEFAULT_L;
                }
                return returnType;
            }else if(width >= 937){
                returnType = Utils_MediaQuery.P_D_DEFAULT_S;
                if (width <= 1080 && height >= 1777) {//Desktop
                    returnType = Utils_MediaQuery.P_D_DESKTOP_S;
                }else if(width > 1080){
                    returnType = Utils_MediaQuery.P_D_DEFAULT_L;
                }
                return returnType;
            }
        } else {//LandscapeScreen
            if (height <= 450) {
                returnType = Utils_MediaQuery.L_M_DEFAULT_S;
                if (height <= 240 && width <= 808) {//fold-land
                    returnType = Utils_MediaQuery.L_M_FOLD_S;
                }else if (height <= 325 && width <= 724) {//iphone-land
                    returnType = Utils_MediaQuery.L_M_IPHONE_S;
                }else if (height <= 360 && width <= 781) {//android-land
                    returnType = Utils_MediaQuery.L_M_ANDROID_S;
                }else if (height > 360){
                    returnType = Utils_MediaQuery.L_M_DEFAULT_L;
                }
                return returnType;
            }else if (height <= 936) {
                returnType = Utils_MediaQuery.L_T_DEFAULT_S;
                if (height <= 586 && width >= 1206) {//Tablet-land
                    returnType = Utils_MediaQuery.L_T_GALAXY_S;
                }else if (height <= 625 && width >= 1133) {//ipad M-land
                    returnType = Utils_MediaQuery.L_T_IPAD_S;
                }else if (height > 625) {
                    returnType = Utils_MediaQuery.L_T_DEFAULT_L;
                }
                return returnType;
            }else if (height >= 937) {
                returnType = Utils_MediaQuery.L_D_DEFAULT_S;
                if (height <= 1080 && width >= 1920) {//Desktop-land
                    returnType = Utils_MediaQuery.L_D_DESKTOP_S;
                }else if(height > 1080){
                    returnType = Utils_MediaQuery.L_D_DEFAULT_L;
                }
                return returnType;
            }
        }
    };
};

/*
let CurrMediaType = Utils_MediaQuery.GetMediaDetailType(props.objWindowSize.Width,props.objWindowSize.Height);
console.log("페이지명 : ",CurrMediaType);
if(CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_S){}
else if(CurrMediaType === Utils_MediaQuery.P_M_FOLD_S){}
else if(CurrMediaType === Utils_MediaQuery.P_M_IPHONE_S){}
else if(CurrMediaType === Utils_MediaQuery.P_M_ANDROID_S){}
else if(CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_L){}

else if(CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_S){}
else if(CurrMediaType === Utils_MediaQuery.P_T_GALAXY_S){}
else if(CurrMediaType === Utils_MediaQuery.P_T_IPAD_S){}
else if(CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_L){}

else if(CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_S){}
else if(CurrMediaType === Utils_MediaQuery.P_D_DESKTOP_S){}
else if(CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_L){}

else if(CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_S){}
else if(CurrMediaType === Utils_MediaQuery.L_M_FOLD_S){}
else if(CurrMediaType === Utils_MediaQuery.L_M_IPHONE_S){}
else if(CurrMediaType === Utils_MediaQuery.L_M_ANDROID_S){}
else if(CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_L){}

else if(CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_S){}
else if(CurrMediaType === Utils_MediaQuery.L_T_GALAXY_S){}
else if(CurrMediaType === Utils_MediaQuery.L_T_IPAD_S){}
else if(CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_L){}

else if(CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_S){}
else if(CurrMediaType === Utils_MediaQuery.L_D_DESKTOP_S){}
else if(CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_L){}
*/
