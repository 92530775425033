import Constants from "../common/Constants";
import * as BABYLON from 'babylonjs';
import {Client} from "colyseus.js";
import Avatar from "./Avatar";
import Communicator from "../common/Communicator";
import CommunicatorEvent from "../common/event/CommunicatorEvent";
import DataManager from "../common/data/DataManager";

export default class MultiPlayManager extends Client{
    'use strict';

    static LOGIN = "login";
    static LOGOUT = "logout";
    static CHATTING = "chatting";
    static NOTICE = "notice";
    static EVENT = "event";
    static ROOM_TYPE_LOBBY = "Room_Type_Lobby";
    static ROOM_TYPE_NORMAL = "Room_Type_Normal";

    constructor(_strURL)
    {
        super(_strURL);

        this.objPlayers = {};
        this.strRoomType = null;
        this.room = null;
        this.key = null;
        this.avatar = Constants.AVATAR;
        this.scene = Constants.SCENE;

        this.init();
    }

    /**
    *===============================================================================
    * init()      :
    *===============================================================================
    */
    init(){

 /*        Communicator.reciveEvent(CommunicatorEvent.SEND_CHAT_MESSAGE, (_data)=>{
           this.SendMessage(_data)
        }); */

        this.sendPosition = () =>{
            // console.log("--- Send !! ", this.room, this.avatar.status);
            //if(this.strRoomType === MultiPlayManager.ROOM_TYPE_LOBBY) return;
            try{
                if(this.room) this.room.send('move', this.avatar.status);
            }catch(_err){

                console.log("ERR ::",_err.message);
            }
        } 
    }

    /**
    *===============================================================================
    * connect()                :
    * @param _strRoomName      : 
    *===============================================================================
    */
    connect(_strRoomName, _isLobby = false)
    {
        this.strRoomType = _isLobby ? MultiPlayManager.ROOM_TYPE_LOBBY : MultiPlayManager.ROOM_TYPE_NORMAL;

        this.joinOrCreate(_strRoomName).then((room) => {
            this.room = room;
            //console.log("_strRoomName ::::", _strRoomName, this.room);
            this.room.state.players.onAdd = (player, key) => {
                // console.log("Room : ", room, ", Player : ", player, ", key : ", key);
                this.key = key;
                
                // 호스트일 경우
                if(this.room.sessionId === key)
                {
                    if(this.strRoomType  === MultiPlayManager.ROOM_TYPE_NORMAL) {
                        this.objPlayers[key] = this.avatar;
                        this.scene.onBeforeAnimationsObservable.add(this.sendPosition);
                    }
                    
                    this.onLogin(this.avatar.status);
                }else { 
                    if(this.strRoomType  === MultiPlayManager.ROOM_TYPE_NORMAL){
                        let onAvatarLoded = () => {
                            this.objPlayers[key] = avatar;
                            this.objPlayers[key].position = new BABYLON.Vector3(player.position.x, player.position.y, player.position.z);
                            this.objPlayers[key].rotation = new BABYLON.Vector3(player.rotation.x, player.rotation.y, player.rotation.z);
                            this.objPlayers[key].name = player.name;
                            this.objPlayers[key].grade = player.grade;
                            this.objPlayers[key].color = player.color;
                            this.objPlayers[key].image = player.image;
    
                            player.position.onChange = () => {
                                // console.log("Position Change!!!!");
                                if(this.objPlayers[key]) {
                                    this.objPlayers[key].position = new BABYLON.Vector3(player.position.x, player.position.y, player.position.z);
                                }
                            };
            
                            player.rotation.onChange = () => {
                                // console.log("Rotation Change!!!!");
                                if(this.objPlayers[key]) 
                                {
                                    this.objPlayers[key].rotation = new BABYLON.Vector3(player.rotation.x, player.rotation.y, player.rotation.z);
                                }
                            };
                        }
                        let avatar
    
                        setTimeout(() => {
                            load();
                        }, 200);
    
                        const load = ()=>{
                            
                            let meshURL = Constants.AVATAR_FILE_NAME;
                            console.log("플레이어 아바타 만듬 : ", player.name);
                            avatar = new Avatar(meshURL, false, player.name);
                            avatar.addEventListener(Avatar.LOAD_COMPLETE, onAvatarLoded);
                        }
                    }
                }
            }
            
            
            
            this.room.connection.transport.ws.onclose = (_e)=>{
                console.log("CLOSE MULTI SERVER");
                if(this.scene && this.sendPosition) this.scene.onBeforeAnimationsObservable.removeCallback(this.sendPosition);
            }; 

            this.room.onMessage("chat", this.onMessageChatting);
            this.room.state.players.onRemove =  (player, key)=> {
                if(this.objPlayers[key]){
                    this.objPlayers[key].dispose();
                }
                this.onLogout(player);
            }; 

            this.room.onLeave(this.onLeave);
            this.room.onError(this.onError);
            
        }).catch(e => {
            //console.log("JOIN ERROR", e);
        });
    }

     /**
    *===============================================================================
    * connect()                :
    * @param _strRoomName      : 
    *===============================================================================
    */
    onLogin(status){
        try{
           // console.log(this.room.state.players);
            this.room.send('login', this.avatar.status);
            this.room.send('chat',{roomType:this.strRoomType, messageType:MultiPlayManager.LOGIN, name:DataManager.GetUserData().name, msg:"님이 입장하셨습니다."});
            this.room.send('chat',{roomType:this.strRoomType, messageType:MultiPlayManager.NOTICE, name:"", msg: " 이 채널의 현재 인원은 " + String(this.room.state.players.size) + "명 입니다."});
        }catch(_err){
            console.log("ERR ::",_err.message);
        }
    }

    onLogout(player){
        try{
            //console.log("onLogout :::: ");
            Communicator.sendEvent(CommunicatorEvent.RECEIVE_CHAT_MESSAGE,  {roomType:this.strRoomType, messageType:MultiPlayManager.LOGOUT, name:player.name, msg:"님이 퇴장하셨습니다."});
            Communicator.sendEvent(CommunicatorEvent.RECEIVE_CHAT_MESSAGE,  {roomType:this.strRoomType, messageType:MultiPlayManager.NOTICE, name:"", msg: " 이 채널의 현재 인원은 " + String(this.room.state.players.size) + "명 입니다."});
        }catch(_err){
            console.log("ERR ::",_err.message);
        }
       
    }

    onMessageChatting(message){
        try{
            //console.log("onMessageChatting :::: ", message);
            Communicator.sendEvent(CommunicatorEvent.RECEIVE_CHAT_MESSAGE, message);
        }catch(_err){
            console.log("ERR ::",_err.message);
        }
    }

    
    onError(code, message){
        console.log("onError ::: ", message);
    }

    onLeave(code){
        try{
            if(this.avatar)Communicator.sendEvent(CommunicatorEvent.RECEIVE_CHAT_MESSAGE,  {roomType:this.strRoomType, messageType:MultiPlayManager.LOGOUT, name:DataManager.GetUserData().name, msg:""});
            
        }catch(_err){
            console.log("ERR ::",_err.message);
        }
    }

    SendMessage(_message){
        try{
            //console.log("SendMessage this.room ::::", this.room);
            this.room.send('chat', {roomType:_message.roomType , messageType:_message.messageType, name:DataManager.GetUserData().name, msg:_message.msg});
        }catch(_err){
            console.log("ERR ::",_err.message);
        }
    }
 
    dispose(){
        //console.log("MultiPlayManager.dispose ::::::")
        for(const key in this.objPlayers){
            this.objPlayers[key].dispose();
        }
        this.room.connection.close();
        this.objPlayers = null;
        this.key = null;
        this.room = null;
        this.scene = null;

    }
}