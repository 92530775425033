	export default class DataLoader
	{
	/*  constructor(){};  */
	constructor(){}
	static LoadData(_strFileURL, _funcCallBack = null, _strRequestType = "GET", _data = null, _token = null)
	{
		// console.log("::: LoadJSON ::: ", _strRequestType, _strFileURL);
		let xmlhttp = new XMLHttpRequest();
		let isAsync = (_funcCallBack !== null);
		
		xmlhttp.open(_strRequestType, _strFileURL, isAsync);
		let returnData = null;

		xmlhttp.onreadystatechange = function() {
			// console.log("LoadJSON ::: ", xmlhttp.readyState);
			if (xmlhttp.readyState === XMLHttpRequest.DONE)
			{
				let objData = {
					success:false,
					message:null,
					errors:xmlhttp.status,
					data:null
					}
				if(xmlhttp.status === 200 || xmlhttp.status === 400) {
					objData = JSON.parse(xmlhttp.responseText);	
				}
				returnData = objData;
				if(_funcCallBack)_funcCallBack(objData);
			}
			
		}
		
		xmlhttp.setRequestHeader("Content-Type", "application/json","charset=UTF-8");
		xmlhttp.withCredentials = true;
		xmlhttp.send(JSON.stringify(_data));

		return returnData;
    }
}