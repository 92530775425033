import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import 'babylonjs-inspector';
import 'babylonjs-gui';
import EventDispatcher from '../common/event/EventDispatcher';
import Constants from '../common/Constants';
import NavimeshManager from './NavimeshManager'; //NavimeshManager 와 CameraManager import 순서 지켜야함
import CameraManager from './CameraManager';
import Avatar from './Avatar';
import BoothManager from './BoothManager';
import ShadowManager from './ShadowManager';
import MultiPlayManager from './MultiPlayManager';
import UOSManager from './UOSManager';
import Communicator from '../common/Communicator';
import CommunicatorEvent from '../common/event/CommunicatorEvent';
import LoadingProgressManager from './LoadingProgressManager';
import DataManager from '../common/data/DataManager';
import GalaxyParticle from './GalaxyParticle';
import BotManager from './BotManager';

export default class BasicWorld extends EventDispatcher {
    'use strict';

    /**
     *===============================================================================
     * constructor()            :
     * @param _canvas           : WebGL용 canvas element
     *===============================================================================
     */
    constructor(_canvas) {
        super();

        Constants.CANVAS = this.canvas = _canvas;
        Constants.ENGINE = this.engine = new BABYLON.Engine(this.canvas, true);
        Constants.SCENE = this.scene = new BABYLON.Scene(this.engine);

        this.isInput = false;
        this.meshWorld = null;
        this.skyboxMaterial = null;
        this.nightTexture = null;
        this.dayTexture = null;
        this.starParticle = null;

        this.botManager = null;

        this.Init();

    }

    dispose() {
        if (this.avatar) this.avatar.dispose();

        CameraManager.dispose();
        this.light.dispose();
        this.light2.dispose();
        this.scene.dispose();
        this.engine.dispose();

        this.meshWorld = null;
        Constants.SCENE = this.scene = null;
        Constants.ENGINE = this.engine = null;
        Constants.CANVAS = this.canvas = null;
    }

    /**
     *===============================================================================
     * Init()             :
     *===============================================================================
     */
    Init() {

        LoadingProgressManager.GetInstance();

        this.setEnvironment();
        this.setCamera();
        this.setWorld();
        this.setInspector();

        if (Constants.DEBUG_MODE) this.setInspector();

        this.engine.runRenderLoop(() => {
            this.scene.render();
        });

        window.addEventListener('resize', () => {
            this.engine.resize();
        });

    }

    setEnvironment() {
        //배경 컬러
        this.scene.clearColor = new BABYLON.Color3(0.933, 0.917, 0.905);
    }

    setCamera() {
        CameraManager.GetInstance();
        // CameraManager.cameraMode = CameraManager.MINI_MAP_MODE;
        this.setLight();
    }

    setLight() {
        this.light = new BABYLON.DirectionalLight("DirectionalLight", new BABYLON.Vector3(0, -120, 0), this.scene);
        this.light.direction = new BABYLON.Vector3(-3, -15, -5)
        // this.light.intensity = 1.5;
        this.light.intensity = 2;
        this.light.diffuse = new BABYLON.Color3(1, 1, 1);
        this.light.specular = new BABYLON.Color3(1, 1, 1);


        this.light2 = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 10, 0), this.scene);
        this.light2.intensity = 0.5;
        // this.light2.intensity = 1;
        this.light2.groundColor = new BABYLON.Color3(.588, .588, .588);

        if (Constants.OS_PLATFORM === Constants.OS_ECT) ShadowManager.GetInstance(this.light);

        /*         this.light3 = new BABYLON.DirectionalLight("DirectionalLight2", new BABYLON.Vector3(0, -120, 0), this.scene);
                this.light3.direction = new BABYLON.Vector3(-1, -10, -5)
                this.light3.intensity = 1.0;
                this.light3.diffuse = new BABYLON.Color3(1, 1, 1);
                this.light3.specular = new BABYLON.Color3(1, 1, 1); */

        /*
                this.m_shadowGenerator = new BABYLON.ShadowGenerator(4096, this.light3);
                this.m_shadowGenerator.useCloseBlurExponentialShadowMap = true;
                this.m_shadowGenerator.forceBackFacesOnly = true;
                this.m_shadowGenerator.usePoissonSampling = true;
                this.m_shadowGenerator.useBlurExponentialShadowMap = true;
                this.m_shadowGenerator.setDarkness(0.5);
                this.m_shadowGenerator.enableSoftTransparentShadow = false;
                this.m_shadowGenerator.transparencyShadow = true; */

    }

    async setWorld() {
        //console.log("setWorld ::::::") 
        NavimeshManager.GetInstance();

        var skybox = BABYLON.MeshBuilder.CreateBox("skybox", {
            size: 1024.0
        }, this.scene);
        this.skyboxMaterial = new BABYLON.StandardMaterial("skybox", this.scene);
        this.skyboxMaterial.backFaceCulling = false;
        this.nightTexture = new BABYLON.CubeTexture("images/Skybox_night/skybox", this.scene);
        this.dayTexture = new BABYLON.CubeTexture("images/Skybox_day/skybox", this.scene);

        // KDG - day texture로 변경
        // this.skyboxMaterial.reflectionTexture = this.nightTexture;
        this.skyboxMaterial.reflectionTexture = this.dayTexture;
        this.skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
        this.skyboxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
        this.skyboxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
        skybox.material = this.skyboxMaterial;

        this.scene.onBeforeRenderObservable.add(() => {
            skybox.rotation.y += 0.00007;
        });

        Constants.CONTAINER_MAP = new BABYLON.Mesh("mapContainer", this.scene);

        // 월드맵 로딩
        let loadData = 0;
        const onMeshLoadProgress = (_data) => {
            let dataUnit = _data.loaded - loadData;
            loadData = _data.loaded;
            LoadingProgressManager.AddData(dataUnit);
        }

        let mapLoad = await BABYLON.SceneLoader.ImportMeshAsync("", Constants.ASSETS_URL, Constants.WORLD_FILE_NAME, this.scene, onMeshLoadProgress);
        this.meshWorld = mapLoad.meshes[0];
        this.meshWorld.name = "WorldMap";

        Constants.CONTAINER_MAP.addChild(this.meshWorld);

        // KDG - 아바타 제외
        // Constants.SCENE.getMeshByName("AVATAR")?.isVisible = false;
        // 새만금 캐릭터 추가
        const afterLoad = (meshes, vec) => 
        { 
            // console.log("mesh : ", meshes, this);
            const rootMesh = meshes.find(i => i.name === "__root__");
            rootMesh.position = vec
            Constants.SCENE.addMesh( rootMesh, true );
        }
        const afterLoad1 = (meshes) => 
        { 
            afterLoad(meshes, new BABYLON.Vector3(0.6, 0.8, 2));
        }
        const afterLoad2 = (meshes) => 
        { 
            afterLoad(meshes, new BABYLON.Vector3(-0.6, 0.8, 2));
        }
        const addCharacter1 = await BABYLON.SceneLoader.ImportMesh("", Constants.ASSETS_URL, "Manguem_idle.glb", Constants.SCENE, afterLoad1);
        const addCharacter2 = await BABYLON.SceneLoader.ImportMesh("", Constants.ASSETS_URL, "Saee_idle.glb", Constants.SCENE, afterLoad2);

        this.onWorldloaded();
    }

    onWorldloaded() {
        let boothManager = new BoothManager();
        boothManager.addEventListener(BoothManager.LOAD_COMPLETE, () => {
            this.onBoothSettingComplete();
            boothManager.removeEventListener(BoothManager.LOAD_COMPLETE);
        })
    }

    onBoothSettingComplete() {
        let uos_Manager = new UOSManager();
        uos_Manager.init();

        this.setAvatar();
    }

    setAvatar() {
        this.avatar = Constants.AVATAR = new Avatar(Constants.AVATAR_FILE_NAME);
        this.avatar.addEventListener(Avatar.LOAD_COMPLETE, () => {
            this.onAvataLoded();
            this.avatar.removeEventListener(Avatar.LOAD_COMPLETE);
        })
    }

    onAvataLoded() {
        CameraManager.target = this.avatar;
        // Constants.CONTAINER_MAP.addChild(this.avatar.mesh);

        this.setNavimesh();
        this.setDeco(1);
        CameraManager.cameraMode = CameraManager.MINI_MAP_MODE;

        Communicator.reciveEvent(CommunicatorEvent.START_CONTENT, this.StartContent.bind(this));

        // KDG
        // if (Constants.OS_PLATFORM === Constants.OS_ECT) {
        if ( false ){
            this.setShadow();
        } else {
            Communicator.sendEvent(CommunicatorEvent.WORLD_LOAD_COMPLETE);
        }
    }

    setNavimesh() {
        // KDG
        // let meshNavi = this.scene.getNodeByName("navvy_mesh");
        let meshNavi = this.scene.getNodeByName("navimesh");
        let mesh = this.scene.getMeshByName("navimesh");
        // console.log("메쉬를 로드 했을 때 pickable?", mesh.name, mesh.isPickable);
        mesh.isPickable = true;
        // KDG - navi mesh의 상하 위치 조절
        mesh.position.y = mesh.position.y-0.2;
        NavimeshManager.AddMeshForNavimesh(meshNavi);
        // KDG - param1은 navmesh가 보이게 할지말지 결정함.
        NavimeshManager.MakeNavimesh(false, false);

        let meshTarget = NavimeshManager.marker.mesh;
        NavimeshManager.AddAvatar(this.avatar, meshTarget);
    }

    setDeco(_intValue) {
        this.starParticle = Constants.GALAXY_PARTICLE = new GalaxyParticle();
        this.starParticle.init(_intValue);
    }

    setShadow() {
        //부스는 부스 클래스 내부에서 따로 설정함
        // 그림자가 그려져야하는 오브젝트
        // ShadowManager.AddShadowMap(this.scene.getNodeByName("eagle"));
        // ShadowManager.AddShadowMap(this.scene.getNodeByName("mountain_primitive0"));
        // ShadowManager.AddShadowMap(this.scene.getNodeByName("mountain_primitive1"));
        // ShadowManager.AddShadowMap(this.scene.getNodeByName("shadow_object_primitive0"));
        // ShadowManager.AddShadowMap(this.scene.getNodeByName("shadow_object_primitive1"));
        // ShadowManager.AddShadowMap(this.scene.getNodeByName("shadow_object_primitive2"));
        // ShadowManager.AddShadowMap(this.scene.getNodeByName("shadow_object_primitive3"));
        // ShadowManager.AddShadowMap(this.scene.getNodeByName("shadow_object_primitive4"));
        // ShadowManager.AddShadowMap(this.scene.getNodeByName("shadow_object_primitive5"));
        // ShadowManager.AddShadowMap(this.scene.getNodeByName("shadow_object_primitive6"));
        // // ShadowManager.AddShadowMap(this.scene.getNodeByName("shadow_object_primitive7"));
        // ShadowManager.AddShadowMap(this.scene.getNodeByName("TextPlus001"));
        // ShadowManager.AddShadowMap(this.scene.getNodeByName("subject_uni_seoul"));
        // ShadowManager.AddShadowMap(this.scene.getNodeByName("YirumaePlane"));
        // ShadowManager.AddShadowMap(this.scene.getNodeByName("Screen_01"));
        // ShadowManager.AddShadowMap(this.scene.getNodeByName("screen_02"));
        // ShadowManager.AddShadowMap(this.scene.getNodeByName("Homepage"));
        // ShadowManager.AddShadowMap(this.scene.getNodeByName("instar_primitive0"));
        // ShadowManager.AddShadowMap(this.scene.getNodeByName("instar_primitive1"));
        // ShadowManager.AddShadowMap(this.scene.getNodeByName("youtube"));
        // ShadowManager.AddShadowMap(this.scene.getNodeByName("screen_02"));

        // // // 그림자가 그려질 오브젝트
        // ShadowManager.AddReceive(this.scene.getNodeByName("ground_star"));
        // ShadowManager.AddReceive(this.scene.getNodeByName("fake_road_primitive0"));
        // ShadowManager.AddReceive(this.scene.getNodeByName("fake_road_primitive1"));
        // ShadowManager.AddReceive(this.scene.getNodeByName("glow_road"));
        // ShadowManager.AddReceive(this.scene.getNodeByName("track"));
        // ShadowManager.AddReceive(this.scene.getNodeByName("navvy_mesh"));

        


        // 그림자가 그려져야하는 오브젝트 (빛을 가릴 오브젝트)
        ShadowManager.AddShadowMap(this.scene.getNodeByName("center_building"));

        // // 그림자가 그려질 오브젝트 (그림자를 받아 표시할)
        ShadowManager.AddReceive(this.scene.getNodeByName("navimesh"));
        ShadowManager.AddReceive(this.scene.getNodeByName("floor"));

        const funcCallback = () => {
            Communicator.sendEvent(CommunicatorEvent.WORLD_LOAD_COMPLETE);
        }
        ShadowManager.FreezingShadow(this.light, funcCallback);
    }

    /**
     *===============================================================================
     * StartContent()        : 플래이어가 입장하기 클릭시 실행되는 함수
     * @param _objUserData   : 유저데이터
     *===============================================================================
     */
    StartContent(_objUserData) {
        //console.log("StartContent ::::::", _objUserData, this.avatar);
        CameraManager.cameraMode = CameraManager.PLAY_MODE;

        DataManager.SetUserData(_objUserData);

        this.avatar.name = _objUserData.name;
        // let strURL = Constants.MULTI_SERVER_URL.url + ":" + Constants.MULTI_SERVER_URL.port;
        let strURL = Constants.MULTI_SERVER_URL.url;

        //일반 멀티서버 룸 접속
        let multiPlayManager = new MultiPlayManager(strURL, this.avatar, this.scene);
        multiPlayManager.connect('normalRoom');

        //전체 멀티서버 룸 접속
        let multiPlayManagerLobby = new MultiPlayManager(strURL, this.avatar, this.scene);
        multiPlayManagerLobby.connect('lobby', true);

        Communicator.reciveEvent(CommunicatorEvent.SEND_CHAT_MESSAGE, (data) => {
            if (data.roomType === MultiPlayManager.ROOM_TYPE_NORMAL) {
                multiPlayManager.SendMessage(data);
            } else if (data.roomType === MultiPlayManager.ROOM_TYPE_LOBBY) {
                multiPlayManagerLobby.SendMessage(data);
            }
        });

        Communicator.reciveEvent(CommunicatorEvent.GO_TO_TENANT, (data) => {

            let id = data.id;
            CameraManager.goToBooth(id);

        });

        this.botManager = new BotManager();
    }

    setInspector() { // insert 버튼 눌러 인스펙터 창 토글
        var keys = [],
            egd1 = "65,78,84,79,78,73,79",
            egsd = "68,65,89",
            egsn = "78,73,71,72,84",
            egne = "78,69,79,78",
            egde = "68,69,70,65,85,76,84";

        this.scene.onKeyboardObservable.add((kbInfo) => {
            if (kbInfo.type === BABYLON.KeyboardEventTypes.KEYDOWN) {
                keys.push(kbInfo.event.keyCode);
                if (keys.toString().indexOf(egd1) >= 0) {
                    keys = [];
                    this.scene.debugLayer.show({
                        enablePopup: true,
                        overlay: true,
                    });
                    document.getElementById("scene-explorer-host").style.zIndex = 100;
                    document.getElementById("inspector-host").style.zIndex = 100;
                }
                else if(keys.toString().indexOf(egsd) >= 0){
                    keys = [];
                    this.skyboxMaterial.reflectionTexture = this.dayTexture;
                    this.skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
                    this.starParticle.dispose();
                    this.setDeco(2);
                }
                else if(keys.toString().indexOf(egsn) >= 0){
                    keys = [];
                    this.skyboxMaterial.reflectionTexture = this.nightTexture;
                    this.skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
                    this.starParticle.dispose();
                    this.setDeco(1);
                }
                else if(keys.toString().indexOf(egne) >= 0){
                    keys = [];
                    this.light.intensity = 0.1;
                    this.light2.intensity = 0.1;
                    CameraManager.pipeline.bloomWeight = 1;
                    CameraManager.pipeline.bloomKernel = 100;
                }
                else if(keys.toString().indexOf(egde) >= 0){
                    keys = [];
                    this.light.intensity = 1.5;
                    this.light2.intensity = 0.5;
                    CameraManager.pipeline.bloomWeight = 0.1;
                    CameraManager.pipeline.bloomKernel = 64;
                    this.skyboxMaterial.reflectionTexture = this.nightTexture;
                    this.skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
                    this.starParticle.dispose();
                    this.setDeco(1);
                }
            }
        });
    }
}