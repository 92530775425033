import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

import IcoClose from '../images/ico_close.png';
import ImgArrowRight from "../images/ico_arrowRight.png";
import ImgArrowLeft from "../images/ico_arrowLeft.png";
import { LandscapeScreen, PortraitScreen } from "../common/MediaQuery";
import Utils_MediaQuery from "../common/Utils_MediaQuery";


let ContainerBox = styled.div`
    position: fixed;
    left: 0;
    top:0;
    pointer-events:all;    
    height:100vh;
    width:100vw;
    z-index: 1000;
`;

let BlackBgBox = styled.div`
    position: fixed;
    left: 0;
    top:0;
    pointer-events:all;    
    height:100vh;
    width:100vw;
    background-color: black;
    opacity: 0.9;
    z-index: 1000;
`;

let BtnClose = styled.div`
    pointer-events:all;
    cursor: pointer;
    position: fixed;
    z-index: 1300;
    right: ${props => props.Right};
    top: ${props => props.top};
    width:${props => props.width};
    height:${props => props.height};
    background-image: url(${props => props.ImgURL});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
`;

const IMG = styled.img`
    width: ${props=>props.Width ||"610px"}; 
    height: ${props=>props.Height ||"900px"};
`;

const Container = styled.div`
    position: absolute;
    left :${props=>props.Left || "calc(50vw - 305px)"};
    top: ${props=>props.Top   || "calc(50vh - 450px)"};
    width: ${props=>props.Width ||"610px"}; 
    height: ${props=>props.Height ||"900px"};
    overflow: hidden;
    z-index: 1300;
`;

let ButtonRight = styled.div`
    pointer-events:all; cursor: pointer;
    position:absolute; 
    float: right;
    right: ${props=>props.Right||"400px"}; 
    top: ${props=>props.Top || "calc(50vh - 35px)"};
    width:${props=>props.Width ||"35px"}; 
    height:${props=>props.Height||"69px"}; 
    z-index:1310;
    background-image: url(${props => props.ImgURL});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    filter: drop-shadow(3px 3px 3px #000);
`;

let ButtonLeft = styled.div`
    pointer-events:all; cursor: pointer;
    position:absolute; 
    /* float: right; */
    left: ${props=>props.Left||"400px"}; 
    top: ${props=>props.Top || "calc(50vh - 35px)"};
    width:${props=>props.Width ||"35px"}; 
    height:${props=>props.Height||"69px"}; 
    background-image: url(${props => props.ImgURL});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index:1310;
    filter: drop-shadow(3px 3px 3px #000);
`;

const SliderContainer = styled.div`
    width: ${props=>props.Width ||"610px"}; 
    height: ${props=>props.Height ||"900px"};
    background-color: white;
    display: flex;
`;

function ModalCatalog(props){

    const [currentSlide, setCurrentSlide] = useState(0);
    const slideRef = useRef(null);

    const TOTAL_SLIDES = props.arrCatalogData.length-1;
    const images = props.arrCatalogData;

    let [ImgSize, setImgSize] = useState({ Width:"610px", Height:"900px" });
    let [closeImgSize, setCloseImgSize] = useState({ Width:"29px", Height:"28px" });
    let [arrowLocation, setArrowLocation] = useState({ Width:"35px", Height:"69px" });

    useEffect(() => {
        let CurrMediaType = Utils_MediaQuery.GetMediaDetailType(props.objWindowSize.Width,props.objWindowSize.Height);
        console.log("ModalCatalog : ",CurrMediaType);
        if(CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_M_FOLD_S){
            setImgSize({ Width:"250px", Height:"369px" });
            setCloseImgSize({ Width:"20px", Height:"20px" });
            setArrowLocation({ Width:"15px", Height:"30px"});
        }
        else if(CurrMediaType === Utils_MediaQuery.P_M_IPHONE_S ||
            CurrMediaType === Utils_MediaQuery.P_M_ANDROID_S ||
            CurrMediaType === Utils_MediaQuery.P_M_DEFAULT_L){
            setImgSize({ Width:"300px", Height:"443px" });
            setCloseImgSize({ Width:"20px", Height:"20px" });
            setArrowLocation({ Width:"15px", Height:"30px"});
        }
        
        
        else if(CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_T_GALAXY_S ||
            CurrMediaType === Utils_MediaQuery.P_T_IPAD_S ||
            CurrMediaType === Utils_MediaQuery.P_T_DEFAULT_L){
            setImgSize({ Width:"630px", Height:"930px" });
            setCloseImgSize({ Width:"20px", Height:"20px" });
            setArrowLocation({ Width:"15px", Height:"30px"});
        }
        
        else if(CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.P_D_DESKTOP_S ||
            CurrMediaType === Utils_MediaQuery.P_D_DEFAULT_L){
            setImgSize({ Width:"678px", Height:"1000px" });
            setCloseImgSize({ Width:"20px", Height:"20px" });
            setArrowLocation({ Width:"15px", Height:"30px"});
        }
        
        else if(CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.L_M_FOLD_S){
            setImgSize({ Width:"163px", Height:"240px" });
            setCloseImgSize({ Width:"20px", Height:"20px" });
            setArrowLocation({ Width:"15px", Height:"30px"});
        }
        else if(CurrMediaType === Utils_MediaQuery.L_M_IPHONE_S ||
            CurrMediaType === Utils_MediaQuery.L_M_ANDROID_S ||
            CurrMediaType === Utils_MediaQuery.L_M_DEFAULT_L){
            setImgSize({ Width:"216px", Height:"319px" });
            setCloseImgSize({ Width:"13px", Height:"13px" });
            setArrowLocation({ Width:"15px", Height:"30px"});
        }
        
        else if(CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.L_T_GALAXY_S){
            setImgSize({ Width:"397px", Height:"586px" });
            setCloseImgSize({ Width:"20px", Height:"20px" });
            setArrowLocation({ Width:"30px", Height:"59px"});
        }
        else if(CurrMediaType === Utils_MediaQuery.L_T_IPAD_S ||
            CurrMediaType === Utils_MediaQuery.L_T_DEFAULT_L){
            setImgSize({ Width:"424px", Height:"625px" });
            setCloseImgSize({ Width:"20px", Height:"20px" });
            setArrowLocation({ Width:"30px", Height:"59px"});
        }
        
        else if(CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_S ||
            CurrMediaType === Utils_MediaQuery.L_D_DESKTOP_S ||
            CurrMediaType === Utils_MediaQuery.L_D_DEFAULT_L){
            setImgSize({ Width:"635px", Height:"937px" });
            setCloseImgSize({ Width:"20px", Height:"20px" });
            setArrowLocation({ Width:"30px", Height:"59px"});
        }
    },[props.objWindowSize])

    const nextSlide = () => {
        if (currentSlide >= TOTAL_SLIDES) {
            setCurrentSlide(0);
        } else {
            setCurrentSlide(currentSlide + 1);
        }
    };

    const prevSlide = () => {
        if (currentSlide === 0) {
            setCurrentSlide(TOTAL_SLIDES);
        } else {
            setCurrentSlide(currentSlide - 1);
        }
    };

    useEffect(() => {
        slideRef.current.style.transition = "all 0.5s ease-in-out";
        slideRef.current.style.transform = `translateX(-${currentSlide}00%)`;
    }, [currentSlide]);

    return (
        <ContainerBox>
            <LandscapeScreen>
                {(TOTAL_SLIDES > 0)
                ?<>
                    <ButtonLeft Width={arrowLocation.Width} Height={arrowLocation.Height} 
                    Left={"calc("+ props.objWindowSize.Width/2+"px - (" + ImgSize.Width + " / 2) - 3vw)" } 
                    Top={arrowLocation.Top} ImgURL={ImgArrowLeft} onClick={prevSlide}/>
                    <ButtonRight Width={arrowLocation.Width} Height={arrowLocation.Height} 
                    Right={"calc("+ props.objWindowSize.Width/2+"px - (" + ImgSize.Width + " / 2) - 3vw)" } 
                    Top={arrowLocation.Top} ImgURL={ImgArrowRight} onClick={nextSlide}/>
                </>
                :null
                }
                
            </LandscapeScreen>
            <PortraitScreen>
            {(TOTAL_SLIDES > 0)
            ?<>
                <ButtonLeft Width={arrowLocation.Width} Height={arrowLocation.Height} 
                    Left={"calc("+ props.objWindowSize.Width/2+"px - (" + ImgSize.Width + " / 2) - 6vw)" } 
                    Top={arrowLocation.Top} ImgURL={ImgArrowLeft} onClick={prevSlide}/>
                <ButtonRight Width={arrowLocation.Width} Height={arrowLocation.Height} 
                    Right={"calc("+ props.objWindowSize.Width/2+"px - (" + ImgSize.Width + " / 2) - 6vw)" } 
                    Top={arrowLocation.Top} ImgURL={ImgArrowRight} onClick={nextSlide}/>
            </>
            :null
            }
            </PortraitScreen>
            <BtnClose Right="1vw" top="1vw" width={closeImgSize.Width} height={closeImgSize.Height} ImgURL={IcoClose} onClick={() => {
                props.funcSetOpenCatalog(false);
            }} />
            <Container Width={ImgSize.Width} Height={ImgSize.Height}
                Left={"calc("+ props.objWindowSize.Width/2+"px - (" + ImgSize.Width + " / 2))" }
                Top={"calc("+ props.objWindowSize.Height/2+"px - (" + ImgSize.Height + " / 2))" }
            >
                <SliderContainer ref={slideRef} Width={ImgSize.Width} Height={ImgSize.Height}>
                    {images.map((img, i) => <IMG src={img} key={i}  Width={ImgSize.Width} Height={ImgSize.Height}/>)}
                </SliderContainer>
            </Container>
            <BlackBgBox />
        </ContainerBox>
    );
}

export default ModalCatalog;